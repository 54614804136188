export type EmptyState = {
  state: "empty";
};

export type LoadingState = {
  state: "loading";
};

export interface Error {
  type: string;
  serverMessage: string;
}

export type RejectedState = {
  state: "rejected";
  error: Error;
};

export type FulfilledState<T> = {
  state: "fulfilled";
  data: T;
};

export type RequestState<T> =
  | EmptyState
  | LoadingState
  | RejectedState
  | FulfilledState<T>;

export const EMPTY_STATE: EmptyState = { state: "empty" };
export const LOADING_STATE: LoadingState = { state: "loading" };

export const rejectedState = (error: Error): RejectedState => ({
  state: "rejected",
  error,
});

export const fulfilledState = <T>(data: T): FulfilledState<T> => ({
  state: "fulfilled",
  data,
});
