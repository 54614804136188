import { RootPanel } from "../components";
import { OutsetaSupportForm } from "../components/outseta";
import { Loading } from "../components/loading";
import { Flex } from "@mantine/core";
import { useUser } from "src/store/user";

export const Support = () => {
  const user = useUser();
  return (
    <RootPanel withNavbar>
      <Flex direction="column" gap="md">
        <Loading isLoading={user.state === "loading"}>
          {user.state === "fulfilled" && (
            <OutsetaSupportForm user={user.data} />
          )}
        </Loading>
      </Flex>
    </RootPanel>
  );
};
