import { FC, useEffect, useState } from "react";
import { SquareCard } from "../square-card";
import { TextCard } from "../text-card";
import { Loading } from "../loading";
import classes from "./network-selector.module.css";
import { Flex, Grid } from "@mantine/core";
import { Chain, Network } from "src/api/chain";
import { useChains } from "src/store/chain";

interface Props {
  chainId: number;
}

export const NetworkSelector: FC<Props> = ({ chainId }) => {
  const chains = useChains();
  const [chain, setChain] = useState<Chain | null>(null);

  useEffect(() => {
    if (chains.state === "fulfilled") {
      const chain = chains.data.find((chain: Chain) => chain.id === chainId);
      if (chain) {
        setChain(chain);
      }
    }
  }, [chainId, chains]);

  return (
    <Loading isLoading={chains.state === "loading"}>
      <Flex justify="center">
        <Grid className={classes.grid} grow={false} gutter={"lg"}>
          <Grid.Col span={3} p="lg">
            {chain && <SquareCard text={chain.name} imgSrc={chain.imageUrl} />}
          </Grid.Col>
          <Grid.Col span={8} p="lg">
            {chain?.networks?.map((network: Network, index: number) => (
              <TextCard key={index} title={network.name} text={`bla`} />
            ))}
          </Grid.Col>
        </Grid>
      </Flex>
    </Loading>
  );
};
