import { ReactNode } from "react";
import {
  ButtonProps as MantineButtonProps,
  Button as MantineButton,
  Space,
} from "@mantine/core";
import { theme } from "src/ui/theme";

type ButtonProps = {
  text: string;
  icon?: ReactNode;
} & MantineButtonProps;

export const SubmitButton = ({
  text,
  styles,
  icon,
  ...buttonProps
}: ButtonProps) => {
  return (
    <MantineButton
      styles={styles}
      color={theme.colors?.green?.[5]}
      variant={buttonProps.variant ? buttonProps.variant : "filled"}
      type="submit"
      {...buttonProps}
    >
      {icon && <Space pr={"xs"}>{icon}</Space>}
      {text}
    </MantineButton>
  );
};
