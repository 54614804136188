import { LinkButton } from "../buttons";
import { Flex } from "@mantine/core";

type FooterProps = {
  backButtonLink?: string;
  continueButtonLink?: string;
};

export const Footer = ({ backButtonLink, continueButtonLink }: FooterProps) => {
  return (
    <Flex justify="space-between" p={"sm"}>
      {backButtonLink && (
        <LinkButton text="Back" link={backButtonLink} variant={"default"} />
      )}
      {continueButtonLink && (
        <LinkButton text="Continue" link={continueButtonLink} />
      )}
    </Flex>
  );
};
