import { AxiosResponse } from "axios";
import { marlyApi } from "./marly";
import { recursiveToCamelCase } from "src/utility/recursive-to-camel-case";

export type Period = "startOfMonth" | "default";
export type Interval = "minute" | "hour" | "day";

export interface Notification {
  id: string;
  priority: "low" | "high";
  createdAt: string;
  updatedAt: string;
  readAt: string | undefined;
  template: string;
  templateData: string | undefined;
  userId: string;
}
const getNotifications = async (): Promise<AxiosResponse<Notification[]>> => {
  return await marlyApi
    .get(`/v3/user/notifications`, {
      withCredentials: true,
    })
    .then((response) => {
      response.data = recursiveToCamelCase(response.data);
      return response;
    })
    .catch((error) => {
      console.error("Unknown error", error);
      throw error;
    });
};

const markAsRead = async (
  notification: Notification,
): Promise<AxiosResponse<Notification>> => {
  return await marlyApi
    .post(
      `/v3/user/notifications/${notification.id}/mark_as_read`,
      {},
      { withCredentials: true },
    )
    .catch((error) => {
      console.error("Unknown error", error);
      throw error;
    });
};

export default {
  getNotifications,
  markAsRead,
};
