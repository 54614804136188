import { useEffect, useState } from "react";
import { DailyQuotaDepleted } from "./daily_quota_depleted";
import { MonthlyQuotaDepleted } from "./monthly_quota_depleted";
import { ActionIcon, Flex, Indicator, Menu } from "@mantine/core";
import { Notification } from "src/api/notifications";
import { useAppDispatch } from "src/core/hooks";
import {
  getNotifications,
  markAsRead,
  useNotifications,
} from "src/store/notifications";
import { BellIcon } from "src/ui/icons/bell-icon";
import { baseURL } from "src/api/marly";

export const NotificationBell = () => {
  const notifications = useNotifications();
  const dispatch = useAppDispatch();

  const [unreadNotifications, setUnreadNotifications] = useState(false);

  useEffect(() => {
    if (notifications) {
      const unreadNotifications = notifications.filter(
        (notification) => !notification.readAt,
      );
      setUnreadNotifications(unreadNotifications.length > 0);
    }
  }, [notifications]);

  useEffect(() => {
    const evtSource = new EventSource(`${baseURL}/sse/stream`, {
      withCredentials: true,
    });
    evtSource.addEventListener("new_notification", () => {
      // Refresh notifications
      dispatch(getNotifications());
    });
    return () => {
      evtSource.close();
    };
  }, [dispatch]);

  const renderNotification = (notification: Notification) => {
    if (notification.template === "quota_exceeded_daily") {
      return (
        <Menu.Item
          key={notification.id}
          component="div"
          onClick={() => handleClick(notification)}
        >
          <DailyQuotaDepleted />
        </Menu.Item>
      );
    }
    if (notification.template === "quota_exceeded_monthly") {
      return (
        <Menu.Item
          key={notification.id}
          component="div"
          onClick={() => handleClick(notification)}
        >
          <MonthlyQuotaDepleted />
        </Menu.Item>
      );
    }
    return null;
  };

  const handleClick = (notification: Notification) => {
    dispatch(markAsRead(notification));
  };

  return (
    <Menu shadow="md" closeOnItemClick={false}>
      <Menu.Target>
        <Indicator disabled={!unreadNotifications}>
          <ActionIcon variant="filled" color="green" radius="sm">
            <BellIcon fillColor="white" />
          </ActionIcon>
        </Indicator>
      </Menu.Target>

      <Menu.Dropdown>
        <Flex direction="column" gap="md">
          {notifications && notifications.map(renderNotification)}
        </Flex>
      </Menu.Dropdown>
    </Menu>
  );
};
