import { Interval } from "src/api/user";

export const metricsdateFormat = (interval: Interval, date: Date): string => {
  switch (interval) {
    case "minute":
      return `${date.getDate()}/
                ${date.getMonth() + 1} ${date.getHours()}:${date.getMinutes()}`;
    case "hour":
      return `${date.getDate()}/${date.getMonth() + 1} ${date.getHours()}:00`;
    case "day":
      return `${date.getDate()}/${date.getMonth() + 1}`;
  }
};
