import { AxiosResponse } from "axios";
import { marlyApi } from "./marly";
import { recursiveToCamelCase } from "src/utility/recursive-to-camel-case";

export interface SubscriptionPlan {
  name: string;
  rate_limit: number;
  monthly_quota: number;
  id: number;
  daily_quota: number;
  hidden: boolean;
  features: string[];
  lookup_key: string;
  overcharge_price: number;
  price: number;
  price_tax_included: number;
  tax: number;
}

export interface PreviewInvoice {
  type: string;
  version: number;
  total: number;
  currency: string;
  interval: "yearly" | "monthly";
  firstMonthFee: number;
  baseFee: number;
  discountedAmount: number | undefined;
  discountedPercentage: number | undefined;
  taxAmount: number;
  taxPercentage: number;
  overchargePrice: number;
}

export interface Previews {
  developerPlanYearly: PreviewInvoice;
  developerPlanMonthly: PreviewInvoice;
  growthPlanYearly: PreviewInvoice;
  growthPlanMonthly: PreviewInvoice;
}

export type PreviewKey = keyof Previews;

const getSubscriptionPlans = async (): Promise<
  AxiosResponse<SubscriptionPlan[]>
> => {
  return await marlyApi.get("/v3/subscriptions", {}).catch((error) => {
    // Unknown error
    console.error("Unknown login error", error);
    throw error;
  });
};

const getSubscriptionPreviews = async (): Promise<AxiosResponse<Previews>> => {
  return await marlyApi
    .get("/subscriptions/previews", {})
    .then((backend) => {
      backend.data = recursiveToCamelCase(backend.data);
      return backend;
    })
    .catch((error) => {
      // Unknown error
      console.error("Unknown login error", error);
      throw error;
    });
};

export default {
  getSubscriptionPlans,
  getSubscriptionPreviews,
};
