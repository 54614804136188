import { useState } from "react";
import { RootPanel } from "../components/panels/root-panel/root-panel";
import { CardGrid } from "../components/card-grid/card-grid";
import { Footer } from "../components/footer";
import { PageRoute } from "src/utility/utils";

export const SelectChain = () => {
  const [selectedChain, setSelectedChain] = useState<number | null>(null);

  const handleChainSelect = (chainId: number) => {
    setSelectedChain(chainId);
  };

  const footer = (
    <Footer
      backButtonLink={PageRoute.DASHBOARD}
      continueButtonLink={
        selectedChain
          ? `${PageRoute.SELECT_NETWORK}/${selectedChain}`
          : PageRoute.DASHBOARD
      }
    />
  );

  return (
    <RootPanel footer={footer}>
      <CardGrid onChainSelect={handleChainSelect} />
    </RootPanel>
  );
};
