import { useEffect, useState } from "react";
import { RootPanel } from "../../components";
import { CustomTabs } from "../../components/panels/custom-tabs";
import { useParams } from "react-router-dom";
import { Space, Container } from "@mantine/core";
import {
  EndpointTabValue,
  getEndpointTabs,
  PageRoute,
} from "src/utility/utils";
import { MetricsSummary } from "src/ui/components/metrics-summary";
import { MethodChart } from "src/ui/components/method-chart";
import { Interval } from "src/api/user";
import { useAnalyticsV4 } from "src/store/user";
import { useChains } from "src/store/chain";
import { Loading } from "src/ui/components/loading";
import { urlify } from "src/utility/endpoint";
import { Chain } from "src/api/chain";
import { Chart, initChart, populateV4Analytics } from "src/utility/chart";
import { UsageChart } from "src/ui/components/usage-chart";
import { TextComponent } from "src/ui/components/text-component";
import { useTranslation } from "react-i18next";

export const Metrics = () => {
  const { t } = useTranslation();
  const interval: Interval = "day";
  const chains = useChains();
  const [chain, setChain] = useState<Chain | undefined>(undefined);
  const analyticsV4 = useAnalyticsV4(interval);
  const [chart, setChart] = useState<Chart | null>(null);

  const { id: endpointId } = useParams();
  const tabs = getEndpointTabs(endpointId || "");

  useEffect(() => {
    if (chains.state === "fulfilled") {
      setChain(
        chains.data.find((c) => urlify(c.name) === endpointId) || undefined,
      );
    }
  }, [chains, endpointId, setChain]);

  useEffect(() => {
    if (
      analyticsV4.state === "fulfilled" &&
      chain &&
      chains.state === "fulfilled"
    ) {
      const data = analyticsV4.data;

      const lookupHostName = new Map();
      chains.data.map((chain) => {
        chain.networks.map((network) => {
          const url = new URL(network.https);
          const host = url.host;
          const hostName = `${chain.name} - ${network.name}`;
          lookupHostName.set(host, hostName);
        });
      });

      const chart = initChart(interval, 30, () =>
        process.env.REACT_APP_INJECT_RANDOM_DATA === "true"
          ? Math.floor(1000000 + Math.random() * 150)
          : 0,
      );
      const filterApiKeys = undefined; // No API key filter here
      const filterHosts = chain.networks.map((network) => {
        const url = new URL(network.https);
        return url.host;
      });
      populateV4Analytics(
        chart,
        data,
        lookupHostName,
        filterApiKeys,
        filterHosts,
      );
      setChart(chart);
    }
  }, [analyticsV4, chain, chains]);

  return (
    <RootPanel withNavbar>
      <CustomTabs
        path={PageRoute.ENDPOINT_TABS}
        tabs={tabs}
        defaultTabValue={EndpointTabValue.METRICS}
      />
      <Container fluid>
        <Loading isLoading={chains.state === "loading"}>
          {/* <GetStartedGrid /> */}
          {chart && <MetricsSummary chart={chart} />}
          <Space h="2vh" />
          <TextComponent title={t("metrics.responses_per_day")} mb="sm" />
          {chart && (
            <UsageChart
              interval={"day"}
              daysBack={30}
              chain={chain}
              label={"Responses"}
            />
          )}
          <Space h="2vh" />
          {chart && <MethodChart chart={chart} />}
        </Loading>
      </Container>
    </RootPanel>
  );
};
