import * as ct from "countries-and-timezones";

export const countryName = (countryCode: string): string | undefined => {
  return ct.getCountry(countryCode)?.name;
};

export const currentCountry = (): ReturnType<
  typeof ct.getCountryForTimezone
> => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  if (timezone === "" || !timezone) {
    return null;
  }

  const data = ct.getCountryForTimezone(timezone);
  return data;
};
