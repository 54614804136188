import { ReactNode } from "react";
import {
  ButtonProps as MantineButtonProps,
  Button as MantineButton,
  Space,
} from "@mantine/core";
import { Link } from "react-router-dom";
import { theme } from "src/ui/theme";

type LinkButtonProps = {
  text: string;
  link: string;
  linkState?: object;
  onClick?: () => void;
  icon?: ReactNode;
} & MantineButtonProps;

export const LinkButton = ({
  text,
  link,
  linkState = {},
  styles,
  onClick,
  icon,
  ...buttonProps
}: LinkButtonProps) => {
  return (
    <Link to={link} state={linkState}>
      <MantineButton
        styles={styles}
        color={theme.colors?.green?.[5]}
        variant={buttonProps.variant ? buttonProps.variant : "filled"}
        onClick={onClick}
        {...buttonProps}
      >
        {icon && <Space pr={"xs"}>{icon}</Space>}
        {text}
      </MantineButton>
    </Link>
  );
};
