import React, { useCallback, useEffect, useState } from "react";
import { Text } from "@mantine/core";

export const paddingOne = "0";
export const paddingTwo = "00";

export type MaintenanceTimingProps = {
  start: Date;
  expectedEnd: Date;
};

export const MaintenanceTiming: React.FC<MaintenanceTimingProps> = ({
  start,
  expectedEnd,
}) => {
  const date = expectedEnd;
  const [remaining, setRemaining] = useState(0);
  const [hours, setHours] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(0);

  const countDown = () => {
    setRemaining((previous) => previous - 1);
  };

  const updateDisplay = useCallback(() => {
    const minutes = remaining / 60;
    const hours = minutes / 60;

    setMinutes(Math.floor(minutes % 60));
    setHours(Math.floor(hours % 24));
  }, [remaining]);

  useEffect(() => {
    if (typeof date === "undefined") {
      return;
    }

    const futureTime = date.getTime();
    const currentTime = new Date().getTime();
    if (currentTime >= futureTime) {
      return;
    }

    setRemaining(Math.ceil((futureTime - currentTime) / 1000));
  }, [date]);

  useEffect(() => {
    if (remaining >= 0) {
      const timeoutReference = setTimeout(() => {
        countDown();
      }, 1000);

      updateDisplay();

      return () => {
        clearTimeout(timeoutReference);
      };
    }
    return;
  }, [remaining, updateDisplay]);

  let hoursText = "hours";
  if (hours === 1) {
    hoursText = "hour";
  }

  let minutesText = "minutes";
  if (minutes === 1) {
    minutesText = "minute";
  }

  let remainingText = "";
  if (hours > 0 && minutes > 0) {
    remainingText = `${hours} ${hoursText} and ${minutes} ${minutesText}`;
  } else if (hours > 0 && minutes === 0) {
    remainingText = `${hours} ${hoursText}`;
  } else if (hours === 0 && minutes > 0) {
    remainingText = `${minutes} ${minutesText}`;
  }

  const startFmt = `${start.getHours()}:${start.getMinutes()}`;
  const endFmt = `${expectedEnd.getHours()}:${expectedEnd.getMinutes()}`;

  return (
    <Text>
      Maintenance started at {startFmt} and we expect it to be completed in{" "}
      <Text span fw={700}>
        {remainingText}
      </Text>{" "}
      (by {endFmt}).
    </Text>
  );
};
