import { LinkButton } from "../buttons";
import classes from "./text-card.module.css";
import { Paper, Space, Text } from "@mantine/core";

type TextCardProps = {
  title: string;
  text: string;
  button?: {
    text: string;
    link: string;
  };
};

export const TextCard = ({ title, text, button }: TextCardProps) => {
  return (
    <Paper
      className={classes.textcard}
      shadow="lg"
      p="xl"
      radius="md"
      withBorder
    >
      <Text>
        <b>{title}</b>
      </Text>
      <Space h="sm" />
      <Text>{text}</Text>
      {button && (
        <LinkButton
          styles={{ root: { marginTop: "30px" } }}
          text={button.text}
          link={button.link}
        ></LinkButton>
      )}
    </Paper>
  );
};
