// Use the toConsider parameter to specify which keys should be
// converted to camelCase.
export const recursiveToCamelCase = (
  item: unknown,
  toConsider: string[] | undefined = undefined,
): unknown => {
  if (Array.isArray(item)) {
    return item.map((el: unknown) => recursiveToCamelCase(el, toConsider));
  } else if (typeof item === "function" || item !== Object(item)) {
    return item;
  }
  return Object.fromEntries(
    Object.entries(item as Record<string, unknown>).map(
      ([key, value]: [string, unknown]) => {
        if (toConsider == null) {
          return [toCamelCase(key), recursiveToCamelCase(value, toConsider)];
        } else if (toConsider.includes(key)) {
          return [toCamelCase(key), recursiveToCamelCase(value, toConsider)];
        } else {
          // toConsider has entries and key was not one of them, leave it
          // untouched.
          return [key, recursiveToCamelCase(value, toConsider)];
        }
      },
    ),
  );
};

const toCamelCase = (item: string): string => {
  return item.replace(/([-_][a-z0-9])/gi, (c) =>
    c.toUpperCase().replace(/[-_]/g, ""),
  );
};
