import { useEffect } from "react";
import api, { BadCredentialsError, LoginInput, User } from "../api/marly";
import userApi, {
  AnalyticsV4,
  BadTokenError,
  CurrentSubscription,
  DedicatedNodeRequest,
  EmailNotVerifiedError,
  Interval,
  Period,
  ResetPassword,
  RpsAnalytics,
  UserUpdate,
} from "../api/user";
import {
  LOADING_STATE,
  Error,
  RequestState,
  rejectedState,
  fulfilledState,
  EMPTY_STATE,
} from "./core/request_state";
import { cookieSelector, isAuthenticated } from "./authentication";
import { useAppDispatch, useAppSelector } from "src/core/hooks";
import { RootState } from "src/store";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { useCookies } from "react-cookie";

export interface UserState {
  user: RequestState<User>;
  login: RequestState<null>; // Track login request
  userUpdate: RequestState<null>;
  analyticsV4: Record<string, RequestState<AnalyticsV4>>;
  rpsAnalytics: RequestState<RpsAnalytics>;
  subscription: RequestState<CurrentSubscription>;
  cancelCurrent: RequestState<null>; // track request only
  forgotPassword: RequestState<null>; // Used to track error state
  resetPassword: RequestState<null>; // Used to track error state
  requestDedicatedNode: RequestState<null>;
}

const initialState: UserState = {
  user: EMPTY_STATE,
  login: EMPTY_STATE,
  userUpdate: EMPTY_STATE,
  analyticsV4: {},
  rpsAnalytics: EMPTY_STATE,
  subscription: EMPTY_STATE,
  cancelCurrent: EMPTY_STATE,
  forgotPassword: EMPTY_STATE,
  resetPassword: EMPTY_STATE,
  requestDedicatedNode: EMPTY_STATE,
};

const analyticsV4Key = (interval: Interval, period: Period): string => {
  return `${interval}${period}`;
};

export const loadCurrentUser = createAsyncThunk(
  "user/current",
  async (_input, { rejectWithValue }) => {
    try {
      const response = await api.currentUser();
      return response.data;
    } catch (error: unknown) {
      // Unknown error
      console.error("Unknown login error", error);
      return rejectWithValue({ type: "unknown", serverMessage: "" });
    }
  },
);

export const login = createAsyncThunk(
  "auth/login",
  async (input: LoginInput, { rejectWithValue }) => {
    try {
      const response = await api.login(input);
      // We get no body, only a cookie
      return response.data;
    } catch (error: unknown) {
      if (error instanceof BadCredentialsError) {
        return rejectWithValue({
          type: "LOGIN_BAD_CREDENTIALS",
          serverMessage: error.detail,
        });
      }
      // Unknown error
      console.error("Unknown login error", error);
      return rejectWithValue({ type: "unknown", serverMessage: "" });
    }
  },
);

export const updateUser = createAsyncThunk(
  "user/update",
  async (input: UserUpdate, { rejectWithValue }) => {
    try {
      await userApi.updateUser(input);
      // We get no usable response
      return null;
    } catch (error: unknown) {
      // Unknown error
      console.error("Unknown login error", error);
      return rejectWithValue({ type: "unknown", serverMessage: "" });
    }
  },
);

export const getAnalyticsV4 = createAsyncThunk(
  "user/getAnalyticsV4",
  async (
    {
      interval,
      period,
      apiKeys,
      domains,
    }: {
      interval: Interval;
      period: Period;
      apiKeys?: string[];
      domains?: string[];
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await userApi.getAnalyticsV4(
        interval,
        period,
        apiKeys,
        domains,
      );
      return response.data;
    } catch (error: unknown) {
      // Unknown error
      console.error("Unknown getAnalytics error", error);
      return rejectWithValue({ type: "unknown", serverMessage: "" });
    }
  },
);

export const getRpsAnalytics = createAsyncThunk(
  "user/getRpsAnalytics",
  async (_, { rejectWithValue }) => {
    try {
      const response = await userApi.getRpsAnalytics();
      return response.data;
    } catch (error: unknown) {
      // Unknown error
      console.error("Unknown error", error);
      return rejectWithValue({ type: "unknown", serverMessage: "" });
    }
  },
);
export const getSubscription = createAsyncThunk(
  "user/getSubscription",
  async (_, { rejectWithValue }) => {
    try {
      const response = await userApi.getCurrentSubscription();
      return response.data;
    } catch (error: unknown) {
      // Unknown error
      console.error("Unknown getSubscription error", error);
      return rejectWithValue({ type: "unknown", serverMessage: "" });
    }
  },
);

export const cancelCurrent = createAsyncThunk(
  "checkout/cancelCurrent",
  async (apiKeyToKeep: string, { rejectWithValue }) => {
    try {
      await userApi.cancelCurrent(apiKeyToKeep);
      return null;
    } catch (error: unknown) {
      // Unknown error
      console.error("Unknown error", error);
      return rejectWithValue({ type: "unknown", serverMessage: "" });
    }
  },
);

export const forgotPassword = createAsyncThunk(
  "user/forgotPassword",
  async (email: string, { rejectWithValue }) => {
    try {
      const response = await userApi.forgotPassword(email);
      return response.data;
    } catch (error: unknown) {
      if (error instanceof EmailNotVerifiedError) {
        return rejectWithValue({
          type: "NotVerified",
          serverMessage: error.detail,
        });
      }
      // Unknown error
      console.error("Unknown error", error);
      return rejectWithValue({ type: "unknown", serverMessage: "" });
    }
  },
);

export const resetPassword = createAsyncThunk(
  "user/resetPassword",
  async (input: ResetPassword, { rejectWithValue }) => {
    try {
      const response = await userApi.resetPassword(input);
      return response.data;
    } catch (error: unknown) {
      if (error instanceof BadTokenError) {
        return rejectWithValue({
          type: "BadToken",
          serverMessage: error.detail,
        });
      }
      // Unknown error
      console.error("Unknown error", error);
      return rejectWithValue({ type: "unknown", serverMessage: "" });
    }
  },
);

export const requestDedicatedNode = createAsyncThunk(
  "user/requestDedicatedNode",
  async (input: DedicatedNodeRequest, { rejectWithValue }) => {
    try {
      const response = await userApi.requestDedicatedNode(input);
      return response.data;
    } catch (error: unknown) {
      // Unknown error
      console.error("Unknown error", error);
      return rejectWithValue({ type: "unknown", serverMessage: "" });
    }
  },
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    reloadCurrentSubscription(state) {
      state.subscription = EMPTY_STATE;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(loadCurrentUser.pending, (state, _action) => {
      state.user = LOADING_STATE;
    });
    builder.addCase(
      loadCurrentUser.rejected,
      (state, action: PayloadAction<unknown>) => {
        const error = action.payload as Error;

        state.user = rejectedState(error);
      },
    );
    builder.addCase(
      loadCurrentUser.fulfilled,
      (state, action: PayloadAction<User>) => {
        state.user = fulfilledState(action.payload);
      },
    );

    builder.addCase(login.pending, (state, _action) => {
      state.login = LOADING_STATE;
    });
    builder.addCase(login.rejected, (state, action: PayloadAction<unknown>) => {
      const error = action.payload as Error;

      state.login = rejectedState(error);
    });
    builder.addCase(login.fulfilled, (state, _action) => {
      state.login = fulfilledState(null);
      state.user = EMPTY_STATE; // Reset the current user to force a refresh
    });

    builder.addCase(updateUser.pending, (state, _action) => {
      state.userUpdate = LOADING_STATE;
    });
    builder.addCase(
      updateUser.rejected,
      (state, action: PayloadAction<unknown>) => {
        const error = action.payload as Error;

        state.userUpdate = rejectedState(error);
      },
    );
    builder.addCase(
      updateUser.fulfilled,
      (state, action: PayloadAction<null>) => {
        state.userUpdate = fulfilledState(action.payload);
        // Current update user interface does not return the new user so mark it as EMPTY to force a refresh when needed?
        state.user = EMPTY_STATE;
      },
    );

    builder.addCase(getAnalyticsV4.pending, (state, action) => {
      const interval = action.meta.arg.interval;
      const period = action.meta.arg.period;
      state.analyticsV4[analyticsV4Key(interval, period)] = LOADING_STATE;
    });
    builder.addCase(getAnalyticsV4.rejected, (state, action) => {
      const error = action.payload as Error;
      const interval = action.meta.arg.interval;
      const period = action.meta.arg.period;
      state.analyticsV4[analyticsV4Key(interval, period)] =
        rejectedState(error);
    });
    builder.addCase(getAnalyticsV4.fulfilled, (state, { payload, meta }) => {
      const interval = meta.arg.interval;
      const period = meta.arg.period;
      state.analyticsV4[analyticsV4Key(interval, period)] =
        fulfilledState(payload);
    });

    builder.addCase(getRpsAnalytics.pending, (state, _action) => {
      state.rpsAnalytics = LOADING_STATE;
    });
    builder.addCase(
      getRpsAnalytics.rejected,
      (state, action: PayloadAction<unknown>) => {
        const error = action.payload as Error;

        state.rpsAnalytics = rejectedState(error);
      },
    );
    builder.addCase(getRpsAnalytics.fulfilled, (state, action) => {
      state.rpsAnalytics = fulfilledState(action.payload);
    });

    builder.addCase(getSubscription.pending, (state, _action) => {
      state.subscription = LOADING_STATE;
    });
    builder.addCase(
      getSubscription.rejected,
      (state, action: PayloadAction<unknown>) => {
        const error = action.payload as Error;

        state.subscription = rejectedState(error);
      },
    );
    builder.addCase(
      getSubscription.fulfilled,
      (state, action: PayloadAction<CurrentSubscription>) => {
        state.subscription = fulfilledState(action.payload);
      },
    );

    builder.addCase(cancelCurrent.pending, (state, _action) => {
      state.cancelCurrent = LOADING_STATE;
    });
    builder.addCase(
      cancelCurrent.rejected,
      (state, action: PayloadAction<unknown>) => {
        const error = action.payload as Error;

        state.cancelCurrent = rejectedState(error);
      },
    );
    builder.addCase(
      cancelCurrent.fulfilled,
      (state, _action: PayloadAction<null>) => {
        state.cancelCurrent = fulfilledState(null);
        state.subscription = EMPTY_STATE; // Reset the current subscription to force a refresh
      },
    );

    builder.addCase(forgotPassword.pending, (state, _action) => {
      state.forgotPassword = LOADING_STATE;
    });
    builder.addCase(
      forgotPassword.rejected,
      (state, action: PayloadAction<unknown>) => {
        const error = action.payload as Error;

        state.forgotPassword = rejectedState(error);
      },
    );
    builder.addCase(
      forgotPassword.fulfilled,
      (state, action: PayloadAction<null>) => {
        state.forgotPassword = fulfilledState(action.payload);
      },
    );

    builder.addCase(resetPassword.pending, (state, _action) => {
      state.resetPassword = LOADING_STATE;
    });
    builder.addCase(
      resetPassword.rejected,
      (state, action: PayloadAction<unknown>) => {
        const error = action.payload as Error;

        state.resetPassword = rejectedState(error);
      },
    );
    builder.addCase(
      resetPassword.fulfilled,
      (state, action: PayloadAction<null>) => {
        state.resetPassword = fulfilledState(action.payload);
      },
    );

    builder.addCase(requestDedicatedNode.pending, (state, _action) => {
      state.requestDedicatedNode = LOADING_STATE;
    });
    builder.addCase(
      requestDedicatedNode.rejected,
      (state, action: PayloadAction<unknown>) => {
        const error = action.payload as Error;

        state.requestDedicatedNode = rejectedState(error);
      },
    );
    builder.addCase(
      requestDedicatedNode.fulfilled,
      (state, action: PayloadAction<null>) => {
        state.requestDedicatedNode = fulfilledState(action.payload);
      },
    );
  },
});

// Action creators are generated for each case reducer function
// export const { login } = authSlice.actions;

/* Selectors */
export const selectLoading = (state: RootState): boolean => state.user.loading;
export const selectLoginState = (state: RootState): RequestState<null> =>
  state.user.login;
export const selectUser = (state: RootState): User | null => state.user.user;
export const selectError = (state: RootState): Error | null => state.user.error;
export const selectForgotPassword = (state: RootState): RequestState<null> =>
  state.user.forgotPassword;
export const selectResetPassword = (state: RootState): RequestState<null> =>
  state.user.resetPassword;
const selectUserState = (state: RootState): UserState => state.user;
const selectUserSubscription = (
  state: RootState,
): RequestState<CurrentSubscription> => state.user.subscription;
export const selectSubscriptionCancelState = (
  state: RootState,
): RequestState<null> => state.user.cancelCurrent;

export const useUser = (): RequestState<User> => {
  const dispatch = useAppDispatch();
  const userState = useAppSelector(selectUserState);
  const [cookies] = useCookies(cookieSelector);

  useEffect(() => {
    if (userState.user === EMPTY_STATE && isAuthenticated(cookies)) {
      dispatch(loadCurrentUser());
    }
  }, [cookies, userState, dispatch]);

  // TODO We should probably handle errors in a generic way?
  // Throw here and have a error boundary that handles it?

  return userState.user;
};

export const useAnalyticsV4 = (
  interval: Interval,
  period: Period = "default",
  apiKeys?: string[],
  domains?: string[],
): RequestState<AnalyticsV4> => {
  const dispatch = useAppDispatch();
  const userState = useAppSelector(selectUserState);
  const key = analyticsV4Key(interval, period);

  useEffect(() => {
    const state =
      key in userState.analyticsV4 ? userState.analyticsV4[key] : EMPTY_STATE;
    if (state.state === "empty") {
      dispatch(getAnalyticsV4({ interval, period, apiKeys, domains }));
    }
  }, [key, userState, period, interval, apiKeys, domains, dispatch]);

  // TODO We should probably handle errors in a generic way?
  // Throw here and have a error boundary that handles it?

  return key in userState.analyticsV4
    ? userState.analyticsV4[key]
    : EMPTY_STATE;
};

export const useRpsAnalytics = (): RequestState<RpsAnalytics> => {
  const dispatch = useAppDispatch();
  const userState = useAppSelector(selectUserState);

  useEffect(() => {
    if (userState.rpsAnalytics.state === "empty") {
      dispatch(getRpsAnalytics());
    }
  }, [dispatch, userState]);

  // TODO We should probably handle errors in a generic way?
  // Throw here and have a error boundary that handles it?

  return userState.rpsAnalytics;
};

export const useCurrentSubscription = (): RequestState<CurrentSubscription> => {
  const dispatch = useAppDispatch();
  const subscription = useAppSelector(selectUserSubscription);

  useEffect(() => {
    if (subscription.state == "empty") {
      dispatch(getSubscription());
    }
  }, [subscription, dispatch]);

  // TODO We should probably handle errors in a generic way?
  // Throw here and have a error boundary that handles it?

  return subscription;
};

export const { reloadCurrentSubscription } = userSlice.actions;
export default userSlice.reducer;
