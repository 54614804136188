export const SellingPointCheckbox = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 5.53015C5.73478 5.53015 5.48043 5.63551 5.29289 5.82304C5.10536 6.01058 5 6.26493 5 6.53015V18.5302C5 18.7954 5.10536 19.0497 5.29289 19.2373C5.48043 19.4248 5.73478 19.5302 6 19.5302H18C18.2652 19.5302 18.5196 19.4248 18.7071 19.2373C18.8946 19.0497 19 18.7954 19 18.5302V12.5302C19 11.9779 19.4477 11.5302 20 11.5302C20.5523 11.5302 21 11.9779 21 12.5302V18.5302C21 19.3258 20.6839 20.0889 20.1213 20.6515C19.5587 21.2141 18.7957 21.5302 18 21.5302H6C5.20435 21.5302 4.44129 21.2141 3.87868 20.6515C3.31607 20.0889 3 19.3258 3 18.5302V6.53015C3 5.7345 3.31607 4.97144 3.87868 4.40883C4.44129 3.84622 5.20435 3.53015 6 3.53015H15C15.5523 3.53015 16 3.97787 16 4.53015C16 5.08244 15.5523 5.53015 15 5.53015H6ZM19.2929 5.82304C19.6834 5.43252 20.3166 5.43252 20.7071 5.82304C21.0976 6.21357 21.0976 6.84673 20.7071 7.23726L12.7071 15.2373C12.3166 15.6278 11.6834 15.6278 11.2929 15.2373L8.29289 12.2373C7.90237 11.8467 7.90237 11.2136 8.29289 10.823C8.68342 10.4325 9.31658 10.4325 9.70711 10.823L12 13.1159L19.2929 5.82304Z"
        fill="#52B196"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 5.53015C5.73478 5.53015 5.48043 5.63551 5.29289 5.82304C5.10536 6.01058 5 6.26493 5 6.53015V18.5302C5 18.7954 5.10536 19.0497 5.29289 19.2373C5.48043 19.4248 5.73478 19.5302 6 19.5302H18C18.2652 19.5302 18.5196 19.4248 18.7071 19.2373C18.8946 19.0497 19 18.7954 19 18.5302V12.5302C19 11.9779 19.4477 11.5302 20 11.5302C20.5523 11.5302 21 11.9779 21 12.5302V18.5302C21 19.3258 20.6839 20.0889 20.1213 20.6515C19.5587 21.2141 18.7957 21.5302 18 21.5302H6C5.20435 21.5302 4.44129 21.2141 3.87868 20.6515C3.31607 20.0889 3 19.3258 3 18.5302V6.53015C3 5.7345 3.31607 4.97144 3.87868 4.40883C4.44129 3.84622 5.20435 3.53015 6 3.53015H15C15.5523 3.53015 16 3.97787 16 4.53015C16 5.08244 15.5523 5.53015 15 5.53015H6ZM19.2929 5.82304C19.6834 5.43252 20.3166 5.43252 20.7071 5.82304C21.0976 6.21357 21.0976 6.84673 20.7071 7.23726L12.7071 15.2373C12.3166 15.6278 11.6834 15.6278 11.2929 15.2373L8.29289 12.2373C7.90237 11.8467 7.90237 11.2136 8.29289 10.823C8.68342 10.4325 9.31658 10.4325 9.70711 10.823L12 13.1159L19.2929 5.82304Z"
        fill="black"
        fillOpacity="0.2"
      />
    </svg>
  );
};
