import { useEffect } from "react";
import {
  EMPTY_STATE,
  Error,
  LOADING_STATE,
  RequestState,
  fulfilledState,
  rejectedState,
} from "./core/request_state";
import { useAppDispatch, useAppSelector } from "src/core/hooks";
import { RootState } from "src/store";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiKeysApi, {
  ApiKey,
  CreateApiKeyInput,
  UpdateApiKey,
} from "src/api/api_keys";

export interface ApiKeyState {
  apiKeys: ApiKey[] | null;
  // Result will be stored in apiKeys, these states only tracks the requests
  listApiKeys: RequestState<null>;
  createApiKey: RequestState<null>;
  updateApiKey: RequestState<null>;
  deleteApiKey: RequestState<null>;
}

const initialState: ApiKeyState = {
  apiKeys: null,
  listApiKeys: EMPTY_STATE,
  createApiKey: EMPTY_STATE,
  updateApiKey: EMPTY_STATE,
  deleteApiKey: EMPTY_STATE,
};

export const getApiKeys = createAsyncThunk(
  "user/getApiKeys",
  async (_input, { rejectWithValue }) => {
    try {
      const response = await apiKeysApi.getApiKeys();
      return response.data;
    } catch (error: unknown) {
      // Unknown error
      console.error("Unkwon login error", error);
      return rejectWithValue({ type: "unknown", serverMessage: "" });
    }
  },
);

export const createApiKey = createAsyncThunk(
  "user/createApiKey",
  async (input: CreateApiKeyInput, { rejectWithValue }) => {
    try {
      const response = await apiKeysApi.createApiKey(input);
      return response.data;
    } catch (error: unknown) {
      // Unknown error
      console.error("Unkwon createApiKey error", error);
      return rejectWithValue({ type: "unknown", serverMessage: "" });
    }
  },
);

export const updateApiKey = createAsyncThunk(
  "user/updateApiKey",
  async (
    { key, update }: { key: ApiKey; update: UpdateApiKey },
    { rejectWithValue },
  ) => {
    try {
      const response = await apiKeysApi.updateApiKey(key, update);
      return response.data;
    } catch (error: unknown) {
      // Unknown error
      console.error("Unkwon updateApiKey error", error);
      return rejectWithValue({ type: "unknown", serverMessage: "" });
    }
  },
);

export const deleteApiKey = createAsyncThunk(
  "user/deleteApiKey",
  async (key: ApiKey, { rejectWithValue }) => {
    try {
      const response = await apiKeysApi.deleteApiKey(key);
      return response.data;
    } catch (error: unknown) {
      // Unknown error
      console.error("Unkwon deleteApiKey error", error);
      return rejectWithValue({ type: "unknown", serverMessage: "" });
    }
  },
);

export const apiKeySlice = createSlice({
  name: "apiKeys",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // *** List ***
    builder.addCase(
      getApiKeys.pending,
      (state: ApiKeyState, _action: PayloadAction<unknown>) => {
        state.listApiKeys = LOADING_STATE;
      },
    );
    builder.addCase(
      getApiKeys.rejected,
      (state: ApiKeyState, action: PayloadAction<unknown>) => {
        const error = action.payload as Error;

        state.listApiKeys = rejectedState(error);
      },
    );
    builder.addCase(
      getApiKeys.fulfilled,
      (state, action: PayloadAction<ApiKey[]>) => {
        state.apiKeys = action.payload;
        state.listApiKeys = fulfilledState(null);
      },
    );

    // *** Create ***
    builder.addCase(
      createApiKey.pending,
      (state: ApiKeyState, _action: PayloadAction<unknown>) => {
        state.createApiKey = LOADING_STATE;
      },
    );
    builder.addCase(
      createApiKey.rejected,
      (state: ApiKeyState, action: PayloadAction<unknown>) => {
        const error = action.payload as Error;

        state.createApiKey = rejectedState(error);
      },
    );
    builder.addCase(
      createApiKey.fulfilled,
      (state, action: PayloadAction<ApiKey>) => {
        state.apiKeys = state.apiKeys
          ? [...state.apiKeys, action.payload]
          : [action.payload];
        state.createApiKey = fulfilledState(null);
      },
    );

    // *** Update ***
    builder.addCase(
      updateApiKey.pending,
      (state: ApiKeyState, _action: PayloadAction<unknown>) => {
        state.createApiKey = LOADING_STATE;
      },
    );
    builder.addCase(
      updateApiKey.rejected,
      (state: ApiKeyState, action: PayloadAction<unknown>) => {
        const error = action.payload as Error;

        state.createApiKey = rejectedState(error);
      },
    );
    builder.addCase(
      updateApiKey.fulfilled,
      (state, action: PayloadAction<ApiKey>) => {
        if (state.apiKeys) {
          const index = state.apiKeys?.findIndex(
            (k) => k.id === action.payload.id,
          );
          state.apiKeys.splice(index, 1, action.payload);
        } else {
          // Update of a key we don't have, should not happen but if it does
          // just insert the updated value...
          console.error("Update of key that does not exists in state");
          state.apiKeys = state.apiKeys
            ? [...state.apiKeys, action.payload]
            : [action.payload];
        }
        state.updateApiKey = fulfilledState(null);
      },
    );

    // *** Delete ***
    builder.addCase(
      deleteApiKey.pending,
      (state: ApiKeyState, _action: PayloadAction<unknown>) => {
        state.createApiKey = LOADING_STATE;
      },
    );
    builder.addCase(
      deleteApiKey.rejected,
      (state: ApiKeyState, action: PayloadAction<unknown>) => {
        const error = action.payload as Error;

        state.createApiKey = rejectedState(error);
      },
    );
    builder.addCase(
      deleteApiKey.fulfilled,
      (state, action: PayloadAction<number>) => {
        if (state.apiKeys) {
          const index = state.apiKeys?.findIndex(
            (k) => k.id === action.payload,
          );
          state.apiKeys.splice(index, 1);
        }
        state.deleteApiKey = fulfilledState(null);
      },
    );
  },
});

// Action creators are generated for each case reducer function
// export const { login } = authSlice.actions;

/* Selectors */
const selectApiKeysState = (state: RootState): ApiKeyState => state.apiKeys;

export const useUserApiKeys = (): ApiKey[] | null => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(selectApiKeysState);

  useEffect(() => {
    if (state.apiKeys == null && state.listApiKeys === EMPTY_STATE) {
      dispatch(getApiKeys());
    }
  }, [state, dispatch]);

  // TODO We should probably handle errors in a generic way?
  // Throw here and have a error boundary that handles it?

  return state.apiKeys;
};

// export const { updateUserSubscriptionId } = apiKeySlice.actions;
export default apiKeySlice.reducer;
