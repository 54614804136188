import { FC } from "react";
import { BaseIconProps, Icon } from "./base/icon";

export const StopIcon: FC<BaseIconProps> = (props) => {
  return (
    <Icon {...props}>
      <path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm192-96H320c17.7 0 32 14.3 32 32V320c0 17.7-14.3 32-32 32H192c-17.7 0-32-14.3-32-32V192c0-17.7 14.3-32 32-32z" />
    </Icon>
  );
};
