import { RootPanel } from "../../components/panels/root-panel/root-panel";
import {
  PageRoute,
  UsageBillingTabValue,
  getUsageBillingTabs,
} from "src/utility/utils";
import { CustomTabs } from "src/ui/components/panels/custom-tabs";

export const PaymentMethods = () => {
  return (
    <RootPanel withNavbar>
      <CustomTabs
        path={PageRoute.USAGE_BILLING_TABS}
        tabs={getUsageBillingTabs()}
        defaultTabValue={UsageBillingTabValue.PAYMENT_METHODS}
      />
    </RootPanel>
  );
};
