import { useEffect } from "react";
import {
  EMPTY_STATE,
  Error,
  LOADING_STATE,
  RequestState,
  fulfilledState,
  rejectedState,
} from "./core/request_state";
import { useAppDispatch, useAppSelector } from "src/core/hooks";
import { RootState } from "src/store";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import notificationsAPI, { Notification } from "src/api/notifications";

export interface NotificationState {
  notifications: Notification[] | null;
  // Result will be stored in notifications, these states only tracks the requests
  getNotifications: RequestState<null>;
  markAsRead: RequestState<null>;
}

const initialState: NotificationState = {
  notifications: null,
  getNotifications: EMPTY_STATE,
  markAsRead: EMPTY_STATE,
};

export const getNotifications = createAsyncThunk(
  "notifications/getNotifications",
  async (_input, { rejectWithValue }) => {
    try {
      const response = await notificationsAPI.getNotifications();
      return response.data;
    } catch (error: unknown) {
      // Unknown error
      console.error("Unkwon error", error);
      return rejectWithValue({ type: "unknown", serverMessage: "" });
    }
  },
);

export const markAsRead = createAsyncThunk(
  "notifications/markAsRead",
  async (notification: Notification, { rejectWithValue }) => {
    try {
      const response = await notificationsAPI.markAsRead(notification);
      return response.data;
    } catch (error: unknown) {
      // Unknown error
      console.error("Unkwon createApiKey error", error);
      return rejectWithValue({ type: "unknown", serverMessage: "" });
    }
  },
);

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // *** List ***
    builder.addCase(
      getNotifications.pending,
      (state: NotificationState, _action: PayloadAction<unknown>) => {
        state.getNotifications = LOADING_STATE;
      },
    );
    builder.addCase(
      getNotifications.rejected,
      (state: NotificationState, action: PayloadAction<unknown>) => {
        const error = action.payload as Error;

        state.getNotifications = rejectedState(error);
      },
    );
    builder.addCase(
      getNotifications.fulfilled,
      (state, action: PayloadAction<Notification[]>) => {
        state.notifications = action.payload;
        state.getNotifications = fulfilledState(null);
      },
    );

    // *** markAsRead ***
    builder.addCase(
      markAsRead.pending,
      (state: NotificationState, _action: PayloadAction<unknown>) => {
        state.markAsRead = LOADING_STATE;
      },
    );
    builder.addCase(
      markAsRead.rejected,
      (state: NotificationState, action: PayloadAction<unknown>) => {
        const error = action.payload as Error;

        state.markAsRead = rejectedState(error);
      },
    );
    builder.addCase(
      markAsRead.fulfilled,
      (state, action: PayloadAction<Notification>) => {
        if (state.notifications) {
          const index = state.notifications?.findIndex(
            (k) => k.id === action.payload.id,
          );
          state.notifications.splice(index, 1, action.payload);
        } else {
          // Update of a notification we don't have, should not happen but if
          // it does just insert the updated value...
          console.error("Update of notification that does not exists in state");
          state.notifications = state.notifications
            ? [...state.notifications, action.payload]
            : [action.payload];
        }
        state.markAsRead = fulfilledState(null);
      },
    );
  },
});

// Action creators are generated for each case reducer function
// export const { login } = authSlice.actions;

/* Selectors */
const selectNotificationState = (state: RootState): NotificationState =>
  state.notifications;

export const useNotifications = (): Notification[] | null => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(selectNotificationState);

  useEffect(() => {
    if (state.notifications == null && state.getNotifications === EMPTY_STATE) {
      dispatch(getNotifications());
    }
  }, [state, dispatch]);

  // TODO We should probably handle errors in a generic way?
  // Throw here and have a error boundary that handles it?

  return state.notifications;
};

// export const { } = notificationsSlice.actions;
export default notificationsSlice.reducer;
