import { FC, useEffect, useState } from "react";
import { Loading } from "../loading";
import { Button, DangerButton } from "../buttons";
import {
  Alert,
  Flex,
  List,
  Modal,
  Select,
  SelectProps,
  Text,
} from "@mantine/core";
import { useUserApiKeys } from "src/store/api_keys";
import { ApiKey } from "src/api/api_keys";
import { useAppDispatch, useAppSelector } from "src/core/hooks";
import { useSubscriptionCancelChecks } from "src/store/checkout";
import { cancelCurrent, selectSubscriptionCancelState } from "src/store/user";

interface SelectApiKeyProps {
  onChange: (value: string | null) => void;
  apiKeys: ApiKey[] | null;
}

const SelectApiKey: FC<SelectApiKeyProps> = ({ onChange, apiKeys }) => {
  const [selectedApiKey, setSelectedApiKey] = useState<ApiKey | null>(null);

  const renderSelectOption: SelectProps["renderOption"] = ({ option }) =>
    option.label !== option.value ? (
      <Text>
        <Text span fw={700}>
          {option.label}
        </Text>{" "}
        - {option.value}
      </Text>
    ) : (
      <Text>{option.value}</Text>
    );
  return (
    <>
      {apiKeys && apiKeys.length > 1 && (
        <Select
          id="select-api-key"
          label="Select API key"
          placeholder="Select an api key"
          withCheckIcon={false}
          onChange={(value) => {
            if (!value || !apiKeys) {
              return; // Do nothing if value is null or apiKeys is undefined
            }

            const newApiKey =
              apiKeys.find((key) => key.apiKey === value) || null;

            if (selectedApiKey?.apiKey === newApiKey?.apiKey) {
              return; // Do not change if the selected key is the same as the current one
            }

            setSelectedApiKey(newApiKey); // Set the new API key if it's different
            onChange(value);
          }}
          data={apiKeys.map((key) => ({
            label: key.name || key.apiKey,
            value: key.apiKey,
          }))}
          renderOption={renderSelectOption}
          value={selectedApiKey?.apiKey}
        />
      )}
    </>
  );
};

interface ConfirmCancelPlanModalProps {
  show: boolean;
  onClose: () => void;
}

export const ConfirmCancelPlanModal: FC<ConfirmCancelPlanModalProps> = ({
  show,
  onClose,
}) => {
  const apiKeys = useUserApiKeys();
  const [selectedApiKey, setSelectedApiKey] = useState<string | null>(null);
  const checks = useSubscriptionCancelChecks(1);
  const cancelState = useAppSelector(selectSubscriptionCancelState);
  const dispatch = useAppDispatch();

  const onCancelConfirmed = () => {
    if (!selectedApiKey) {
      return;
    }
    dispatch(cancelCurrent(selectedApiKey)).then(() => {
      onClose();
    });
  };

  useEffect(() => {
    if (apiKeys) {
      const activeKeys = apiKeys.filter((key) => key.manualEnabled);
      if (activeKeys.length === 1) {
        // If we only have one active key, use that when canceling the plan
        setSelectedApiKey(activeKeys[0].apiKey);
      }
    }
  }, [apiKeys]);

  return (
    <>
      <Modal
        opened={show}
        onClose={onClose}
        title={<Text>Confirm Plan Cancellation</Text>}
      >
        <Loading isLoading={checks.state === "loading"}>
          <Flex direction="column" gap="md">
            <Text>
              You are about to downgrade to the Free Plan. This means that your
              account will be subject to daily and monthly quota limits and if
              they are exceeded, further RPC traffic will be blocked.
            </Text>
            {checks.state === "fulfilled" &&
              (checks.data.tooMuchDailyTraffic ||
                checks.data.tooMuchMonthlyTraffic) && (
                <>
                  <Alert
                    variant="light"
                    color="yellow"
                    title="Traffic will be blocked"
                  >
                    <List mb="md">
                      {checks.state === "fulfilled" &&
                        checks.data.tooMuchDailyTraffic && (
                          <List.Item>
                            <Text>
                              Today's usage is higher than the Free Plan's daily
                              quota limit.
                            </Text>
                          </List.Item>
                        )}
                      <List.Item>
                        <Text>
                          This month's usage is higher than the Free Plan's
                          monthly quota limit.
                        </Text>
                      </List.Item>
                    </List>
                    <Text>
                      Since your account's usage is above the Free Plan's quota
                      limits, further traffic will be blocked if you continue
                      with the cancellation.
                    </Text>
                  </Alert>
                </>
              )}
            {checks.state === "fulfilled" && checks.data.tooManyKeys && (
              <>
                <Alert
                  variant="light"
                  color="red"
                  title="Too many active API keys"
                >
                  <Flex direction="column" gap="md">
                    <List>
                      <List.Item>
                        <Text>
                          You have too many active keys for your new plan.
                        </Text>
                      </List.Item>
                    </List>
                    <Text>
                      Before you can continue with the cancellation you need to
                      select one API key that will stay active. The other keys
                      will be disabled.
                    </Text>
                    <SelectApiKey
                      apiKeys={apiKeys}
                      onChange={setSelectedApiKey}
                    />
                  </Flex>
                </Alert>
              </>
            )}
            <DangerButton
              text="I understand, please cancel my plan"
              onClick={onCancelConfirmed}
              disabled={selectedApiKey === null}
              loading={cancelState.state === "loading"}
            />
            <Button text="Stay on the current plan" onClick={onClose} />
          </Flex>
        </Loading>
      </Modal>
    </>
  );
};
