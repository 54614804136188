import { setupStore } from "src/store";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import "@mantine/charts/styles.css";
import { App } from "src/ui";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://151bac9969563c178fffcaeb8e709e82@o4508092832677888.ingest.de.sentry.io/4508092834578512",
  integrations: [],
});

const store = setupStore();

export class Application {
  private _domRoot: string;

  constructor(domRoot: string) {
    this._domRoot = domRoot;
  }

  async start(): Promise<void> {
    this._render();
  }

  private _render() {
    const appRoot = document.getElementById(this._domRoot);
    if (!appRoot) {
      throw new Error(
        `Could not render the application since an HTML element with id ${this._domRoot} could not be found`,
      );
    }
    const root = createRoot(appRoot);
    root.render(
      <Provider store={store}>
        <App />
      </Provider>,
    );
  }
}
