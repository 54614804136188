import { Box } from "@mantine/core";

export const GrayDwellirLogo = () => {
  return (
    <>
      <Box lightHidden>
        <GrayDwellirLogoDark />
      </Box>
      <Box darkHidden>
        <GrayDwellirLogoLight />
      </Box>
    </>
  );
};

const GrayDwellirLogoDark = () => {
  return (
    <svg
      width="207"
      height="268"
      viewBox="0 0 207 268"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_dd_7668_6365)">
        <g filter="url(#filter1_i_7668_6365)">
          <path
            d="M193.722 148.203C193.415 131.06 193.657 113.907 193.657 96.7598H193.57C193.57 77.5 193.574 58.2378 193.565 38.978C193.56 32.1513 193.194 31.4819 186.669 29.9378C164.179 24.619 141.683 19.3123 119.132 14.2545C111.929 12.6378 104.607 11.5359 97.1036 12.7731C70.48 17.1519 44.4108 23.952 18.2907 30.5419C15.2642 31.3031 13.6468 33.0164 13.4749 36.1048C13.4192 37.0786 13.32 38.0501 13.32 39.0239C13.3006 75.9196 13.3369 112.815 13.2619 149.713C13.2352 163.357 16.6031 176.09 23.1549 187.991C33.1594 206.166 47.5995 220.329 64.1364 232.521C75.1699 240.657 86.8861 247.643 99.612 252.894C102.452 254.066 104.96 254.052 107.793 252.875C117.839 248.707 127.245 243.393 136.235 237.33C152.973 226.039 167.854 212.799 179.154 195.903C188.802 181.481 194.037 165.759 193.722 148.203Z"
            fill="#242424"
          />
        </g>
        <path
          d="M23.5829 39.7699C23.5781 51.449 23.5757 63.1281 23.5757 74.8072C23.5757 99.3616 23.5757 124.755 23.5272 149.732C23.503 161.573 26.3237 172.474 32.1492 183.058C40.4564 198.152 52.5576 211.249 70.2349 224.282C81.4427 232.549 92.3357 238.812 103.534 243.428C103.59 243.452 103.638 243.469 103.679 243.484C103.728 243.467 103.783 243.447 103.851 243.418C112.645 239.769 121.359 235.004 130.489 228.844C148.019 217.02 161.142 204.389 170.621 190.218C179.526 176.903 183.727 163.216 183.46 148.386C183.245 136.359 183.298 124.184 183.349 112.411C183.356 110.625 183.364 108.82 183.371 107.003H183.306L183.308 80.5392C183.31 66.9148 183.31 53.2928 183.303 39.6684C159.706 34.0886 138.368 29.067 116.884 24.2485C111.076 22.9435 104.858 21.8754 98.7737 22.8783C74.7238 26.8317 50.5286 32.9552 27.13 38.8733L23.5829 39.7699ZM103.677 264C101.008 264 98.3403 263.451 95.6915 262.362C82.9317 257.096 70.6126 250.03 58.0368 240.758C38.1635 226.106 23.8129 210.459 14.1596 192.922C6.72402 179.414 2.96872 164.869 3.0002 149.693C3.05104 124.733 3.05346 99.3519 3.05346 74.8048C3.05346 62.8768 3.05346 50.9463 3.05831 39.0183C3.05831 37.9937 3.12126 37.0923 3.17694 36.2973C3.19631 36.0436 3.21326 35.7898 3.22779 35.5361C3.64908 28.0642 8.33897 22.4868 15.7745 20.6116L22.089 19.0167C45.9017 12.9898 70.5278 6.76242 95.4349 2.66641C105.013 1.09082 113.928 2.58908 121.385 4.26132C143.28 9.17171 164.975 14.2827 189.034 19.9736C192.615 20.8194 197.518 21.9793 200.787 26.3508C203.82 30.4082 203.823 34.9585 203.825 38.9724C203.835 52.8288 203.833 66.6852 203.833 80.5416L203.83 86.5177H203.917V96.759C203.917 102.013 203.896 107.343 203.874 112.498C203.823 124.16 203.772 136.221 203.985 148.018C204.319 166.899 198.84 184.924 187.69 201.591C176.708 218.011 161.757 232.479 141.98 245.816C131.7 252.751 121.804 258.154 111.732 262.335C109.059 263.444 106.367 264 103.677 264Z"
          fill="#2E2E2E"
        />
        <path
          d="M90.151 130.922C85.9841 130.006 82.8898 131.139 80.3572 133.594C75.1806 138.609 70.0234 143.652 65.0672 148.879C60.9972 153.173 61.0359 159.574 64.8033 163.511C68.7741 167.658 75.3598 167.803 79.8875 163.528C85.2722 158.441 90.4827 153.168 95.6641 147.873C98.2717 145.21 99.6058 142.047 98.2814 137.509C95.698 139.841 93.6061 141.897 91.3325 143.734C89.352 145.336 87.1729 145.191 85.3352 143.439C83.4612 141.653 83.4878 139.507 84.9454 137.49C86.4417 135.411 88.1196 133.466 90.151 130.922ZM118.419 118.01C122.181 119.223 125.237 118.15 127.803 115.666C133.273 110.369 138.781 105.098 144.008 99.567C148.41 94.9127 148.132 88.6587 143.774 84.5531C139.694 80.7035 133.653 80.7422 129.164 84.9639C123.765 90.0434 118.511 95.2849 113.315 100.577C110.71 103.233 109.455 106.391 110.884 110.371C113.552 109.197 114.922 107.22 116.465 105.444C118.528 103.064 121.046 103.054 123.314 104.702C125.929 106.604 125.6 109.132 123.767 111.538C122.109 113.713 119.431 115.067 118.419 118.01ZM88.5046 120.709C91.2236 120.579 93.8336 120.837 96.1725 122.162C98.0998 123.256 99.4823 122.729 100.921 121.255C102.395 119.747 103.489 118.476 102.538 116.055C98.7584 106.418 101.635 98.5327 109.056 91.7278C114.082 87.1194 118.571 81.9239 123.648 77.3759C130.946 70.8441 141.643 70.7836 149.127 76.8177C156.42 82.6972 158.463 93.0109 154.236 101.326C152.405 104.929 148.788 106.556 146.287 109.39C142.485 113.704 138.691 118.041 134.59 122.06C128.784 127.746 121.77 129.452 114.053 126.695C111.518 125.791 109.794 125.431 107.635 127.563C105.569 129.602 106.204 131.204 106.981 133.427C109.756 141.361 108.092 148.451 102.201 154.461C97.003 159.763 91.8047 165.067 86.4393 170.197C78.2822 178 66.5926 178.114 58.7261 170.647C50.9564 163.274 50.4286 151.155 57.9246 142.98C63.4765 136.922 69.4157 131.204 75.3792 125.542C78.9965 122.111 83.4515 120.342 88.5046 120.709Z"
          fill="#2E2E2E"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd_7668_6365"
          x="0"
          y="0"
          width="207"
          height="268"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0509804 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_7668_6365"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.101961 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_7668_6365"
            result="effect2_dropShadow_7668_6365"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_7668_6365"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_i_7668_6365"
          x="13.2617"
          y="12.2419"
          width="180.475"
          height="245.524"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.141176 0 0 0 0 0.141176 0 0 0 0 0.141176 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_7668_6365"
          />
        </filter>
      </defs>
    </svg>
  );
};

const GrayDwellirLogoLight = () => {
  return (
    <svg
      width="207"
      height="268"
      viewBox="0 0 207 268"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_dd_7695_26150)">
        <g filter="url(#filter1_i_7695_26150)">
          <path
            d="M193.722 148.203C193.415 131.06 193.657 113.907 193.657 96.7598H193.57C193.57 77.5 193.574 58.2378 193.565 38.978C193.56 32.1513 193.194 31.4819 186.669 29.9378C164.179 24.619 141.683 19.3123 119.132 14.2545C111.929 12.6378 104.607 11.5359 97.1036 12.7731C70.48 17.1519 44.4108 23.952 18.2907 30.5419C15.2642 31.3031 13.6468 33.0164 13.4749 36.1048C13.4192 37.0786 13.32 38.0501 13.32 39.0239C13.3006 75.9196 13.3369 112.815 13.2619 149.713C13.2352 163.357 16.6031 176.09 23.1549 187.991C33.1594 206.166 47.5995 220.329 64.1364 232.521C75.1699 240.657 86.8861 247.643 99.612 252.894C102.452 254.066 104.96 254.052 107.793 252.875C117.839 248.707 127.245 243.393 136.235 237.33C152.973 226.039 167.854 212.799 179.154 195.903C188.802 181.481 194.037 165.759 193.722 148.203Z"
            fill="#F1F3F5"
          />
        </g>
        <path
          d="M23.5829 39.7699C23.5781 51.449 23.5757 63.1281 23.5757 74.8072C23.5757 99.3616 23.5757 124.755 23.5272 149.732C23.503 161.573 26.3237 172.474 32.1492 183.058C40.4564 198.152 52.5576 211.249 70.2349 224.282C81.4427 232.549 92.3357 238.812 103.534 243.428C103.59 243.452 103.638 243.469 103.679 243.484C103.728 243.467 103.783 243.447 103.851 243.418C112.645 239.769 121.359 235.004 130.489 228.844C148.019 217.02 161.142 204.389 170.621 190.218C179.526 176.903 183.727 163.216 183.46 148.386C183.245 136.359 183.298 124.184 183.349 112.411C183.356 110.625 183.364 108.82 183.371 107.003H183.306L183.308 80.5392C183.31 66.9148 183.31 53.2928 183.303 39.6684C159.706 34.0886 138.368 29.067 116.884 24.2485C111.076 22.9435 104.858 21.8754 98.7737 22.8783C74.7238 26.8317 50.5286 32.9552 27.13 38.8733L23.5829 39.7699ZM103.677 264C101.008 264 98.3403 263.451 95.6915 262.362C82.9317 257.096 70.6126 250.03 58.0368 240.758C38.1635 226.106 23.8129 210.459 14.1596 192.922C6.72402 179.414 2.96872 164.869 3.0002 149.693C3.05104 124.733 3.05346 99.3519 3.05346 74.8048C3.05346 62.8768 3.05346 50.9463 3.05831 39.0183C3.05831 37.9937 3.12126 37.0923 3.17694 36.2973C3.19631 36.0436 3.21326 35.7898 3.22779 35.5361C3.64908 28.0642 8.33897 22.4868 15.7745 20.6116L22.089 19.0167C45.9017 12.9898 70.5278 6.76242 95.4349 2.66641C105.013 1.09082 113.928 2.58908 121.385 4.26132C143.28 9.17171 164.975 14.2827 189.034 19.9736C192.615 20.8194 197.518 21.9793 200.787 26.3508C203.82 30.4082 203.823 34.9585 203.825 38.9724C203.835 52.8288 203.833 66.6852 203.833 80.5416L203.83 86.5177H203.917V96.759C203.917 102.013 203.896 107.343 203.874 112.498C203.823 124.16 203.772 136.221 203.985 148.018C204.319 166.899 198.84 184.924 187.69 201.591C176.708 218.011 161.757 232.479 141.98 245.816C131.7 252.751 121.804 258.154 111.732 262.335C109.059 263.444 106.367 264 103.677 264Z"
          fill="white"
        />
        <path
          d="M90.151 130.922C85.9841 130.006 82.8898 131.139 80.3572 133.594C75.1806 138.609 70.0234 143.652 65.0672 148.879C60.9972 153.173 61.0359 159.574 64.8033 163.511C68.7741 167.658 75.3598 167.803 79.8875 163.528C85.2722 158.441 90.4827 153.168 95.6641 147.873C98.2717 145.21 99.6058 142.047 98.2814 137.509C95.698 139.841 93.6061 141.897 91.3325 143.734C89.352 145.336 87.1729 145.191 85.3352 143.439C83.4612 141.653 83.4878 139.507 84.9454 137.49C86.4417 135.411 88.1196 133.466 90.151 130.922ZM118.419 118.01C122.181 119.223 125.237 118.15 127.803 115.666C133.273 110.369 138.781 105.098 144.008 99.567C148.41 94.9127 148.132 88.6587 143.774 84.5531C139.694 80.7035 133.653 80.7422 129.164 84.9639C123.765 90.0434 118.511 95.2849 113.315 100.577C110.71 103.233 109.455 106.391 110.884 110.371C113.552 109.197 114.922 107.22 116.465 105.444C118.528 103.064 121.046 103.054 123.314 104.702C125.929 106.604 125.6 109.132 123.767 111.538C122.109 113.713 119.431 115.067 118.419 118.01ZM88.5046 120.709C91.2236 120.579 93.8336 120.837 96.1725 122.162C98.0998 123.256 99.4823 122.729 100.921 121.255C102.395 119.747 103.489 118.476 102.538 116.055C98.7584 106.418 101.635 98.5327 109.056 91.7278C114.082 87.1194 118.571 81.9239 123.648 77.3759C130.946 70.8441 141.643 70.7836 149.127 76.8177C156.42 82.6972 158.463 93.0109 154.236 101.326C152.405 104.929 148.788 106.556 146.287 109.39C142.485 113.704 138.691 118.041 134.59 122.06C128.784 127.746 121.77 129.452 114.053 126.695C111.518 125.791 109.794 125.431 107.635 127.563C105.569 129.602 106.204 131.204 106.981 133.427C109.756 141.361 108.092 148.451 102.201 154.461C97.003 159.763 91.8047 165.067 86.4393 170.197C78.2822 178 66.5926 178.114 58.7261 170.647C50.9564 163.274 50.4286 151.155 57.9246 142.98C63.4765 136.922 69.4157 131.204 75.3792 125.542C78.9965 122.111 83.4515 120.342 88.5046 120.709Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd_7695_26150"
          x="0"
          y="0"
          width="207"
          height="268"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0509804 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_7695_26150"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.101961 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_7695_26150"
            result="effect2_dropShadow_7695_26150"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_7695_26150"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_i_7695_26150"
          x="13.2617"
          y="12.2419"
          width="180.475"
          height="245.524"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.945098 0 0 0 0 0.952941 0 0 0 0 0.960784 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_7695_26150"
          />
        </filter>
      </defs>
    </svg>
  );
};
