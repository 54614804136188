import { AxiosResponse } from "axios";
import { marlyApi } from "./marly";
import { recursiveToCamelCase } from "src/utility/recursive-to-camel-case";

export interface Attribute {
  name: string;
  controlType: string;
  controlParams:
    | null
    | SelectControlParams
    | TextControlParams
    | CheckboxListControlParams
    | NumberControlParams
    | RadioListControlParams;
  Label: string;
  systemName: string;
  uid: string;
}

export interface SelectControlParams {
  options: string[];
}

export interface TextControlParams {
  hintText: string;
}

export interface CheckboxListControlParams {
  options: string[];
}

export interface NumberControlParams {
  step: number;
}

export interface RadioListControlParams {
  options: string[];
}

const getAttributes = async (): Promise<AxiosResponse<Attribute[]>> => {
  return await marlyApi
    .get("/v4/attributes", {
      withCredentials: true,
    })
    .then((response) => {
      response.data = recursiveToCamelCase(response.data);
      return response;
    });
};

export default {
  getAttributes,
};
