import { t } from "i18next";
import { useEffect, useState } from "react";
import { TextComponent } from "../text-component";
import { Loading } from "../loading";
import { Flex, Grid, Paper, Progress, Text } from "@mantine/core";
import { theme } from "src/ui/theme";
// import { FlashIcon } from "src/ui/icons/flash-icon";
// import { StopwatchIcon } from "src/ui/icons/stopwatch-icon";
import { Interval } from "src/api/user";
import { useAnalyticsV4, useCurrentSubscription } from "src/store/user";
import { useMediaQuery } from "@mantine/hooks";

type PaperColumnProps = {
  id?: string;
  title: string;
  text: string;
  // icon: ReactElement;
  mt: string;
};

const PaperColumn = ({ id, title, text, mt }: PaperColumnProps) => {
  return (
    <Paper
      id={id}
      p={{ base: "xs", md: "lg", lg: "md", xl: "md" }}
      radius="md"
      mt={mt}
      withBorder
    >
      <Flex align="center" direction={"column"}>
        <Flex direction={"row"} align={"center"} justify={"center"}>
          <Text fw={700}>{title}</Text>
        </Flex>
        <TextComponent text={text} margin="0" />
      </Flex>
    </Paper>
  );
};

export const CreditUsageCard = () => {
  const interval: Interval = "day";
  const analytics = useAnalyticsV4(interval, "startOfMonth");
  const subscription = useCurrentSubscription();
  const [current, setCurrent] = useState<number>(0);
  const [limit, setLimit] = useState<number>(0);
  const isMobile = useMediaQuery("(max-width: 900px)");

  useEffect(() => {
    if (analytics.state === "fulfilled") {
      setCurrent(
        analytics.data.rows.reduce((acc, row) => acc + row.responses, 0),
      );
    }
    if (subscription.state === "fulfilled") {
      setLimit(subscription.data.monthlyQuota);
    }
  }, [analytics, subscription]);

  return (
    <Paper
      id="usage-card"
      shadow="sm"
      p={{ base: "sm", lg: "xl" }}
      radius="md"
      withBorder
    >
      <Loading
        isLoading={
          analytics.state === "loading" || subscription.state === "loading"
        }
      >
        <Flex direction={"column"}>
          <Grid gutter={"xl"}>
            <Grid.Col span={6}>
              <TextComponent
                title={t("usage.current_usage_label.title")}
                margin="0"
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextComponent
                text={t("usage.current_usage_numers", {
                  current,
                  limit,
                })}
                alignRight
                margin="0"
              />
            </Grid.Col>
            <Grid.Col span={12} pt={0}>
              <Progress
                color={theme.colors?.green?.[5]}
                value={(current / limit) * 100}
              />
            </Grid.Col>
          </Grid>
          <Grid
            gutter="xl"
            mt="md"
            justify={isMobile ? "flex-start" : "center"}
            align={isMobile ? "flex-start" : "center"}
          >
            <Grid.Col span={isMobile ? 12 : 4}>
              <PaperColumn
                id="included-responses"
                title={t("usage.plan_label.title")}
                text={t("format.number", { number: limit })}
                // icon={<FlashIcon />}
                mt={isMobile ? "md" : "0"}
              />
            </Grid.Col>
            <Grid.Col span={isMobile ? 12 : 4}>
              <PaperColumn
                id="remaining-responses"
                title={t("usage.remaining_label.title")}
                text={t("format.number", {
                  number: limit - current >= 0 ? limit - current : "0",
                })}
                // icon={<StopwatchIcon />}
                mt={isMobile ? "md" : "0"}
              />
            </Grid.Col>
            <Grid.Col span={isMobile ? 12 : 4}>
              <PaperColumn
                id="overages"
                title={t("usage.overages_label.title")}
                text={t("format.number", {
                  number: limit - current < 0 ? Math.abs(limit - current) : 0,
                })}
                // icon={<StopwatchIcon />}
                mt={isMobile ? "md" : "0"}
              />
            </Grid.Col>
          </Grid>
        </Flex>
      </Loading>
    </Paper>
  );
};
