import { useEffect } from "react";

export const OutsetaLeadCapture = () => {
  const isProd = window.location.origin === "https://dashboard.dwellir.com";

  let dedicatedNodeForm = "DQ2DNLWV";
  if (isProd) {
    dedicatedNodeForm = "aWxXLVWV";
  }

  useEffect(() => {
    // Define the Outseta options
    window.o_options.leadCapture = {
      mode: "embed",
      selector: "div[data-form-uid]",
    };

    return () => {
      window.o_options.load = window.o_options.load.replace(",leadCapture", "");
      delete window.o_options.leadCapture;
    };
  }, []);

  return <div data-form-uid={dedicatedNodeForm}></div>;
};
