import { metricsdateFormat } from "./metrics-date-format";
import { AnalyticsV4, Interval } from "src/api/user";

export type Chart = {
  data: Record<
    string, // Date
    {
      requests: number;
      responses: number;
      byHost: {
        host: string;
        hostName: string;
        requests: number;
        responses: number;
      }[];
    }
  >;
  interval: Interval;
  noDays: number;
  methods: Record<
    string, // Method
    {
      requests: number;
      responses: number;
      byHost: {
        host: string;
        hostName: string;
        requests: number;
        responses: number;
      }[];
    }
  >;
  summary: {
    today: number;
    lastSevenDays: number;
    lastThirtyDays: number;
    averageThirtyDays: number;
  };
  isEmpty: boolean;
};

export const initChart = (
  interval: Interval,
  no: number,
  initial: () => number,
): Chart => {
  const chart: Chart = {
    data: {},
    interval,
    noDays: no,
    methods: {},
    summary: {
      today: 0,
      lastSevenDays: 0,
      lastThirtyDays: 0,
      averageThirtyDays: 0,
    },
    isEmpty: true,
  };
  for (let i = 0; i < no; i++) {
    const day = new Date();
    day.setDate(day.getDate() - i);
    const key = metricsdateFormat(interval, day);
    chart.data[key] = {
      responses: initial(),
      requests: initial(),
      byHost: [],
    };
  }
  return chart;
};

const populateSummary = (chart: Chart) => {
  for (let i = 0; i < chart.noDays; i++) {
    const day = new Date();
    day.setDate(day.getDate() - i);
    const key = metricsdateFormat(chart.interval, day);
    const responses = chart.data[key].responses;

    if (i === 0) {
      chart.summary.today += responses;
    }
    if (i < 7) {
      chart.summary.lastSevenDays += responses;
    }
    if (i < 30) {
      chart.summary.lastThirtyDays += responses;
    }
  }
};

export const populateV4Analytics = (
  chart: Chart,
  data: AnalyticsV4,
  lookupHostName: Map<string, string>,
  filterApiKeys: string[] | undefined = undefined,
  filterHosts: string[] | undefined = undefined,
): Chart => {
  const hosts: Record<
    string, // apiKey
    Record<
      string, // row.domain (host)
      {
        host: string;
        hostName: string;
        requests: number;
        responses: number;
      }
    >
  > = {};

  const methods: Record<
    string, // method
    Record<
      string, // row.domain (host)
      {
        host: string;
        hostName: string;
        requests: number;
        responses: number;
      }
    >
  > = {};

  for (const row of data.rows.values()) {
    if (filterApiKeys && !filterApiKeys.includes(row.apiKey)) {
      continue;
    }
    if (filterHosts && !filterHosts.includes(row.domain)) {
      continue;
    }

    const key: string = metricsdateFormat(
      chart.interval,
      new Date(row.datetime), // TODO rename field
    );

    if (key in chart.data) {
      // byMethod
      const method = row.rpcMethod;
      if (!chart.methods[method]) {
        chart.methods[method] = {
          responses: 0,
          requests: 0,
          byHost: [],
        };
      }
      chart.methods[method].requests += row.requests;
      chart.methods[method].responses += row.responses;

      if (!methods[method]) {
        methods[method] = {};
      }

      if (!methods[method][row.domain]) {
        methods[method][row.domain] = {
          host: row.domain,
          hostName: lookupHostName.get(row.domain) || row.domain,
          requests: row.requests,
          responses: row.responses,
        };
      } else {
        methods[method][row.domain].requests += row.requests;
        methods[method][row.domain].responses += row.responses;
      }

      chart.methods[method].byHost = Object.values(methods[method]);

      chart.data[key].requests += row.requests;
      chart.data[key].responses += row.responses;

      if (!hosts[key]) {
        hosts[key] = {};
      }

      if (!hosts[key][row.domain]) {
        hosts[key][row.domain] = {
          host: row.domain,
          hostName: lookupHostName.get(row.domain) || row.domain,
          requests: row.requests,
          responses: row.responses,
        };
      } else {
        hosts[key][row.domain].requests += row.requests;
        hosts[key][row.domain].responses += row.responses;
      }
      chart.data[key].byHost = Object.values(hosts[key]);

      // We have added some data to the chart, it is no longer empty
      chart.isEmpty = false;
    }
  }
  populateSummary(chart);
  return chart;
};

export const dayKeySort = (
  a: { name: string },
  b: { name: string },
): number => {
  const [aDay, aMonth] = a.name.split("/");
  const [bDay, bMonth] = b.name.split("/");
  if (aMonth === bMonth) {
    return Number(aDay) - Number(bDay);
  }
  return Number(aMonth) - Number(bMonth);
};
