import classes from "./square-card.module.css";
import { Flex, Paper, Image, Text } from "@mantine/core";

type SquareCardProps = {
  text: string;
  imgSrc?: string;
  selected?: string;
};

export const SquareCard = ({ text, imgSrc, selected }: SquareCardProps) => {
  return (
    <Paper
      className={
        selected ? `${classes.card} ${classes.selected}` : `${classes.card}`
      }
      miw={{ base: "10vw", md: "10vw" }}
      mih={{ base: "20vh", md: "20vh" }}
      maw={{ base: "40vw", md: "15vw" }}
      mah={{ base: "40vh", md: "20vh" }}
      shadow="lg"
      radius="md"
      mb="md"
      withBorder
    >
      <Flex className={classes["content-wrapper"]} direction="column" m="md">
        {imgSrc && (
          <Image
            className={classes.image}
            w={{ base: "75px", md: "100px", xl: "125px" }}
            h={{ base: "75px", md: "100px", xl: "125px" }}
            mb="lg"
            src={imgSrc}
            alt="logo"
          />
        )}
        <Text fw={500}>{text}</Text>
      </Flex>
    </Paper>
  );
};
