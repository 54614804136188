import { NetworkSelector } from "../components/network-selector";
import { RootPanel } from "../components/panels/root-panel/root-panel";
import { Footer } from "../components/footer";
import { PageRoute } from "src/utility/utils";
import { useParams } from "react-router-dom";

export const SelectNetwork = () => {
  const { chainId } = useParams();

  const footer = (
    <Footer
      backButtonLink={PageRoute.SELECT_CHAIN}
      continueButtonLink={PageRoute.DASHBOARD}
    />
  );

  return (
    <RootPanel footer={footer}>
      {chainId && <NetworkSelector chainId={parseInt(chainId, 10)} />}
    </RootPanel>
  );
};
