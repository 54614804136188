import { RootPanel } from "../../components";
import { GetStartedGrid } from "../../components/get-started-grid";
import { CustomTabs } from "../../components/panels/custom-tabs";
import { useParams } from "react-router-dom";
import {
  EndpointTabValue,
  getEndpointTabs,
  PageRoute,
} from "src/utility/utils";

export const Urls = () => {
  const { id: endpointId } = useParams();

  const tabs = getEndpointTabs(endpointId || "");
  return (
    <RootPanel withNavbar>
      <CustomTabs
        path={PageRoute.ENDPOINT_TABS}
        tabs={tabs}
        defaultTabValue={EndpointTabValue.GET_STARTED}
      />
      <GetStartedGrid />
    </RootPanel>
  );
};
