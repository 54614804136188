import { FC, useEffect, useState } from "react";
import { usePostHog } from "posthog-js/react";
import { Survey } from "posthog-js";
import { Flex, Text, Button, TextInput, Alert } from "@mantine/core";

import { theme } from "src/ui/theme";
import { InfoCircleIcon } from "src/ui/icons/info-circle-icon";
import { SendIcon } from "src/ui/icons/send-icon";
import { SuccessIcon } from "src/ui/icons/success-icon";

interface RequestChainProps {
  initialRequest?: string;
}

export const RequestChain: FC<RequestChainProps> = ({
  initialRequest = "",
}) => {
  const [request, setRequest] = useState<string>(initialRequest);
  const [survey, setSurvey] = useState<Survey | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const posthog = usePostHog();

  useEffect(() => {
    setRequest(initialRequest);
  }, [initialRequest]);

  useEffect(() => {
    posthog.getActiveMatchingSurveys((surveys) => {
      const requestSurvey = surveys.filter(
        (survey) => survey.name === "Request chain",
      )[0];
      if (requestSurvey) {
        setSurvey(requestSurvey);
        posthog.capture("survey shown", {
          $survey_id: requestSurvey.id, // eslint-disable-line camelcase
          $survey_name: requestSurvey.name, // eslint-disable-line camelcase
        });
      }
    });
  }, [posthog]);

  const handleSubmit = () => {
    if (request === "" || survey === null) {
      return;
    }
    // Send request to posthog

    posthog.capture("survey sent", {
      $survey_id: survey.id, // eslint-disable-line camelcase
      $survey_name: survey.name, // eslint-disable-line camelcase
      $survey_response: request, // eslint-disable-line camelcase
    });
    setSuccess(true);
    setRequest("");
  };

  return (
    <Flex direction="column" gap="md">
      <Alert
        title="Not finding what you are looking for?"
        color="green"
        icon={<InfoCircleIcon fillColor="green" />}
      >
        <Text>We would love to extend our offerings!</Text>
        <Text>
          Make a request below and we'll get in touch once it is ready for you
          to use.
        </Text>
      </Alert>
      <Flex direction="row" gap="md" align="center">
        <TextInput
          id="filter-chains"
          w="100%"
          value={request}
          description="Which chains would you like us to support? If multiple, separate by comma."
          onChange={(event) => {
            setSuccess(false);
            setRequest(event.currentTarget.value);
          }}
          placeholder="Search chains"
          mb="md"
        />
        {/*<SubmitButton text="Request" } />*/}
        <Button
          color={theme.colors?.green?.[5]}
          miw="150px"
          type="submit"
          disabled={success}
          rightSection={
            success ? (
              <SuccessIcon fillColor="white" />
            ) : (
              <SendIcon fillColor="white" />
            )
          }
          onClick={handleSubmit}
        >
          {success ? "Success" : "Request"}
        </Button>
      </Flex>
    </Flex>
  );
};
