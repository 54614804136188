import { FC, useState } from "react";
import { ToSModal, getTosVersion } from "../tos-modal";
import { Loading } from "src/ui/components/loading";
import { useUserApiKeys } from "src/store/api_keys";
import { useChains } from "src/store/chain";
import { User } from "src/api/marly";
import { useAppDispatch } from "src/core/hooks";
import { updateUser } from "src/store/user";

export interface WelcomeModalProps {
  user: User;
}

export const WelcomeModal: FC<WelcomeModalProps> = ({ user }) => {
  const keys = useUserApiKeys();
  const chains = useChains();
  const dispatch = useAppDispatch();

  // If the user has declined the ToS, don't show it as we must reach out
  // manually to figure out why they declined it.
  const currentTos = getTosVersion(user);
  const [showTosUpdate, setShowTosUpdate] = useState(
    !(
      user.tosAgreement === `declined_${currentTos}` ||
      user.tosAgreement === currentTos
    ),
  );

  const handleDisagree = () => {
    dispatch(
      updateUser({
        name: user.name,
        tosAgreement: `declined_${currentTos}`,
      }),
    );
    setShowTosUpdate(false);
  };

  const handleAgreement = () => {
    dispatch(
      updateUser({
        name: user.name,
        tosAgreement: currentTos,
      }),
    );
    setShowTosUpdate(false);
  };

  return (
    <Loading isLoading={keys === null || chains.state === "loading"}>
      {showTosUpdate && (
        <ToSModal
          show={showTosUpdate}
          user={user}
          onCloseDisagree={handleDisagree}
          onCloseAccept={handleAgreement}
        />
      )}
    </Loading>
  );
};
