import { FC, ReactNode } from "react";
import { GrayDwellirLogo } from "./gray-dwellir-logo";
import { Box, Center } from "@mantine/core";

interface DwellirLogoBackgroundProps {
  children: ReactNode;
}

export const DwellirLogoBackground: FC<DwellirLogoBackgroundProps> = ({
  children,
}) => {
  return (
    <Box h="100%" w="100%" style={{ position: "relative" }}>
      <Box h="100%" w="100%" style={{ position: "absolute" }}>
        <Center h="100%" w="100%">
          <GrayDwellirLogo />
        </Center>
      </Box>
      <Box h="100%" w="100%" style={{ position: "absolute" }}>
        {children}
      </Box>
    </Box>
  );
};
