// eslint-disable-next-line import/no-unused-modules
export type BuildMode = "production" | "development";

export class BuildInfo {
  public static get mode(): BuildMode {
    return process.env.NODE_ENV === "production" ? "production" : "development";
  }

  public static get version(): string {
    return process.env.VERSION as string;
  }
}
