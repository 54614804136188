import { FC, ReactNode } from "react";
import { Link } from "react-router-dom";
import { theme } from "src/ui/theme";

interface TextLinkProps {
  target: string;
  newTab?: boolean;
  children: ReactNode;
}

export const TextLink: FC<TextLinkProps> = ({
  target,
  newTab = true,
  children,
}) => {
  return (
    <Link
      to={target}
      target={newTab ? "_blank" : undefined}
      style={{
        textDecorationColor: theme.colors.green[6],
        color: theme.colors.green[6],
      }}
    >
      {children}
    </Link>
  );
};
