import { ReactNode } from "react";
import {
  ButtonProps as MantineButtonProps,
  Button as MantineButton,
  Space,
  Tooltip,
} from "@mantine/core";
import { theme } from "src/ui/theme";

type ButtonProps = {
  id?: string;
  text: string;
  onClick?: () => void;
  icon?: ReactNode;
  tooltip?: string;
} & MantineButtonProps;

export const Button = ({
  id,
  text,
  styles,
  onClick,
  icon,
  tooltip,
  ...buttonProps
}: ButtonProps) => {
  const button = (
    <MantineButton
      id={id}
      styles={styles}
      color={theme.colors?.green?.[5]}
      variant={buttonProps.variant ? buttonProps.variant : "filled"}
      onClick={onClick}
      {...buttonProps}
    >
      {icon && <Space pr={"xs"}>{icon}</Space>}
      {text}
    </MantineButton>
  );

  if (tooltip) {
    return <Tooltip label={tooltip}>{button}</Tooltip>;
  } else {
    return button;
  }
};
