import { useEffect } from "react";
import subscriptionApi, {
  Previews,
  SubscriptionPlan,
} from "../api/subscription_plan";
import {
  LOADING_STATE,
  Error,
  RequestState,
  rejectedState,
  fulfilledState,
  EMPTY_STATE,
} from "./core/request_state";
import { useAppDispatch, useAppSelector } from "src/core/hooks";
import { RootState } from "src/store";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

export interface SubscriptionState {
  plans: RequestState<SubscriptionPlan[]>;
  invoicePreviews: RequestState<Previews>;
}

const initialState: SubscriptionState = {
  plans: EMPTY_STATE,
  invoicePreviews: EMPTY_STATE,
};

export const getSubscriptionPlans = createAsyncThunk(
  "subscription/plans",
  async (_, { rejectWithValue }) => {
    try {
      const response = await subscriptionApi.getSubscriptionPlans();
      // We get no body, only a cookie
      const data = response.data;
      data.sort((a, b) => {
        if (a.rate_limit < b.rate_limit) {
          return -1;
        } else {
          return 1;
        }
      });
      return data;
    } catch (error: unknown) {
      // Unknown error
      console.error("Unkwon login error", error);
      return rejectWithValue({ type: "unknown", serverMessage: "" });
    }
  },
);

export const getSubscriptionPreviews = createAsyncThunk(
  "subscription/previews",
  async (_, { rejectWithValue }) => {
    try {
      const response = await subscriptionApi.getSubscriptionPreviews();
      return response.data;
    } catch (error: unknown) {
      // Unknown error
      console.error("Unkwon login error", error);
      return rejectWithValue({ type: "unknown", serverMessage: "" });
    }
  },
);

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getSubscriptionPlans.pending, (state, _action) => {
      state.plans = LOADING_STATE;
    });
    builder.addCase(
      getSubscriptionPlans.rejected,
      (state, action: PayloadAction<unknown>) => {
        const error = action.payload as Error;

        state.plans = rejectedState(error);
      },
    );
    builder.addCase(
      getSubscriptionPlans.fulfilled,
      (state, action: PayloadAction<SubscriptionPlan[]>) => {
        state.plans = fulfilledState(action.payload);
      },
    );

    builder.addCase(getSubscriptionPreviews.pending, (state, _action) => {
      state.invoicePreviews = LOADING_STATE;
    });
    builder.addCase(
      getSubscriptionPreviews.rejected,
      (state, action: PayloadAction<unknown>) => {
        const error = action.payload as Error;

        state.invoicePreviews = rejectedState(error);
      },
    );
    builder.addCase(
      getSubscriptionPreviews.fulfilled,
      (state, action: PayloadAction<Previews>) => {
        state.invoicePreviews = fulfilledState(action.payload);
      },
    );
  },
});

// Action creators are generated for each case reducer function
// export const { login } = authSlice.actions;

/* Selectors */
export const selectPlans = (state: RootState): SubscriptionPlan[] | null =>
  state.subscription.plans;
const selectState = (state: RootState): SubscriptionState => state.subscription;

export const usePlans = (): RequestState<SubscriptionPlan[]> => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(selectState);

  useEffect(() => {
    if (state.plans === EMPTY_STATE) {
      dispatch(getSubscriptionPlans());
    }
  }, [state, dispatch]);

  // TODO We should probably handle errors in a generic way?
  // Throw here and have a error boundary that handles it?

  return state.plans;
};

export const useSubscriptionPreviews = (): RequestState<Previews> => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(selectState);

  useEffect(() => {
    if (state.invoicePreviews === EMPTY_STATE) {
      dispatch(getSubscriptionPreviews());
    }
  }, [state, dispatch]);

  // TODO We should probably handle errors in a generic way?
  // Throw here and have a error boundary that handles it?

  return state.invoicePreviews;
};

export default subscriptionSlice.reducer;
