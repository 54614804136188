import { FC, useState } from "react";
import { ActionIcon } from "@mantine/core";
import Joyride, {
  Placement,
  STATUS,
  ACTIONS,
  CallBackProps,
  Step,
} from "react-joyride";
import { HelpIcon } from "src/ui/icons/help-icon";
import { PageRoute, UsageBillingTabValue } from "src/utility/utils";
import { generatePath } from "react-router-dom";

const steps: Record<string, Step[]> = {
  navbar: [
    {
      target: "#dashboard-button",
      content: "Go here for an overview of your Dwellir account.",
      placement: "right" as Placement,
      disableBeacon: true,
    },
    {
      target: "#endpoints-button",
      content:
        "Here you are able to view all endpoints we offer and go to their individual pages for more information and statistics. This is also where you have the best access to the URL's",
      placement: "right" as Placement,
      disableBeacon: true,
    },
    {
      target: "#usage-button",
      content: "View statistics and usage of your endpoints here.",
      placement: "right" as Placement,
      disableBeacon: true,
    },
    {
      target: "#support-button",
      content: "If you ever need more help we are just a click away!",
      placement: "right" as Placement,
      disableBeacon: true,
    },
    {
      target: "#api-keys-button",
      content:
        "Manage your API keys here. You can create new keys, delete old ones, and view the keys you have created.",
      placement: "right" as Placement,
      disableBeacon: true,
    },
    {
      target: "#billing-button",
      content:
        "Manage your subscriptions here, upgrade your plan and get information about your next invoice (subject to change depending on your usage).",
      placement: "right" as Placement,
      disableBeacon: true,
    },
    {
      target: "#profile-button",
      content: "View and change your settings here.",
      placement: "right" as Placement,
      disableBeacon: true,
    },
  ],
  [PageRoute.DASHBOARD]: [
    {
      target: "#credit-usage",
      content:
        "Here you can see your current usage and how much data you have left for this month.",
      placement: "bottom" as Placement,
      disableBeacon: true,
    },
    {
      target: "#billing-card",
      content: "Here we present a summary of your upcoming invoice.",
      placement: "bottom" as Placement,
      disableBeacon: true,
    },
    {
      target: "#endpoints-card",
      content:
        "Your favorite endpoints are shown here. You can mark endpoints as favorites on the endpoints page.",
      placement: "bottom" as Placement,
      disableBeacon: true,
    },
  ],
  [PageRoute.ENDPOINTS]: [
    {
      target: "#select-api-key",
      content: "Select which API key should be shown in the URL's.",
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: "#filter-chains",
      content: "Search for chains by name or network here.",
      placement: "bottom" as Placement,
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: "#chain-name",
      content: "Click the chain name to go to the dedicated chain page.",
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: "#click-to-copy",
      content: "Click the button to copy the endpoints URL",
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: "#mark-as-favorite",
      content:
        "Mark this chain as a favorite. This will make it show up on the dashboard for easy access on your next visit.",
      disableBeacon: true,
      disableScrolling: true,
    },
  ],
  [generatePath(PageRoute.USAGE_BILLING_TABS, {
    tabValue: UsageBillingTabValue.USAGE,
  })]: [
    {
      target: "#usage-card",
      content: "This is a summary of your current usage this month.",
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: "#included-responses",
      content:
        "This is how many responses are freely available in your current plan.",
      placement: "bottom" as Placement,
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: "#remaining-responses",
      content:
        "This is how many responses you have left this month. When you run out of responses your api keys will either be blocked (free users) or we will start charge an extra fee.",
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: "#overages",
      content: "This is how many responses you have used over your limit.",
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: "#mark-as-favorite",
      content:
        "Mark this chain as a favorite. This will make it show up on the dashboard for easy access on your next visit.",
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: "#usage-chart",
      content: "Here you can get a detailed breakdown of the usage per chain.",
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: "#select-chain",
      content: "Select which chain for which to see the usage for.",
      disableBeacon: true,
      disableScrolling: true,
    },
  ],
  [PageRoute.API_KEYS]: [
    {
      target: "#add-new",
      content:
        "Click here to add a new API Key. A free user is limited to 1 key, paying users are limited to 10 keys. If you have the need for more reach out to support for a custom plan.",
      placement: "bottom" as Placement,
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: "#edit-name",
      content: "Click the pencil to edit the name",
      placement: "bottom" as Placement,
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: "#click-to-copy",
      content: "Click the button to copy the API Key",
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: "#enable-disable-key",
      content: "Start/Stop all traffic for this key",
      placement: "bottom" as Placement,
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: "#delete-key",
      content:
        "Delete the API key, we do not allow you to delete your last key.",
      placement: "bottom" as Placement,
      disableBeacon: true,
      disableScrolling: true,
    },
  ],
  [generatePath(PageRoute.USAGE_BILLING_TABS, {
    tabValue: UsageBillingTabValue.BILLING,
  })]: [
    {
      target: "#upcoming-invoice",
      content:
        "This is how much you are currently expected to pay on the next invoice. Do note that this number might change depending on your traffic usage.",
      placement: "bottom" as Placement,
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: "#upgrade-plan",
      content: "Click here if you want to change your current plan",
      placement: "bottom" as Placement,
      disableBeacon: true,
      disableScrolling: true,
    },
  ],
};

interface GuidedTourProps {
  tour: string;
}

export const GuidedTour: FC<GuidedTourProps> = ({ tour }) => {
  const [run, setRun] = useState(false);

  const callback = (data: CallBackProps) => {
    const { status, action } = data;
    if (
      status === STATUS.FINISHED ||
      status === STATUS.SKIPPED ||
      action === ACTIONS.CLOSE
    ) {
      // You need to set our running state to false, so we can restart if we click start again.
      setRun(false);
    }
  };

  return (
    <>
      <ActionIcon
        variant="filled"
        color="green"
        radius="xl"
        onClick={() => setRun(true)}
      >
        <HelpIcon fillColor="white" />
      </ActionIcon>
      {run && (
        <Joyride
          steps={steps[tour]}
          run={run}
          showSkipButton={true}
          continuous={true}
          callback={callback}
          styles={{
            options: {
              arrowColor: "var(--mantine-color-green-filled)",
              backgroundColor: "var(--mantine-color-background-filled)",
              primaryColor: "var(--mantine-color-green-filled)",
              textColor: "var(--manitne-color-text-filled)",
            },
          }}
        />
      )}
    </>
  );
};
