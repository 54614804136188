import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import {
  cookieSelector,
  isAuthenticated,
  logout,
} from "src/store/authentication";
import { useAppDispatch } from "src/core/hooks";
import { PageRoute } from "src/utility/utils";

export const Logout = () => {
  const dispatch = useAppDispatch();
  const [cookies] = useCookies(cookieSelector);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated(cookies) === false) {
      navigate(PageRoute.LOGIN + location.search, {
        replace: true,
      });
    }
  }, [location, dispatch, cookies, navigate]);

  return null;
};
