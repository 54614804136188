import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import { SubmitButton } from "../../components/buttons";
import classes from "./login.module.css";
import {
  NavLink,
  Paper,
  TextInput,
  Title,
  Text,
  Flex,
  Alert,
  Center,
  Box,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { forgotPassword, selectForgotPassword } from "src/store/user";
import { theme } from "src/ui/theme";
import { CopyrightIcon } from "src/ui/icons/copyright-icon";
import { DwellirLogo } from "src/ui/icons/dwellir-logo";
import { TextLink } from "src/ui/components/text-link";

interface LoginForm {
  email: string;
}

export const ForgotPasswordPage = () => {
  const dispatch = useAppDispatch();
  const forgotPasswordRequest = useAppSelector(selectForgotPassword);
  const [error, setError] = useState<"NotVerified" | "Unknown" | null>(null);
  const [success, setSuccess] = useState(false);

  const query = new URLSearchParams(location.search);
  const email = query.get("email");

  useEffect(() => {
    if (forgotPasswordRequest.state == "rejected") {
      if (forgotPasswordRequest.error.type === "NotVerified") {
        setError("NotVerified");
      } else {
        setError("Unknown");
      }
    }
    if (forgotPasswordRequest.state == "fulfilled") {
      setSuccess(true);
    }
  }, [forgotPasswordRequest]);

  const form = useForm({
    initialValues: {
      email: email || "",
    },
  });

  const handleLogin = (values: LoginForm) => {
    if (values.email.length === 0) {
      form.setFieldError("email", "Please enter an Email");
    } else {
      dispatch(forgotPassword(values.email));
    }
  };

  return (
    <div className={classes.wrapper}>
      <Paper h="100vw" className={classes.form} radius={0} px={30}>
        <Flex h="100%" direction="column" justify="space-between">
          <Box>
            <Flex direction="column" gap="lg">
              <Flex w="100%" justify="center">
                <DwellirLogo />
              </Flex>
              <Title
                order={2}
                className={classes.title}
                ta="center"
                mt="md"
                mb={50}
              >
                Forgot your password?
              </Title>

              <Text>
                Enter your email and we will send you a link to reset your
                password.
              </Text>

              <form onSubmit={form.onSubmit(handleLogin)}>
                <TextInput
                  placeholder="hello@gmail.com"
                  size="md"
                  {...form.getInputProps("email")}
                />
                <SubmitButton
                  fullWidth
                  mt="md"
                  size="md"
                  text="Send reset link"
                />
              </form>

              {success && (
                <Alert mt="md" radius="md" color={theme.colors?.green?.[9]}>
                  Please check your email for further instructions.
                </Alert>
              )}
              {error == "NotVerified" && (
                <Alert mt="md" radius="md" color="red">
                  Your email was never verified. Please reach out to
                  <TextLink target="mailto:support@dwellir.com">
                    support@dwellir.com
                  </TextLink>{" "}
                  for assistance.
                </Alert>
              )}
            </Flex>
          </Box>
          <Center>
            <NavLink
              w="auto"
              href="https://dwellir.com"
              label="Dwellir.com"
              variant="subtle"
              active
              color="green"
              leftSection={<CopyrightIcon />}
            />
          </Center>
        </Flex>
      </Paper>
    </div>
  );
};
