import { RootPanel } from "../components/panels/root-panel/root-panel";
import { EndpointsCard } from "../components/endpoints-card";

export const EndpointsPage = () => {
  // const { state } = useLocation();
  return (
    <RootPanel withNavbar>
      <EndpointsCard filterChains={false} favouriteChains={false} />
    </RootPanel>
  );
};
