import { FC } from "react";
import { DangerButton } from "../buttons";
import { ConfirmCancelPlanModal } from "../confirm-cancel-plan-modal/confirm-cancel-plan-modal";
import { Flex, Grid } from "@mantine/core";
import { BillingCard } from "src/ui/components/billing-card";
import { useDisclosure } from "@mantine/hooks";
import { useCurrentSubscription } from "src/store/user";

interface CancelPlanButtonProps {
  disabled: boolean;
}

const CancelPlanButton: FC<CancelPlanButtonProps> = ({ disabled }) => {
  const [showConfirmModal, confirmModalCtrl] = useDisclosure(false);

  const onCancelClick = () => {
    confirmModalCtrl.open();
  };

  return (
    <>
      <ConfirmCancelPlanModal
        show={showConfirmModal}
        onClose={confirmModalCtrl.close}
      />
      <DangerButton
        text="Cancel Plan"
        onClick={onCancelClick}
        disabled={disabled}
      />
    </>
  );
};

export const PlanViewer = () => {
  const subscription = useCurrentSubscription();
  const isFree =
    subscription.state === "fulfilled" && subscription.data.id === 1;

  return (
    <Flex direction={"column"} mt="md" mx={{ lg: "10vw", xl: "15vw" }}>
      <Grid gutter={"md"}>
        <Grid.Col span={12}>
          <BillingCard showUpgrade header="PlanHeader" />
        </Grid.Col>
        <Grid.Col span={12}>
          <Flex direction="column" mr="md" align="flex-end">
            <CancelPlanButton disabled={isFree} />
          </Flex>
        </Grid.Col>
      </Grid>
    </Flex>
  );
};
