import { useEffect, useState } from "react";
import { Loading } from "../components/loading";
import { DwellirShieldTextIcon } from "../icons/dwellir-shield-text";
import { Flex, Paper, Text } from "@mantine/core";
import { useNavigate, useSearchParams } from "react-router-dom";
import { loadCurrentUser, useUser } from "src/store/user";
import api from "src/api/marly";
import { PageRoute } from "src/utility/utils";
import { useAppDispatch } from "src/core/hooks";

export const VerifyPage = () => {
  const [searchParams, _setSearchParams] = useSearchParams();
  const user = useUser();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isVerifying, setIsVerifying] = useState(false);

  useEffect(() => {
    if (user.state === "fulfilled") {
      if (user.data.isVerified) {
        navigate(PageRoute.INDEX);
      } else {
        const code = searchParams.get("code");
        if (code) {
          // Make sure we only call the API once
          if (!isVerifying) {
            api.verifyEmail(code).then((res) => {
              if (res.status === 200) {
                dispatch(loadCurrentUser());
              }
            });
          }
          setIsVerifying(true);
        }
      }
    }
  }, [isVerifying, user, navigate, searchParams, dispatch]);

  return (
    <Flex
      h="100vh"
      w="100vw"
      direction="column"
      align="center"
      justify="center"
      gap="md"
    >
      <DwellirShieldTextIcon />
      <Flex direction="row" justify="center" align="center">
        <Paper p="md" shadow="lg" radius="md" mx="sm" withBorder>
          <Loading isLoading={user.state === "loading"}>
            <Flex direction="column" justify="space-between" gap="md">
              {isVerifying ? (
                <>
                  <Text size="xl" fw={700} c="green">
                    Please hold while we are confirming your email address
                  </Text>
                  <Text>
                    You should be redirected to the API Platform shortly.
                  </Text>
                </>
              ) : (
                <>
                  <Text size="xl" fw={700} c="green">
                    Please confirm your email address
                  </Text>
                  <Text>
                    Click the link in the email we just sent you to use the API
                    Platform.
                  </Text>
                  <Text size="sm" c="dimmed" fw={400}>
                    You can close this tab. The link in the email will open a
                    new tab in the Platform.
                  </Text>
                </>
              )}
            </Flex>
          </Loading>
        </Paper>
      </Flex>
    </Flex>
  );
};
