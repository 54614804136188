import {
  Box,
  ActionIcon,
  useMantineColorScheme,
  useComputedColorScheme,
} from "@mantine/core";
import { MoonIcon } from "src/ui/icons/moon-icon";
import { SunIcon } from "src/ui/icons/sun-icon";

export const ColorSchemeToggle = () => {
  const { setColorScheme } = useMantineColorScheme();
  const computedColorScheme = useComputedColorScheme("light", {
    getInitialValueInEffect: true,
  });

  return (
    <ActionIcon
      onClick={() =>
        setColorScheme(computedColorScheme === "light" ? "dark" : "light")
      }
      color="green"
      aria-label="Toggle color scheme"
    >
      <Box lightHidden>
        <SunIcon fillColor="white" />
      </Box>

      <Box darkHidden>
        <MoonIcon fillColor="black" />
      </Box>
    </ActionIcon>
  );
};
