import {
  Flex,
  Space,
  Text as MantineText,
  TextProps as MantineTextProps,
} from "@mantine/core";

type TextComponentProps = {
  title?: string;
  text?: string;
  alignRight?: boolean;
  margin?: string;
  fontColor?: string;
} & MantineTextProps;

export const TextComponent = ({
  title,
  text,
  alignRight,
  margin,
  fontColor,
  ...mantineTextProps
}: TextComponentProps) => {
  return (
    <Space mb={mantineTextProps.mb} mt={mantineTextProps.mt}>
      {title && (
        <Flex>
          <MantineText
            fw={700}
            ta={alignRight ? "right" : "left"}
            w="100%"
            m={margin ? margin : 0}
            c={fontColor ?? "primaryText"}
          >
            {title}
          </MantineText>
        </Flex>
      )}
      {text && (
        <Flex>
          <MantineText
            ta={alignRight ? "right" : "left"}
            w="100%"
            m={margin ? margin : 0}
            c={fontColor ?? "primaryText"}
          >
            {text}
          </MantineText>
        </Flex>
      )}
    </Space>
  );
};
