import { Tabs } from "@mantine/core";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { theme } from "src/ui/theme";
import { PageRoute } from "src/utility/utils";

type Tab = {
  value: string;
  title: string;
};

type TabsPageRoute = PageRoute.ENDPOINT_TABS | PageRoute.USAGE_BILLING_TABS;

type CustomTabsProps = {
  path: TabsPageRoute;
  tabs: Tab[];
  defaultTabValue: string;
};

export const CustomTabs = ({
  path,
  tabs,
  defaultTabValue,
}: CustomTabsProps) => {
  const navigate = useNavigate();
  const { id: endpointId, tabValue } = useParams(); // Capture endpointId from URL

  // useParams returns string | undefined but we need string | null
  const pathId = endpointId ?? null;

  const tabRows = tabs.map((tab) => (
    <Tabs.Tab key={tab.value} value={tab.value}>
      {tab.title}
    </Tabs.Tab>
  ));

  return (
    <Tabs
      color={theme.colors?.green?.[5]}
      radius="md"
      value={tabValue ?? defaultTabValue}
      onChange={(value) => {
        navigate(
          generatePath(path, {
            id: pathId,
            tabValue: value,
          }),
        );
      }} // Directly navigate to the tab's value, handling null case
    >
      <Tabs.List>{tabRows}</Tabs.List>
    </Tabs>
  );
};
