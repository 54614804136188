export const GoBackArrow = () => {
  return (
    <svg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.35355 2.64645C6.54881 2.84171 6.54881 3.15829 6.35355 3.35355L4.20711 5.5H10C10.2761 5.5 10.5 5.72386 10.5 6C10.5 6.27614 10.2761 6.5 10 6.5H4.20711L6.35355 8.64645C6.54881 8.84171 6.54881 9.15829 6.35355 9.35355C6.15829 9.54882 5.84171 9.54882 5.64645 9.35355L2.64645 6.35355C2.45118 6.15829 2.45118 5.84171 2.64645 5.64645L5.64645 2.64645C5.84171 2.45118 6.15829 2.45118 6.35355 2.64645Z"
        fill="#52B196"
      />
    </svg>
  );
};
