import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import { cookieSelector, isAuthenticated } from "src/store/authentication";
import {
  Paper,
  TextInput,
  PasswordInput,
  Title,
  Text,
  Alert,
  Flex,
  Box,
  Button,
} from "@mantine/core";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "@mantine/form";
import { useCookies } from "react-cookie";
import { PageRoute } from "src/utility/utils";
import { login, selectLoginState } from "src/store/user";
import { DwellirShield } from "src/ui/icons/dwellir-shield";
import { TextLink } from "src/ui/components/text-link";

interface LoginForm {
  email: string;
  password: string;
}

export const Login = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loadingState = useAppSelector(selectLoginState);
  const [internalError, setInternalError] = useState<null | string>(null);
  const [cookies] = useCookies(cookieSelector);
  const [doRedirect, setDoRedirect] = useState(false);

  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },
  });

  useEffect(() => {
    if (loadingState.state == "rejected") {
      if (loadingState.error.type === "LOGIN_BAD_CREDENTIALS") {
        // We can't set an error for the whole form it seems like, so we do it ourself
        setInternalError(t("login.bad_credentials"));
      }
    } else {
      setInternalError(null);
    }
  }, [loadingState, navigate, t]);

  const handleLogin = (values: LoginForm) => {
    if (values.email.length === 0) {
      form.setFieldError("email", "Please enter an Email");
    } else if (values.password.length === 0) {
      form.setFieldError("password", "Please enter a password");
    } else {
      dispatch(login({ username: values.email, password: values.password }));
    }
  };

  useEffect(() => {
    if (isAuthenticated(cookies)) {
      setDoRedirect(true);
    }
  }, [cookies]);

  useEffect(() => {
    if (doRedirect) {
      const query = new URLSearchParams(location.search);
      const redirect = query.get("from");
      if (redirect) {
        setInternalError(null);
        navigate(redirect);
      } else {
        navigate(PageRoute.INDEX);
      }
    }
  }, [doRedirect, location, navigate]);

  return (
    <Box w="100dvw" h="100dvh" bg="background">
      <Flex h="100dvh" justify="center" align="center" p="sm" gap="xl">
        <Flex direction="column" w={400} gap="lg">
          <Flex direction="row" w="100%" gap="rem(2)" justify="center">
            <DwellirShield />
            <Text size="xl" fw={600}>
              Blockchain API Platform
            </Text>
          </Flex>
          <Paper radius="md" p="xl" bg="second-background">
            <Flex direction="column" gap="xl">
              <Flex w="100%" justify="center">
                <Title order={3}>Log In</Title>
              </Flex>

              <form onSubmit={form.onSubmit(handleLogin)}>
                <Flex direction="column" gap="md">
                  <TextInput
                    label={t("register.email")}
                    placeholder={t("register.email_placeholder")}
                    {...form.getInputProps("email")}
                  />
                  <PasswordInput
                    styles={{
                      label: {
                        // Needed to be able to get the reset link on the right side.
                        width: "100%",
                      },
                    }}
                    label={
                      <Flex direction="row" justify="space-between">
                        <Text>Password</Text>
                        <Flex align="flex-end">
                          <TextLink
                            target={
                              PageRoute.FORGOT_PASSWORD +
                              `?email=${form.values.email}`
                            }
                            newTab={false}
                          >
                            Forgot your password?
                          </TextLink>
                        </Flex>
                      </Flex>
                    }
                    placeholder="Your password"
                    mt="md"
                    size="md"
                    {...form.getInputProps("password")}
                  />
                  <Button
                    w="100%"
                    color="green"
                    type="submit"
                    data-disabled={
                      form.values.email === "" ||
                      form.values.password.length < 12
                    }
                  >
                    Log in
                  </Button>
                </Flex>
              </form>

              <Flex w="100%" justify="center">
                <Text size="xs">
                  Don&apos;t have an account?{" "}
                  <TextLink target={PageRoute.REGISTER} newTab={false}>
                    Create an account
                  </TextLink>
                  .
                </Text>
              </Flex>
            </Flex>
            {internalError && (
              <Alert mt="md" radius="md" color="red">
                {internalError}
              </Alert>
            )}
          </Paper>
        </Flex>
      </Flex>
    </Box>
  );
};
