import { useEffect } from "react";
import checkoutApi, {
  CancelChecks,
  Checkout,
  CreateCheckout,
  Invoice,
  PaymentMethodCard,
} from "../api/checkout";
import {
  EMPTY_STATE,
  Error,
  LOADING_STATE,
  RequestState,
  fulfilledState,
  rejectedState,
} from "./core/request_state";
import { useAppDispatch, useAppSelector } from "src/core/hooks";
import { RootState } from "src/store";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

export interface SessionStatus {
  status: string;
  customerEmail: string;
}

export interface CheckoutState {
  sessionStatus: RequestState<SessionStatus>;
  upcomingInvoice: RequestState<Invoice>;
  subscriptionCancelChecks: RequestState<CancelChecks>; // track request only
  getPaymentMethods: RequestState<PaymentMethodCard[]>; // track request only
  createCheckout: RequestState<Checkout>;
}

const initialState: CheckoutState = {
  sessionStatus: EMPTY_STATE,
  upcomingInvoice: EMPTY_STATE,
  subscriptionCancelChecks: EMPTY_STATE,
  getPaymentMethods: EMPTY_STATE,
  createCheckout: EMPTY_STATE,
};

export const getPaymentMethods = createAsyncThunk(
  "checkout/getPaymentMethods",
  async (_input, { rejectWithValue }) => {
    try {
      const response = await checkoutApi.getPaymentMethods();
      return response.data;
    } catch (error: unknown) {
      // Unknown error
      console.error("Unkwon error", error);
      return rejectWithValue({ type: "unknown", serverMessage: "" });
    }
  },
);

export const getCheckoutStatus = createAsyncThunk(
  "checkout/getCheckoutStatus",
  async (input: { sessionId: string }, { rejectWithValue }) => {
    try {
      const response = await checkoutApi.getCheckoutStatus(input.sessionId);
      return response.data;
    } catch (error: unknown) {
      // Unknown error
      console.error("Unkwon error", error);
      return rejectWithValue({ type: "unknown", serverMessage: "" });
    }
  },
);

export const subscriptionCancelChecks = createAsyncThunk(
  "checkout/subscriptionCancelChecks",
  async (newSubId: number, { rejectWithValue }) => {
    try {
      const response = await checkoutApi.subscriptionCancelChecks(newSubId);
      return response.data;
    } catch (error: unknown) {
      // Unknown error
      console.error("Unkwon error", error);
      return rejectWithValue({ type: "unknown", serverMessage: "" });
    }
  },
);

export const upcomingInvoice = createAsyncThunk(
  "checkout/upcomingInvoice",
  async (_input, { rejectWithValue }) => {
    try {
      const response = await checkoutApi.upcomingInvoice();
      return response.data;
    } catch (error: unknown) {
      // Unknown error
      console.error("Unkwon error", error);
      return rejectWithValue({ type: "unknown", serverMessage: "" });
    }
  },
);

export const createCheckout = createAsyncThunk(
  "checkout/createCheckout",
  async (input: CreateCheckout, { rejectWithValue }) => {
    try {
      const response = await checkoutApi.createCheckout(input);
      return response.data;
    } catch (error: unknown) {
      // Unknown error
      console.error("Unkwon error", error);
      return rejectWithValue({ type: "unknown", serverMessage: "" });
    }
  },
);

export const checkoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getPaymentMethods.pending, (state, _action) => {
      state.getPaymentMethods = LOADING_STATE;
    });
    builder.addCase(
      getPaymentMethods.rejected,
      (state, action: PayloadAction<unknown>) => {
        const error = action.payload as Error;

        state.getPaymentMethods = rejectedState(error);
      },
    );
    builder.addCase(
      getPaymentMethods.fulfilled,
      (state, action: PayloadAction<PaymentMethodCard[]>) => {
        state.getPaymentMethods = fulfilledState(action.payload);
      },
    );

    builder.addCase(getCheckoutStatus.pending, (state, _action) => {
      state.sessionStatus = LOADING_STATE;
    });
    builder.addCase(
      getCheckoutStatus.rejected,
      (state, action: PayloadAction<unknown>) => {
        const error = action.payload as Error;

        state.sessionStatus = rejectedState(error);
      },
    );
    builder.addCase(
      getCheckoutStatus.fulfilled,
      (
        state,
        action: PayloadAction<{
          status: string;
          customer_email: string;
        }>,
      ) => {
        state.sessionStatus = fulfilledState({
          status: action.payload.status,
          customerEmail: action.payload.customer_email,
        });
      },
    );

    builder.addCase(subscriptionCancelChecks.pending, (state, _action) => {
      state.subscriptionCancelChecks = LOADING_STATE;
    });
    builder.addCase(
      subscriptionCancelChecks.rejected,
      (state, action: PayloadAction<unknown>) => {
        const error = action.payload as Error;

        state.subscriptionCancelChecks = rejectedState(error);
      },
    );
    builder.addCase(
      subscriptionCancelChecks.fulfilled,
      (state, action: PayloadAction<CancelChecks>) => {
        state.subscriptionCancelChecks = fulfilledState(action.payload);
      },
    );

    builder.addCase(upcomingInvoice.pending, (state, _action) => {
      state.upcomingInvoice = LOADING_STATE;
    });
    builder.addCase(
      upcomingInvoice.rejected,
      (state, action: PayloadAction<unknown>) => {
        const error = action.payload as Error;

        state.upcomingInvoice = rejectedState(error);
      },
    );
    builder.addCase(
      upcomingInvoice.fulfilled,
      (state, action: PayloadAction<Invoice>) => {
        state.upcomingInvoice = fulfilledState(action.payload);
      },
    );

    builder.addCase(createCheckout.pending, (state, _action) => {
      state.createCheckout = LOADING_STATE;
    });
    builder.addCase(
      createCheckout.rejected,
      (state, action: PayloadAction<unknown>) => {
        const error = action.payload as Error;

        state.createCheckout = rejectedState(error);
      },
    );
    builder.addCase(
      createCheckout.fulfilled,
      (state, action: PayloadAction<Checkout>) => {
        state.createCheckout = fulfilledState(action.payload);
      },
    );
  },
});

// Action creators are generated for each case reducer function
// export const { login } = authSlice.actions;

/* Selectors */
const selectCheckoutState = (state: RootState): CheckoutState => state.checkout;
export const selectCreateCheckout = (
  state: RootState,
): RequestState<Checkout> => state.checkout.createCheckout;

export const usePaymentMethods = (): RequestState<PaymentMethodCard[]> => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(selectCheckoutState);

  useEffect(() => {
    if (state.getPaymentMethods === EMPTY_STATE) {
      dispatch(getPaymentMethods());
    }
  }, [state, dispatch]);

  // TODO We should probably handle errors in a generic way?
  // Throw here and have a error boundary that handles it?

  return state.getPaymentMethods;
};

export const useSubscriptionCancelChecks = (
  newSubId: number,
): RequestState<CancelChecks> => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(selectCheckoutState);

  useEffect(() => {
    if (state.subscriptionCancelChecks === EMPTY_STATE) {
      dispatch(subscriptionCancelChecks(newSubId));
    }
  }, [state, dispatch, newSubId]);

  // TODO We should probably handle errors in a generic way?
  // Throw here and have a error boundary that handles it?

  return state.subscriptionCancelChecks;
};

export const useCheckoutStatus = (
  sessionId: string | undefined,
): RequestState<SessionStatus> => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(selectCheckoutState);

  useEffect(() => {
    if (sessionId && state.sessionStatus === EMPTY_STATE) {
      dispatch(getCheckoutStatus({ sessionId }));
    }
  }, [sessionId, state, dispatch]);

  // TODO We should probably handle errors in a generic way?
  // Throw here and have a error boundary that handles it?

  return state.sessionStatus;
};

export const useUpcomingInvoice = (): RequestState<Invoice> => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(selectCheckoutState);

  useEffect(() => {
    if (state.upcomingInvoice === EMPTY_STATE) {
      dispatch(upcomingInvoice());
    }
  }, [state, dispatch]);

  // TODO We should probably handle errors in a generic way?
  // Throw here and have a error boundary that handles it?

  return state.upcomingInvoice;
};

export const { reset } = checkoutSlice.actions;
export default checkoutSlice.reducer;
