import { FC } from "react";
import { BaseIconProps, Icon } from "./base/icon";

export const ChevronRightIcon: FC<BaseIconProps> = (props) => {
  return (
    <Icon viewBox="0 0 320 512" {...props}>
      <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
    </Icon>
  );
};
