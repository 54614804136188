import { useEffect } from "react";
import {
  EMPTY_STATE,
  Error,
  LOADING_STATE,
  RequestState,
  fulfilledState,
  rejectedState,
} from "./core/request_state";
import { RootState } from "src/store";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "src/api/marly";
import { useAppDispatch, useAppSelector } from "src/core/hooks";

export interface MaintenanceMessage {
  inMaintenance: boolean;
  start: string | undefined;
  expectedEnd: string | undefined;
}

export interface MaintenanceState {
  inMaintenance: boolean;
  start: Date | undefined;
  expectedEnd: Date | undefined;
  health: RequestState<null>;
}

const initialState: MaintenanceState = {
  inMaintenance: false,
  start: undefined,
  expectedEnd: undefined,
  health: EMPTY_STATE,
};

export const getHealthCheck = createAsyncThunk(
  "maintenance/getHealthCheck",
  async (_input, { rejectWithValue }) => {
    try {
      const response = await api.getHealthCheck();
      return response.data;
    } catch (error: unknown) {
      // Unknown error
      console.error("Unkwon error", error);
      return rejectWithValue({ type: "unknown", serverMessage: "" });
    }
  },
);

export const maintenanceSlice = createSlice({
  name: "maintenance",
  initialState,
  reducers: {
    setMaintenanceMode: (state, action: PayloadAction<MaintenanceMessage>) => {
      state.inMaintenance = action.payload.inMaintenance;
      state.start = action.payload.start
        ? new Date(action.payload.start)
        : undefined;
      state.expectedEnd = action.payload.expectedEnd
        ? new Date(action.payload.expectedEnd)
        : undefined;
      return state;
    },
    triggerHealthCheck: (state) => {
      state.health = EMPTY_STATE;
      return state;
    },
  },
  extraReducers: (builder) => {
    // *** List ***
    builder.addCase(
      getHealthCheck.pending,
      (state: MaintenanceState, _action: PayloadAction<unknown>) => {
        state.health = LOADING_STATE;
      },
    );
    builder.addCase(
      getHealthCheck.rejected,
      (state: MaintenanceState, action: PayloadAction<unknown>) => {
        const error = action.payload as Error;

        state.health = rejectedState(error);
      },
    );
    builder.addCase(
      getHealthCheck.fulfilled,
      (state: MaintenanceState, _action: PayloadAction<null>) => {
        state.health = fulfilledState(null);
      },
    );
  },
});

// Action creators are generated for each case reducer function
export const { setMaintenanceMode, triggerHealthCheck } =
  maintenanceSlice.actions;

/* Selectors */
const selectState = (state: RootState): MaintenanceState => state.maintenance;
export const selectMaintenanceState = (state: RootState): MaintenanceState =>
  state.maintenance;

export const useHealhtCheck = (): RequestState<null> => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(selectState);

  useEffect(() => {
    if (state.health === EMPTY_STATE) {
      dispatch(getHealthCheck());
    }
  }, [state, dispatch]);

  // TODO We should probably handle errors in a generic way?
  // Throw here and have a error boundary that handles it?

  return state.health;
};

export default maintenanceSlice.reducer;
