import * as i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./locales/en/global.json";
import { initReactI18next } from "react-i18next";

export const i18nConfig = {
  fallbackLng: "en",
  resources: {
    en: {
      global: en,
    },
  },
  ns: ["global"],
  defaultNS: "global",
  keySeparator: ".",
};

i18n.use(LanguageDetector).use(initReactI18next).init(i18nConfig);
