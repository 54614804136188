import { FC, useEffect, useState } from "react";
import { Button } from "../buttons";
import { PlanPicker } from "../plan-picker";
import { ConfirmCancelPlanModal } from "../confirm-cancel-plan-modal/confirm-cancel-plan-modal";
import { useSearchParams } from "react-router-dom";
import { Loading } from "src/ui/components/loading";
import { Text, Paper, Flex, Box, HoverCard, Divider } from "@mantine/core";
import { billingPath } from "src/utility/routes";
import { useUpcomingInvoice } from "src/store/checkout";
import { useTranslation } from "react-i18next";
import { useCurrentSubscription } from "src/store/user";
import { FlashIcon } from "src/ui/icons/flash-icon";
import { useDisclosure } from "@mantine/hooks";
import { Invoice, InvoiceItem } from "src/api/checkout";
import { ViewLink } from "src/ui/pages/dashboard";
import { WalletIcon } from "src/ui/icons/wallet-icon";

export interface CostBreakdownProps {
  invoice: Invoice;
}

export const CostBreakdown: FC<CostBreakdownProps> = ({ invoice }) => {
  const { t } = useTranslation();
  const [invoiceLines, setInvoiceLines] = useState<InvoiceItem[]>([]);

  useEffect(() => {
    setInvoiceLines(
      invoice.lines
        .filter((line) => line.amount !== 0)
        .map((line) => {
          if (line.description.includes("Tier")) {
            const description = `${t("format.number", {
              number: line.quantity,
            })} API Responses (at $${line.price_per_unit} / response)`;
            return { ...line, description };
          }
          return line;
        }),
    );
  }, [t, invoice]);

  return (
    <Flex direction="column" m="md" gap="xs">
      <Text>Cost Breakdown</Text>
      {invoiceLines.map((line, index) => (
        <Flex direction="row" justify="space-between" key={"line-" + index}>
          <HoverCard position="top" shadow="md">
            <HoverCard.Target>
              <Box maw={"70%"}>
                <Text truncate="end">{line.description}</Text>
              </Box>
            </HoverCard.Target>
            <HoverCard.Dropdown>
              <Text mr="md">{line.description}</Text>
              <Text>
                {t("billing_card.currency_amount", {
                  amount: line.amount / 100,
                  currency: line.currency,
                })}
              </Text>
            </HoverCard.Dropdown>
          </HoverCard>
          <Text>
            {t("billing_card.currency_amount", {
              amount: line.amount / 100,
              currency: line.currency,
            })}
          </Text>
        </Flex>
      ))}
      <Divider />
      <Flex direction="row" justify="space-between">
        <Text>Total</Text>
        <Text>
          {t("billing_card.currency_amount", {
            amount: invoice.total / 100,
            currency: invoice.currency,
          })}
        </Text>
      </Flex>
    </Flex>
  );
};

interface BillingCardProps {
  showLink?: boolean;
  showUpgrade?: boolean;
  header: "CardHeader" | "PlanHeader";
}

const CardHeader: FC = () => {
  const { t } = useTranslation();
  return (
    <Flex direction="row" align="center" mt="sm">
      <WalletIcon />
      <Flex direction="column">
        <Text ml="sm" fw={700}>
          {t("billing_card.title")}
        </Text>
      </Flex>
    </Flex>
  );
};

const PlanHeader: FC = () => {
  const subscription = useCurrentSubscription();

  return (
    <Flex direction="row">
      <Text style={{ lineHeight: "1" }} size="2em" fw={600}>
        Plan: {subscription.state === "fulfilled" && subscription.data.name}
      </Text>
    </Flex>
  );
};

export const BillingCard: FC<BillingCardProps> = ({
  showLink = false,
  showUpgrade = false,
  header,
}) => {
  const { t } = useTranslation();
  const invoice = useUpcomingInvoice();
  const [opened, { open, close }] = useDisclosure(false);
  const [showConfirmCancelPlanModal, confirmCtrls] = useDisclosure(false);
  const [modalState, setModalState] = useState<
    "success" | "canceled" | undefined
  >(undefined);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("success")) {
      setModalState("success");
      searchParams.delete("success");
      setSearchParams(searchParams);
      open();
    }

    if (searchParams.get("canceled")) {
      setModalState("canceled");
      searchParams.delete("canceled");
      setSearchParams(searchParams);
      open();
    }
  }, [open, searchParams, setSearchParams]);

  const onCancel = () => {
    close();
    confirmCtrls.open();
  };

  return (
    <Paper shadow="sm" p="md" m="md" radius="md" withBorder h="90%">
      <Loading isLoading={invoice.state === "loading"}>
        <PlanPicker
          opened={opened}
          startState={modalState}
          onClose={close}
          onCancel={onCancel}
        />
        <ConfirmCancelPlanModal
          show={showConfirmCancelPlanModal}
          onClose={confirmCtrls.close}
        />
        <Flex direction="row" justify="space-between" m="md">
          <Box id="upcoming-invoice">
            <Flex direction="column">
              {header === "CardHeader" && <CardHeader />}
              {header === "PlanHeader" && <PlanHeader />}
              <Flex direction="row">
                <Text
                  style={{ lineHeight: "1" }}
                  mt="12px"
                  size="1.5em"
                  fw={400}
                >
                  {invoice.state === "fulfilled" &&
                    t("billing_card.currency_amount", {
                      amount: invoice.data.total / 100,
                      currency: invoice.data.currency,
                    })}
                </Text>
              </Flex>
              <Text style={{ lineHeight: "1" }} mt="8px" fw={400} c="dimmed">
                {invoice.state === "fulfilled" &&
                  t("billing_card.due_on", {
                    date: new Date(invoice.data.dueDate).toDateString(),
                  })}
              </Text>
            </Flex>
          </Box>
          <Box>
            {showLink && <ViewLink href={billingPath} label="View billing" />}
            {showUpgrade && (
              <Flex direction="row" align="center">
                <Button
                  id="upgrade-plan"
                  onClick={open}
                  text={t("plan.plan_buttons.upgrade")}
                  icon={<FlashIcon fillColor="white" />}
                  style={{ height: "32px" }}
                />
              </Flex>
            )}
          </Box>
        </Flex>
        {invoice.state === "fulfilled" && (
          <CostBreakdown invoice={invoice.data} />
        )}
      </Loading>
    </Paper>
  );
};
