import { FC } from "react";
import { BaseIconProps, Icon } from "./base/icon";

export const WalletIcon: FC<BaseIconProps> = (props) => {
  return (
    <Icon viewBox="0 0 1264 1024" {...props}>
      <path d="M1216.347654 427.488395h-74.903703V346.769383a158.59358 158.59358 0 0 0-158.403951-158.403951h-75.851852v-29.708642a158.59358 158.59358 0 0 0-158.46716-158.65679H49.430123A47.849877 47.849877 0 0 0 1.580247 48.102716v176.039506A47.533827 47.533827 0 0 0 0 236.215309v629.127901a158.59358 158.59358 0 0 0 158.403951 158.65679h824.636049a158.59358 158.59358 0 0 0 158.403951-158.403951v-93.740247h74.903703a47.849877 47.849877 0 0 0 47.849877-47.849876V475.338272a47.849877 47.849877 0 0 0-47.849877-47.849877z m-467.753086-331.599012a62.830617 62.830617 0 0 1 62.704197 62.767407v29.708642h-714.271605V95.889383z m297.08642 769.453827a63.209877 63.209877 0 0 1-62.767408 62.767407H158.403951a63.209877 63.209877 0 0 1-62.767408-62.767407v-581.530864h887.403457a63.209877 63.209877 0 0 1 62.767407 62.767407v80.908642h-186.532345a47.849877 47.849877 0 0 0-47.849877 47.849877v248.667654a47.849877 47.849877 0 0 0 47.849877 47.849876h186.532345z m122.88-188.997531h-261.43605V523.188148h261.43605z M935.822222 555.045926h102.020741v95.636543h-102.020741z" />
    </Icon>
  );
};
