import { AxiosResponse } from "axios";
import { marlyApi } from "./marly";
import { recursiveToCamelCase } from "src/utility/recursive-to-camel-case";

export interface OrganizationInformation {
  name: string | undefined;

  billingCountry: string | undefined;
  billingAddress1: string | undefined;
  billingAddress2: string | undefined;
  billingPostalCode: string | undefined;
  billingCity: string | undefined;
  billingPhone: string | undefined;

  isBusiness: boolean | undefined;
  stripeTaxIdType: string | undefined;
  taxId: string | undefined;

  canChangeTaxId: boolean;
}

const createOrganizationInformation = async (
  input: OrganizationInformation,
): Promise<AxiosResponse<OrganizationInformation>> => {
  return await marlyApi
    .put(
      "/v4/organization/information",
      {
        name: input.name,
        billing_country: input.billingCountry, // eslint-disable-line camelcase
        billing_address_1: input.billingAddress1, // eslint-disable-line camelcase
        billing_address_2: input.billingAddress2, // eslint-disable-line camelcase
        billing_postal_code: input.billingPostalCode, // eslint-disable-line camelcase
        billing_city: input.billingCity, // eslint-disable-line camelcase
        billing_phone: input.billingPhone, // eslint-disable-line camelcase
        is_business: input.isBusiness, // eslint-disable-line camelcase
        stripe_tax_id_type: input.stripeTaxIdType, // eslint-disable-line camelcase
        tax_id: input.taxId, // eslint-disable-line camelcase
      },
      {
        withCredentials: true,
      },
    )
    .then((response) => {
      response.data = recursiveToCamelCase(response.data);
      return response;
    })
    .catch((error) => {
      // Unknown error
      console.error("Unknown error", error);
      throw error;
    });
};

const getOrganizationInformation = async (): Promise<
  AxiosResponse<OrganizationInformation>
> => {
  return await marlyApi
    .get("/v4/organization/information", {
      withCredentials: true,
    })
    .then((response) => {
      response.data = recursiveToCamelCase(response.data);
      return response;
    })
    .catch((error) => {
      // Unknown error
      console.error("Unknown error", error);
      throw error;
    });
};

const updateOrganizationInformation = async (
  update: OrganizationInformation,
): Promise<AxiosResponse<OrganizationInformation>> => {
  // Create and update use the same put endpoint
  return createOrganizationInformation(update);
};

export default {
  createOrganizationInformation,
  getOrganizationInformation,
  updateOrganizationInformation,
};
