export const urlify = (name: string) => {
  return name.toLowerCase().replace(/\s+/g, "-");
};

export const endpointUrl = (url: string, apiKey: string) => {
  if (url.includes("<key>")) {
    return url.replace("<key>", apiKey);
  }
  return `${url}/${apiKey}`;
};
