import { Application } from "src/core";
import { BuildInfo } from "src/utility";
import "src/i18n/setup";

const config = {
  domRoot: "app-root",
  configUrl: "appconfig.json",
};

export class Bootstrapper {
  async start(): Promise<void> {
    if (BuildInfo.mode !== "production") {
      // eslint-disable-next-line no-console
      console.log(
        `Running version ${BuildInfo.version} in ${BuildInfo.mode} mode`,
      );
    }

    // TODO: make sure the tactonVis script is loaded before the application starts
    // Currently we just load the script to the index.html file, which might be a race condition
    // If the application starts before the script is loaded, the application will fail

    new Application(config.domRoot).start();
  }
}
