import { FC, useEffect } from "react";
import { MaintenanceTiming } from "./maintenance-timing";
import { Flex, Modal, Paper, Text } from "@mantine/core";
import { useAppDispatch, useAppSelector } from "src/core/hooks";
import {
  selectMaintenanceState,
  setMaintenanceMode,
  triggerHealthCheck,
  useHealhtCheck,
} from "src/store/maintenance";
import { DwellirLogo } from "src/ui/icons/dwellir-logo";

export const MaintenanceModal: FC = () => {
  const maintenance = useAppSelector(selectMaintenanceState);
  const health = useHealhtCheck();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (maintenance.inMaintenance) {
      const timeoutId = setTimeout(() => {
        dispatch(triggerHealthCheck());
      }, 60 * 1000);

      return () => clearTimeout(timeoutId);
    }
    return;
  }, [maintenance, health, dispatch]);

  useEffect(() => {
    if (health.state === "fulfilled") {
      dispatch(
        setMaintenanceMode({
          inMaintenance: false,
          start: undefined,
          expectedEnd: undefined,
        }),
      );
      return;
    }
  }, [dispatch, health]);

  const takingLonger =
    maintenance.expectedEnd &&
    new Date(maintenance.expectedEnd).getTime() < new Date().getTime();

  return (
    <Modal
      size="auto"
      opened={maintenance.inMaintenance}
      onClose={() => {
        return;
      }}
      closeOnClickOutside={false}
      closeOnEscape={false}
      withCloseButton={false}
    >
      <Paper
        shadow="lg"
        radius="md"
        h={{ base: "80vh", md: "50vh" }}
        w={{ base: "60vw", md: "60vw" }}
        mx={{ base: "sm", md: "sm", lg: "md", xl: "md" }}
        withBorder
      >
        <Flex direction="column" h="100%" ml="md">
          <Flex h="100%" direction="row" align="center">
            <Flex w="100%" direction="column" gap="md" justify="center">
              <DwellirLogo />
              <Text fw={700} size="lg">
                We are currently down for maintenance.
              </Text>
              {takingLonger ? (
                <Text>
                  We are taking longer than expected to finish the maintenance,
                  we are working hard to get back online as soon as possible.
                </Text>
              ) : (
                maintenance.start &&
                maintenance.expectedEnd && (
                  <MaintenanceTiming
                    start={new Date(maintenance.start)}
                    expectedEnd={new Date(maintenance.expectedEnd)}
                  />
                )
              )}
              <Text>
                Do not close or reload this tab to avoid loosing any ongoing
                work in the background.
              </Text>
            </Flex>
          </Flex>
          <Flex align="flex-end" mb="md">
            <Text size="sm" c="dimmed" fw={400} truncate="end">
              Once the maintenance is over, this window will close
              automatically.
            </Text>
          </Flex>
        </Flex>
      </Paper>
    </Modal>
  );
};
