import { useEffect, useState } from "react";
import { TextComponent } from "../text-component";
import { CreditUsageCard } from "../credit-usage-card";
import { UsageChartCard } from "../usage-chart";
import { Loading } from "../loading";
import { SelectChain } from "../select-chain";
import { ALL_KEYS, SelectApiKey } from "../select-api-key";
import classes from "./usage-viewer.module.css";
import { Flex, Grid, Box } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useChains } from "src/store/chain";
import { Chain } from "src/api/chain";
import { useAnalyticsV4 } from "src/store/user";

import { ApiKey } from "src/api/api_keys";
import { useUserApiKeys } from "src/store/api_keys";

export type HasDataPartition<T> = {
  hasData: T[];
  missingData: T[];
};

export const UsageViewer = () => {
  const { t } = useTranslation();
  const apiKeys = useUserApiKeys();
  const chains = useChains();
  const analytics = useAnalyticsV4("day");
  const [selectedApiKey, setSelectedApiKey] = useState<ApiKey>(ALL_KEYS);
  const [selectedChain, setSelectedChain] = useState<Chain | undefined>(
    undefined,
  );
  const [filteredChains, setFilteredChains] = useState<Chain[]>([]);
  const [chainsDataPartition, setChainsDataPartition] = useState<
    HasDataPartition<Chain>
  >({ hasData: [], missingData: [] });

  useEffect(() => {
    if (chains.state === "fulfilled" && analytics.state === "fulfilled") {
      const hostsWithData = analytics.data.rows.map((row) => row.domain);
      const chainsWithData = chains.data.filter((chain) => {
        const url = new URL(chain.https || "http://example.com");
        return hostsWithData.includes(url.host);
      });
      setFilteredChains(chainsWithData);
    }
  }, [chains, analytics]);

  useEffect(() => {
    if (analytics.state === "fulfilled") {
      const hostsWithDataForKey = analytics.data.rows.map((row) => {
        if (selectedApiKey.id === ALL_KEYS.id) {
          return row.domain;
        } else if (row.apiKey === selectedApiKey.apiKey) {
          return row.domain;
        }
        return null;
      });
      const partition = filteredChains.reduce(
        (acc: HasDataPartition<Chain>, chain: Chain) => {
          const url = new URL(chain.https || "http://example.com");
          if (hostsWithDataForKey.includes(url.host)) {
            acc.hasData.push(chain);
          } else {
            acc.missingData.push(chain);
          }
          return acc;
        },
        { hasData: [], missingData: [] },
      );
      setChainsDataPartition(partition);
    }
  }, [selectedApiKey, analytics, filteredChains]);

  return (
    <Flex direction={"column"} mt="md">
      <Box ml="md" mr="md">
        <Grid gutter={"xl"}>
          <Grid.Col>
            <CreditUsageCard />
          </Grid.Col>
        </Grid>
        <Grid gutter={"xl"}>
          <Grid.Col>
            <div id="usage-chart" className={classes.text1}>
              <TextComponent title={t("usage.chart_title")} mb="sm" mt="lg" />
              <Loading
                isLoading={
                  chains.state === "loading" || analytics.state === "loading"
                }
              >
                {chains.state === "fulfilled" &&
                  analytics.state === "fulfilled" && (
                    <Flex direction="column" gap="md">
                      <Flex direction="row" gap="md">
                        <div id="select-api-key">
                          {apiKeys && (
                            <SelectApiKey
                              apiKeys={apiKeys}
                              selectedApiKey={selectedApiKey}
                              onSelect={setSelectedApiKey}
                              allowAllOption
                            />
                          )}
                        </div>
                        <div id="select-chain">
                          <SelectChain
                            chains={chainsDataPartition}
                            onSelect={setSelectedChain}
                            allowAllOption
                          />
                        </div>
                      </Flex>
                      <UsageChartCard
                        chain={selectedChain}
                        apiKeys={
                          selectedApiKey && selectedApiKey !== ALL_KEYS
                            ? selectedApiKey
                            : null
                        }
                      />
                    </Flex>
                  )}
              </Loading>
            </div>
          </Grid.Col>
        </Grid>
      </Box>
    </Flex>
  );
};
