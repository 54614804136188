import { FC, ReactElement, useCallback, useEffect, useState } from "react";
import { Loading } from "../loading";
import { UrlClickToCopy } from "../url-click-to-copy";
import { Select, Flex, Table, Paper } from "@mantine/core";
import { useChains } from "src/store/chain";
import { Chain } from "src/api/chain";
import { useUserApiKeys } from "src/store/api_keys";
import { ApiKey } from "src/api/api_keys";
import { endpointUrl, urlify } from "src/utility/endpoint";
import { theme } from "src/ui/theme";

interface EndpointCardProps {
  endpointName: string;
}

export const EndpointCard: FC<EndpointCardProps> = ({ endpointName }) => {
  const chains = useChains();
  const [rows, setRows] = useState<ReactElement[]>([]);
  const apiKeys = useUserApiKeys();
  const [selectedApiKey, setSelectedApiKey] = useState<ApiKey | null>(null);
  const [chain, setChain] = useState<Chain | null>(null);

  useEffect(() => {
    if (chains.state === "fulfilled") {
      setChain(
        chains.data.find((c) => urlify(c.name) === endpointName) || null,
      );
    }
  }, [chains, endpointName, setChain]);

  useEffect(() => {
    if (apiKeys && apiKeys.length >= 1) {
      setSelectedApiKey(apiKeys[0]);
    }
  }, [apiKeys, setSelectedApiKey]);

  const renderRows = useCallback(
    (chain: Chain): ReactElement[] => {
      return chain.networks.map((network) => {
        const key = selectedApiKey?.apiKey ?? "<KEY>";
        const https = network?.https
          ? endpointUrl(network.https, key)
          : undefined;
        const wss = network?.wss ? endpointUrl(network.wss, key) : undefined;
        return (
          <Table.Tr key={`${chain.name}-${network}`}>
            <Table.Td>{network.name}</Table.Td>
            <Table.Td>
              <Flex direction="column" gap="sm">
                {https && (
                  <UrlClickToCopy
                    variant="filled"
                    color={theme.colors.green[8]}
                    url={https}
                    text="HTTPS"
                    width={"4em"}
                  />
                )}
                {wss && (
                  <UrlClickToCopy
                    variant="filled"
                    color={theme.colors.green[8]}
                    url={wss}
                    text="Websocket"
                    width={"5em"}
                  />
                )}
              </Flex>
            </Table.Td>
          </Table.Tr>
        );
      });
    },
    [selectedApiKey],
  );

  useEffect(() => {
    if (chain) {
      setRows(() => renderRows(chain));
    }
  }, [renderRows, chain]);

  return (
    <>
      <Loading isLoading={chains.state === "loading"}>
        <Flex direction="column" mb="md">
          <Flex direction="row" gap="lg" mb="md">
            {apiKeys && apiKeys.length > 1 && (
              <Select
                label="Select API Key"
                placeholder="Select an api key"
                withCheckIcon={false}
                searchable
                disabled={!chains}
                onChange={(value) =>
                  setSelectedApiKey(
                    apiKeys.find((key) => key.apiKey === value) || null,
                  )
                }
                data={apiKeys.map((key) => ({
                  label: key.name || key.apiKey,
                  value: key.apiKey,
                }))}
                value={selectedApiKey?.apiKey}
              />
            )}
          </Flex>
          <Paper
            shadow="none"
            radius="md"
            withBorder
            style={{ overflow: "hidden" }}
          >
            <Table stickyHeader verticalSpacing="sm">
              <Table.Thead>
                <Table.Tr bg="second-background">
                  <Table.Th>Network</Table.Th>
                  <Table.Th>Endpoints</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>{rows}</Table.Tbody>
            </Table>
          </Paper>
        </Flex>
      </Loading>
    </>
  );
};
