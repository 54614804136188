import { useEffect } from "react";
import {
  EMPTY_STATE,
  LOADING_STATE,
  RequestState,
  fulfilledState,
  rejectedState,
} from "./core/request_state";
import chainApi, { Chain, ChainFavoriteData } from "src/api/chain";
import { useAppDispatch, useAppSelector } from "src/core/hooks";
import { RootState } from "src/store";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { chainsSort } from "src/utility/chains-sort";

export interface Error {
  type: string;
  serverMessage: string;
}

export interface ChainState {
  chains: RequestState<Chain[]>;
  addChainFavorite: RequestState<null>;
  removeChainFavorite: RequestState<null>;
}

const initialState: ChainState = {
  chains: EMPTY_STATE,
  addChainFavorite: EMPTY_STATE,
  removeChainFavorite: EMPTY_STATE,
};

export const loadChains = createAsyncThunk(
  "chains/loadChains",
  async (_, { rejectWithValue }) => {
    try {
      const response = await chainApi.getChains();
      return response.data;
    } catch (error: unknown) {
      // Unknown error
      console.error("Unkwon error", error);
      return rejectWithValue({ type: "unknown", serverMessage: "" });
    }
  },
);

export const addChainFavorite = createAsyncThunk(
  "chains/addChainFavorite",
  async (
    input: ChainFavoriteData & { chainId: number },
    { rejectWithValue },
  ) => {
    try {
      const response = await chainApi.addChainFavorite(input);
      return response.data;
    } catch (error: unknown) {
      // Unknown error
      console.error("Unkwon error", error);
      return rejectWithValue({ type: "unknown", serverMessage: "" });
    }
  },
);

export const removeChainFavorite = createAsyncThunk(
  "chains/removeChainFavorite",
  async (
    input: ChainFavoriteData & { chainId: number },
    { rejectWithValue },
  ) => {
    try {
      const response = await chainApi.removeChainFavorite(input);
      return response.data;
    } catch (error: unknown) {
      // Unknown error
      console.error("Unkwon error", error);
      return rejectWithValue({ type: "unknown", serverMessage: "" });
    }
  },
);

export const chainSlice = createSlice({
  name: "chain",
  initialState,
  reducers: {
    reset: () => initialState,
    sortChains: (state, action: PayloadAction<string>) => {
      if (state.chains.state === "fulfilled") {
        const apiKey = action.payload;
        state.chains.data.sort((a: Chain, b: Chain) =>
          chainsSort(a, b, apiKey),
        );
      }
      return state;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(loadChains.pending, (state, _action) => {
      state.chains = LOADING_STATE;
    });
    builder.addCase(
      loadChains.rejected,
      (state, action: PayloadAction<unknown>) => {
        const error = action.payload as Error;

        state.chains = rejectedState(error);
      },
    );
    builder.addCase(
      loadChains.fulfilled,
      (state, action: PayloadAction<Chain[]>) => {
        state.chains = fulfilledState(action.payload);
      },
    );

    builder.addCase(addChainFavorite.pending, (state, _action) => {
      state.addChainFavorite = LOADING_STATE;
    });
    builder.addCase(
      addChainFavorite.rejected,
      (state, action: PayloadAction<unknown>) => {
        const error = action.payload as Error;

        state.addChainFavorite = rejectedState(error);
      },
    );
    builder.addCase(addChainFavorite.fulfilled, (state, action) => {
      if (state.chains.state === "fulfilled") {
        const old = state.chains.data;
        const chainIdx = old.findIndex((c) => c.id === action.meta.arg.chainId);
        const networkIdx = old[chainIdx].networks.findIndex(
          (n) => n.id === action.meta.arg.networkId,
        );
        old[chainIdx].networks[networkIdx].favoritedBy.push(
          action.meta.arg.apiKey,
        );
        state.chains = fulfilledState([...old]);
      }
      state.addChainFavorite = fulfilledState(null);
    });

    builder.addCase(removeChainFavorite.pending, (state, _action) => {
      state.removeChainFavorite = LOADING_STATE;
    });
    builder.addCase(
      removeChainFavorite.rejected,
      (state, action: PayloadAction<unknown>) => {
        const error = action.payload as Error;

        state.removeChainFavorite = rejectedState(error);
      },
    );
    builder.addCase(removeChainFavorite.fulfilled, (state, action) => {
      if (state.chains.state === "fulfilled") {
        const old = state.chains.data;
        const chainIdx = old.findIndex((c) => c.id === action.meta.arg.chainId);
        const networkIdx = old[chainIdx].networks.findIndex(
          (n) => n.id === action.meta.arg.networkId,
        );
        const favIdx = old[chainIdx].networks[networkIdx].favoritedBy.findIndex(
          (k) => k === action.meta.arg.apiKey,
        );
        delete old[chainIdx].networks[networkIdx].favoritedBy[favIdx];
        state.chains = fulfilledState([...old]);
      }
      state.removeChainFavorite = fulfilledState(null);
    });
  },
});

// Action creators are generated for each case reducer function
// export const { login } = authSlice.actions;

/* Selectors */
const selectState = (state: RootState): ChainState => state.chain;

export const useChains = (): RequestState<Chain[]> => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(selectState);

  useEffect(() => {
    if (state.chains === EMPTY_STATE) {
      dispatch(loadChains());
    }
  }, [state, dispatch]);

  // TODO We should probably handle errors in a generic way?
  // Throw here and have a error boundary that handles it?

  return state.chains;
};

export const { reset, sortChains } = chainSlice.actions;
export default chainSlice.reducer;
