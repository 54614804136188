import { RootPanel } from "../../components/panels/root-panel/root-panel";
import {
  PageRoute,
  UsageBillingTabValue,
  getUsageBillingTabs,
} from "src/utility/utils";
import { CustomTabs } from "src/ui/components/panels/custom-tabs";
import { PlanViewer } from "src/ui/components/plan-viewer/plan-viewer";

export const Plan = () => {
  return (
    <RootPanel withNavbar>
      <CustomTabs
        path={PageRoute.USAGE_BILLING_TABS}
        tabs={getUsageBillingTabs()}
        defaultTabValue={UsageBillingTabValue.BILLING}
      />
      <PlanViewer />
    </RootPanel>
  );
};
