import {
  DEFAULT_THEME,
  createTheme,
  mergeMantineTheme,
  colorsTuple,
  virtualColor,
  rem,
  Text,
} from "@mantine/core";

export const theme = mergeMantineTheme(
  DEFAULT_THEME,
  createTheme({
    breakpoints: {
      xs: "599px",
      sm: "600px",
      md: "900px",
      lg: "1200px",
      xl: "1800px",
    },
    components: {
      Text: Text.extend({
        defaultProps: {
          c: "primary",
        },
      }),
    },
    fontFamily:
      "Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji",
    shadows: {
      xs: `0 ${rem(1)} ${rem(2)} rgba(0, 0, 0, 0.102), 0 ${rem(1)} ${rem(
        3,
      )} rgba(0, 0, 0, 0.051)`,
      sm: `0 ${rem(7)} ${rem(7)} ${rem(-5)} rgba(0, 0, 0, 0.0392), 0 ${rem(
        10,
      )} ${rem(15)} ${rem(-5)} rgba(0, 0, 0, 0.102), 0 ${rem(1)} ${rem(
        3,
      )} rgba(0, 0, 0, 0.051)`,
      md: `0 ${rem(10)} ${rem(10)} ${rem(-5)} rgba(0, 0, 0, 0.0392), 0 ${rem(
        20,
      )} ${rem(25)} ${rem(-5)} rgba(0, 0, 0, 0.102), 0 ${rem(1)} ${rem(
        3,
      )} rgba(0, 0, 0, 0.051)`,
      lg: `0 ${rem(12)} ${rem(12)} ${rem(-7)} rgba(0, 0, 0, 0.0392), 0 ${rem(
        28,
      )} ${rem(23)} ${rem(-7)} rgba(0, 0, 0, 0.102), 0 ${rem(1)} ${rem(
        3,
      )} rgba(0, 0, 0, 0.051)`,
      xl: `0 ${rem(17)} ${rem(17)} ${rem(-7)} rgba(0, 0, 0, 0.0392), 0 ${rem(
        36,
      )} ${rem(28)} ${rem(-7)} rgba(0, 0, 0, 0.102), 0 ${rem(1)} ${rem(
        3,
      )} rgba(0, 0, 0, 0.051)`,
    },
    colors: {
      /* To fetch the two green shades used on the dwellir website, use green[5] for the light one and green[9] for dark */
      green: [
        "#e6fcf6",
        "#daf1eb",
        "#b9e0d5",
        "#94cfbe",
        "#76c0ab",
        "#62b79f",
        "#52b196",
        "#449d84",
        "#378c75",
        "#29594b",
      ],

      white: [
        "#f5f5f5",
        "#e7e7e7",
        "#cdcdcd",
        "#b2b2b2",
        "#9a9a9a",
        "#8b8b8b",
        "#848484",
        "#717171",
        "#656565",
        "#575757",
      ],

      purple: [
        "#9966CC",
        "#8c5fbd",
        "#7f57ae",
        "#7250a0",
        "#654991",
        "#584282",
        "#4b3b73",
        "#3e3365",
        "#312c56",
        "#242547",
      ],

      dark: [
        "#c9c9c9",
        "#b8b8b8",
        "#828282",
        "#696969",
        "#424242",
        "#3b3b3b",
        "#2e2e2e",
        "#242424",
        "#1f1f1f",
        "#141414",
      ],

      /* Colors taken from Dwellir.com */
      // Background/Body in Figma
      backgroundLight: colorsTuple(DEFAULT_THEME.colors.gray[1]),
      backgroundDark: colorsTuple(DEFAULT_THEME.colors.dark[7]),
      background: virtualColor({
        name: "background",
        light: "backgroundLight",
        dark: "backgroundDark",
      }),

      // Background/default in Figma
      // "second-background-light": "white",
      "second-background-light": colorsTuple("#FFFFFF"),
      "second-background-dark": colorsTuple(DEFAULT_THEME.colors.dark[6]),
      "second-background": virtualColor({
        name: "second-background",
        light: "second-background-light",
        dark: "second-background-dark",
      }),

      "primary-text": virtualColor({
        name: "primary-text",
        light: "black",
        dark: "white",
      }),
    },
    primaryShade: { light: 6, dark: 8 },
    primaryColor: "green",
  }),
);

export const grayFontColor = theme.colors?.gray?.[6];
