import { RootPanel } from "../components";
import { ApiKeys } from "../components/api-keys-card";

export const ApiKeysPage = () => {
  return (
    <RootPanel withNavbar>
      <ApiKeys />
    </RootPanel>
  );
};
