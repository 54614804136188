import { RootPanel } from "../components";
import { SettingsCard } from "../components/settings-card";
import { Loading } from "../components/loading";
import { LinkButton } from "../components/buttons";
import { SignOutIcon } from "../icons/sign-out-icon";
import { useOrganizationInformation } from "src/store/organization";
import { useNavigate } from "react-router-dom";
import { PageRoute } from "src/utility/utils";
import { Flex, Paper } from "@mantine/core";
import { useTranslation } from "react-i18next";

export const Settings = () => {
  const information = useOrganizationInformation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleLogout = () => {
    navigate(PageRoute.LOGOUT);
  };
  return (
    <RootPanel withNavbar>
      <Flex direction="column" gap="md">
        <Loading isLoading={information === null}>
          {information && <SettingsCard information={information} />}
        </Loading>
        <Paper
          p="xl"
          radius="md"
          mx={{ base: "sm", md: "sm", lg: "25vw", xl: "25vw" }}
          mt="lg"
        >
          <LinkButton
            text={t("profile.sign_out_button")}
            link=""
            fullWidth
            size="md"
            icon={<SignOutIcon fillColor={"white"} />}
            onClick={handleLogout}
          />
        </Paper>
      </Flex>
    </RootPanel>
  );
};
