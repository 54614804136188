import { FC, useState, ReactNode } from "react";
import { Button, SubmitButton } from "../buttons";
import { UrlClickToCopy } from "../url-click-to-copy";
import { CreateKeyModal } from "./create-key-modal";
import { Loading } from "src/ui/components/loading";
import {
  Paper,
  Flex,
  Table,
  Modal,
  Text,
  TextInput,
  NumberInput,
  ActionIcon,
  Grid,
  Divider,
  MantineBreakpoint,
  Box,
  Title,
  Accordion,
  Switch,
} from "@mantine/core";
import {
  createApiKey,
  deleteApiKey,
  updateApiKey,
  useUserApiKeys,
} from "src/store/api_keys";
import { ApiKey, CreateApiKeyInput } from "src/api/api_keys";
import { PlusIcon } from "src/ui/icons/plus-icon";
import { useAppDispatch } from "src/core/hooks";
import { DeleteIcon } from "src/ui/icons/delete-icon";
import { useDisclosure } from "@mantine/hooks";
import { useForm } from "@mantine/form";
import { theme } from "src/ui/theme";
import { useCurrentSubscription } from "src/store/user";
import useWindowDimensions from "src/utility/use-window-dimensions";
import { GearIcon } from "src/ui/icons/gear-icon";
import { SaveIcon } from "src/ui/icons/save-icon";
import { Form } from "react-router-dom";
import { StopIcon } from "src/ui/icons/stop-icon";
import { PlayIcon } from "src/ui/icons/play-icon";
import { CurrentSubscription } from "src/api/user";

interface ResponsiveTableProps {
  data: Record<string, ReactNode>[];
  breakpoint?: MantineBreakpoint;
}

const ResponsiveTable: FC<ResponsiveTableProps> = ({
  data,
  breakpoint = "md",
}) => {
  const { width } = useWindowDimensions();

  if (width >= parseInt(theme.breakpoints[breakpoint])) {
    return (
      <Table
        striped="even"
        stickyHeader
        stickyHeaderOffset={60}
        visibleFrom={breakpoint}
      >
        <Table.Thead>
          <Table.Tr>
            {Object.keys(data[0]).map((key) => (
              <Table.Th key={`${key}-header`}>{key}</Table.Th>
            ))}
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {data.map((row, index) => {
            return (
              <Table.Tr key={`data-row-${index}`}>
                {Object.values(row).map((value, idx) => (
                  <Table.Td key={`${index}-${idx}-data`}>{value}</Table.Td>
                ))}
              </Table.Tr>
            );
          })}
        </Table.Tbody>
      </Table>
    );
  } else {
    return (
      <Grid hiddenFrom={breakpoint}>
        {data.map((row, index) => {
          const rows = Object.entries(row).map(([key, value]) => (
            <Box key={`${index}-${key}`}>
              <Grid.Col span={3}>
                <Text fw={700}>{key}</Text>
              </Grid.Col>
              <Grid.Col span={9}>{value}</Grid.Col>
            </Box>
          ));
          if (index !== data.length - 1) {
            rows.push(
              <Grid.Col span={12} key={`divider-${index}`}>
                <Divider />
              </Grid.Col>,
            );
          }

          return rows;
        })}
      </Grid>
    );
  }
};

interface deleteButtonProps {
  id?: string;
  enabled: boolean;
  onClick: () => void;
}

const DeleteButton: FC<deleteButtonProps> = ({ enabled, onClick }) => {
  const form = useForm({
    initialValues: {
      confirmDelete: "",
    },
  });

  const handleConfirm = () => {
    if (form.values.confirmDelete === "DELETE") {
      onClick();
    }
  };

  return (
    <Paper shadow="sm" p="md" radius="md" withBorder>
      <Flex direction="column" gap="md">
        <Title order={3}>Delete API key</Title>

        {enabled ? (
          <>
            <Text>
              You will not be able to recover it and all traffic using this key
              will be blocked!
            </Text>
            <Text>
              If you are sure that you want to delete the key, please type{" "}
              <Text span fw={700} inherit>
                DELETE
              </Text>{" "}
              and then press enter.
            </Text>
            <Form onSubmit={form.onSubmit(handleConfirm)}>
              <TextInput
                placeholder="DELETE"
                size="md"
                rightSection={
                  <ActionIcon
                    disabled={form.values.confirmDelete != "DELETE"}
                    color="red"
                    onClick={handleConfirm}
                  >
                    <DeleteIcon />
                  </ActionIcon>
                }
                {...form.getInputProps("confirmDelete")}
              />
            </Form>
          </>
        ) : (
          <Text>You can not delete your last API key.</Text>
        )}
      </Flex>
    </Paper>
  );
};

interface ConfigureButtonProps {
  id?: string;
  onClick: () => void;
}

const ConfigureButton: FC<ConfigureButtonProps> = ({ id, onClick }) => {
  return (
    <ActionIcon id={id} color="red" onClick={() => onClick()}>
      <GearIcon />
    </ActionIcon>
  );
};

interface enabledToggleButtonProps {
  id?: string;
  apiKey: ApiKey;
  onToggle: (apiKey: ApiKey, enabled: boolean) => void;
}

const EnabledToggleButton: FC<enabledToggleButtonProps> = ({
  apiKey,
  onToggle,
}) => {
  const enabled = apiKey.manualEnabled;

  return (
    <Paper shadow="sm" p="md" radius="md" withBorder>
      <Flex direction="column" gap="md">
        <Title order={3}>{enabled ? "Disable" : "Enable"} API key</Title>
        {enabled ? (
          <Text>
            All traffic using this key will be blocked until the key is manually
            reactivated.
          </Text>
        ) : (
          <Text>All traffic using this key will be allowed to continue.</Text>
        )}
        <Button
          variant="filled"
          color="orange"
          leftSection={enabled ? <StopIcon /> : <PlayIcon />}
          onClick={() => onToggle(apiKey, !apiKey.manualEnabled)}
          text={enabled ? "Disable" : "Enable"}
        />
      </Flex>
    </Paper>
  );
};

interface EditModalProps {
  open: boolean;
  apiKey: ApiKey;
  subscription: CurrentSubscription;
  keysCount: number;
  onUpdate(
    key: ApiKey,
    name: string,
    dailyQuota: number | undefined,
    monthlyQuota: number | undefined,
  ): void;
  onEnabledToogle: () => void;
  onDelete: (apiKey: ApiKey) => void;
  onClose: () => void;
}

const EditModal: FC<EditModalProps> = ({
  open,
  apiKey,
  subscription,
  keysCount,
  onUpdate,
  onEnabledToogle,
  onDelete,
  onClose,
}) => {
  const [customDailyQuota, setCustomDailyQuota] = useState(
    apiKey.dailyQuota != null,
  );
  const [customMontlyQuota, setCustomMonthlyQuota] = useState(
    apiKey.monthlyQuota != null,
  );
  const form = useForm({
    initialValues: {
      name: apiKey.name,
      dailyQuota: (apiKey.dailyQuota || "").toString(),
      monthlyQuota: (apiKey.monthlyQuota || "").toString(),
    },
  });

  const handleUpdate = () => {
    let daily = undefined;
    if (customDailyQuota && form.values.dailyQuota !== "") {
      daily = Number(form.values.dailyQuota);
    }
    let monthly = undefined;
    if (customMontlyQuota && form.values.monthlyQuota !== "") {
      monthly = Number(form.values.monthlyQuota);
    }
    onUpdate(apiKey, form.values.name, daily, monthly);
  };

  const quotasEnabled = subscription.apiKeysLimit > 1;

  return (
    <Modal
      opened={open}
      onClose={onClose}
      title={
        <Box>
          <Text>Edit API key</Text>
          <Text size="sm" c="dimmed" fw={400} truncate="end">
            {apiKey.apiKey}
          </Text>
        </Box>
      }
    >
      <form onSubmit={form.onSubmit(handleUpdate)}>
        <Flex direction="column" gap="md">
          <TextInput label="Name" size="md" {...form.getInputProps("name")} />
          {!quotasEnabled && (
            <Text size="sm" c="dimmed" fw={400}>
              Your current subscription plan does not support multiple keys.
              Custom quotas are disabled.
            </Text>
          )}
          <Switch
            label="Set a daily quota for this key"
            checked={customDailyQuota}
            disabled={!quotasEnabled}
            onChange={(event) => {
              setCustomDailyQuota(event.currentTarget.checked);
            }}
          />
          {customDailyQuota && (
            <NumberInput
              description="Block this key after this many requests in a day"
              size="md"
              thousandSeparator=" "
              hideControls
              {...form.getInputProps("dailyQuota")}
            />
          )}
          <Switch
            label="Set a montly quota for this key"
            checked={customMontlyQuota}
            disabled={!quotasEnabled}
            onChange={(event) => {
              setCustomMonthlyQuota(event.currentTarget.checked);
            }}
          />
          {customMontlyQuota && (
            <NumberInput
              description="Block this key after this many requests in a month"
              size="md"
              thousandSeparator=" "
              hideControls
              {...form.getInputProps("monthlyQuota")}
            />
          )}
          <SubmitButton text="Update Api Key" icon={<SaveIcon />} />
        </Flex>
      </form>
      <Divider mb="md" />
      <Accordion variant="filled">
        <Accordion.Item key={"dangerousActions"} value="dangerousActions">
          <Accordion.Control>
            <div>
              <Text>Dangerous Actions</Text>
              <Text size="sm" c="dimmed" fw={400}>
                Be careful with these actions, they will have an immediate
                effect on your traffic.
              </Text>
            </div>
          </Accordion.Control>
          <Accordion.Panel>
            <Flex direction="column" gap="md">
              <EnabledToggleButton
                id="enable-disable-key"
                apiKey={apiKey}
                onToggle={onEnabledToogle}
              />
              <DeleteButton
                enabled={keysCount > 1}
                onClick={() => onDelete(apiKey)}
              />
            </Flex>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Modal>
  );
};

export const ApiKeys: FC = () => {
  const apiKeys = useUserApiKeys();
  const dispatch = useAppDispatch();
  const subscription = useCurrentSubscription();
  const [showCreateModal, createModalControlls] = useDisclosure(false);
  const [showEditModal, editModalControlls] = useDisclosure(false);
  const [updateKeyId, setUpdateKeyId] = useState<number | null>(null);

  const handleCreate = (input: CreateApiKeyInput) => {
    dispatch(createApiKey(input));
  };

  const handleUpdate = (
    key: ApiKey,
    name: string,
    dailyQuota: number | undefined,
    monthlyQuota: number | undefined,
  ) => {
    dispatch(updateApiKey({ key, update: { name, dailyQuota, monthlyQuota } }));
    setUpdateKeyId(null);
    editModalControlls.close();
  };

  const handleEnableToggle = () => {
    if (updateKeyId == null) {
      return;
    }
    const key: ApiKey | null =
      apiKeys?.find((k) => k.id === updateKeyId) || null;
    if (key == null) {
      return;
    }
    dispatch(updateApiKey({ key, update: { enabled: !key.manualEnabled } }));
  };

  const handleDeleteApiKey = async (key: ApiKey | null) => {
    if (key) {
      dispatch(deleteApiKey(key));
    }
  };

  const apiKeyData = apiKeys?.map((key, index) => {
    return {
      Name: <Text miw={"120px"}>{key.name}</Text>,
      Key: (
        <UrlClickToCopy
          id={index === 0 ? "click-to-copy" : undefined}
          text={key.apiKey}
          url={key.apiKey}
        />
      ),
      Actions: (
        <Loading isLoading={subscription.state === "loading"}>
          {subscription.state === "fulfilled" && (
            <Flex direction="row" gap="sm">
              <ConfigureButton
                id={index === 0 ? "configure-key" : undefined}
                onClick={() => {
                  setUpdateKeyId(key.id);
                  editModalControlls.open();
                }}
              />
            </Flex>
          )}
        </Loading>
      ),
    };
  });

  const key = apiKeys?.find((k) => k.id === updateKeyId);

  return (
    <Paper shadow="sm" p="md" m="md" radius="md" withBorder h="90%">
      {key && subscription.state === "fulfilled" && (
        <EditModal
          open={showEditModal}
          apiKey={key}
          subscription={subscription.data}
          keysCount={apiKeys?.length || 0}
          onUpdate={handleUpdate}
          onEnabledToogle={() => {
            handleEnableToggle();
          }}
          onDelete={handleDeleteApiKey}
          onClose={editModalControlls.close}
        />
      )}
      {subscription.state === "fulfilled" && (
        <CreateKeyModal
          open={showCreateModal}
          subscription={subscription.data}
          onCreate={handleCreate}
          onClose={createModalControlls.close}
        />
      )}
      <Loading isLoading={apiKeys == null || subscription.state === "loading"}>
        <Flex direction="row" justify="flex-end" mb="md">
          {subscription.state === "fulfilled" &&
          apiKeys != null &&
          apiKeys.length >= subscription.data.apiKeysLimit ? (
            <Button
              id="add-new"
              text="Add new"
              tooltip="You have reached your maximum number of api keys. Upgrade your subscription plan to add more."
              disabled
              icon={<PlusIcon fillColor="black" />}
            />
          ) : (
            <Button
              id="add-new"
              text="Add new"
              icon={<PlusIcon fillColor="white" />}
              onClick={createModalControlls.open}
            />
          )}
        </Flex>
      </Loading>
      {apiKeyData && <ResponsiveTable data={apiKeyData} />}
    </Paper>
  );
};
