export const DwellirShield = () => {
  return (
    <svg
      width="42"
      height="34"
      viewBox="0 0 42 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.1229 18.9184C33.0815 16.7553 33.1141 14.5909 33.1141 12.4271H33.1024C33.1024 9.99688 33.1031 7.5663 33.1017 5.13601C33.1011 4.2746 33.0519 4.19014 32.1752 3.99528C29.1532 3.32412 26.1305 2.6545 23.1004 2.0163C22.1326 1.81227 21.1488 1.67324 20.1406 1.82936C16.5632 2.38188 13.0604 3.23994 9.55073 4.07149C9.14407 4.16756 8.92675 4.38373 8.90365 4.77344C8.89617 4.89635 8.88283 5.01892 8.88283 5.14179C8.88022 9.79747 8.88511 14.4531 8.87502 19.109C8.87144 20.8307 9.32398 22.4373 10.2043 23.9391C11.5486 26.2325 13.4889 28.0196 15.7109 29.558C17.1934 30.5847 18.7677 31.4663 20.4776 32.1289C20.8592 32.2767 21.1963 32.2749 21.5769 32.1264C22.9267 31.6004 24.1906 30.9299 25.3985 30.1648C27.6476 28.7402 29.647 27.0695 31.1654 24.9374C32.4618 23.1176 33.1652 21.1338 33.1229 18.9184Z"
        fill="#29594B"
      />
      <path
        d="M10.2618 5.23582C10.2611 6.70951 10.2608 8.18325 10.2608 9.65694C10.2608 12.7553 10.2608 15.9595 10.2543 19.1113C10.251 20.6054 10.63 21.9809 11.4128 23.3165C12.529 25.2211 14.155 26.8738 16.5302 28.5183C18.0362 29.5614 19.4998 30.3518 21.0045 30.9342C21.012 30.9373 21.0185 30.9394 21.024 30.9413C21.0305 30.9391 21.038 30.9367 21.0471 30.933C22.2287 30.4726 23.3996 29.8713 24.6264 29.094C26.9818 27.602 28.7451 26.0081 30.0188 24.22C31.2153 22.5399 31.7797 20.8128 31.744 18.9414C31.715 17.4238 31.7222 15.8876 31.729 14.4019C31.73 14.1766 31.7309 13.9488 31.7319 13.7195H31.7231L31.7235 10.3802C31.7238 8.66106 31.7238 6.94218 31.7228 5.223C28.5522 4.51893 25.685 3.88529 22.7984 3.27723C22.0179 3.11257 21.1825 2.97781 20.3649 3.10436C17.1334 3.6032 13.8824 4.37592 10.7384 5.12266L10.2618 5.23582ZM21.0237 33.5301C20.6652 33.5301 20.3067 33.4609 19.9508 33.3233C18.2362 32.6589 16.5809 31.7673 14.8912 30.5973C12.2209 28.7485 10.2927 26.7741 8.99557 24.5613C7.99648 22.8567 7.49189 21.0213 7.49612 19.1064C7.50295 15.9568 7.50328 12.7541 7.50328 9.65664C7.50328 8.1515 7.50328 6.64611 7.50393 5.14097C7.50393 5.01169 7.51239 4.89795 7.51987 4.79762C7.52247 4.76558 7.52475 4.73359 7.5267 4.70155C7.58331 3.75873 8.21347 3.05495 9.21256 2.81834L10.061 2.61708C13.2607 1.8566 16.5696 1.07077 19.9162 0.553926C21.2033 0.35514 22.4011 0.544169 23.4031 0.755189C26.3451 1.3748 29.2601 2.0197 32.4929 2.73781C32.9741 2.84456 33.6328 2.99092 34.072 3.54252C34.4797 4.05451 34.48 4.62868 34.4803 5.13519C34.4816 6.88364 34.4813 8.63208 34.4813 10.3805L34.481 11.1347H34.4927V12.4269C34.4927 13.0898 34.4897 13.7625 34.4868 14.4129C34.48 15.8845 34.4732 17.4064 34.5018 18.895C34.5467 21.2775 33.8105 23.5519 32.3123 25.655C30.8366 27.727 28.8277 29.5526 26.1704 31.2355C24.7891 32.1107 23.4594 32.7925 22.106 33.32C21.7469 33.4599 21.3851 33.5301 21.0237 33.5301Z"
        fill="#52B196"
      />
      <mask
        id="mask0_7942_11868"
        maskUnits="userSpaceOnUse"
        x="13"
        y="8"
        width="15"
        height="14"
      >
        <path
          d="M13.9229 8.84607H27.873V21.9397H13.9229V8.84607Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_7942_11868)">
        <path
          d="M18.9698 16.2315C18.4099 16.1159 17.9941 16.259 17.6539 16.5688C16.9583 17.2015 16.2654 17.8379 15.5994 18.4974C15.0525 19.0393 15.0577 19.8471 15.564 20.3438C16.0975 20.8671 16.9824 20.8854 17.5907 20.3459C18.3143 19.704 19.0144 19.0387 19.7106 18.3706C20.061 18.0346 20.2403 17.6354 20.0623 17.0628C19.7152 17.357 19.4341 17.6165 19.1286 17.8483C18.8625 18.0504 18.5697 18.0321 18.3227 17.811C18.0709 17.5857 18.0745 17.3149 18.2704 17.0603C18.4714 16.7981 18.6969 16.5526 18.9698 16.2315ZM22.7681 14.6023C23.2736 14.7554 23.6842 14.62 24.029 14.3065C24.764 13.6381 25.5041 12.9731 26.2065 12.2751C26.7979 11.6878 26.7605 10.8987 26.1749 10.3805C25.6268 9.89482 24.8151 9.89967 24.2119 10.4324C23.4864 11.0734 22.7804 11.7348 22.0823 12.4025C21.7322 12.7376 21.5637 13.1362 21.7556 13.6384C22.1141 13.4902 22.2983 13.2408 22.5055 13.0167C22.7827 12.7163 23.121 12.7151 23.4259 12.9231C23.7773 13.163 23.733 13.482 23.4867 13.7857C23.2639 14.0601 22.904 14.2309 22.7681 14.6023ZM18.7486 14.9429C19.114 14.9264 19.4647 14.959 19.7789 15.1262C20.0379 15.2643 20.2237 15.1978 20.4169 15.0118C20.615 14.8215 20.7621 14.6611 20.6342 14.3556C20.1264 13.1396 20.5129 12.1446 21.51 11.2859C22.1854 10.7044 22.7886 10.0488 23.4708 9.47492C24.4513 8.65069 25.8886 8.64307 26.8942 9.40448C27.8741 10.1464 28.1487 11.4478 27.5807 12.4971C27.3347 12.9517 26.8487 13.157 26.5126 13.5146C26.0019 14.0589 25.4921 14.6063 24.9409 15.1134C24.1608 15.8308 23.2183 16.0461 22.1815 15.6982C21.8409 15.5842 21.6092 15.5388 21.319 15.8077C21.0415 16.065 21.1268 16.2672 21.2312 16.5477C21.604 17.5488 21.3806 18.4435 20.589 19.2018C19.8905 19.8708 19.1921 20.5402 18.4711 21.1875C17.3751 22.1722 15.8044 22.1865 14.7473 21.2442C13.7034 20.3139 13.6324 18.7847 14.6397 17.7531C15.3856 16.9887 16.1837 16.2672 16.985 15.5528C17.471 15.1198 18.0696 14.8966 18.7486 14.9429Z"
          fill="#52B196"
        />
      </g>
    </svg>
  );
};
