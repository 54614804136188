import { AxiosResponse } from "axios";
import { marlyApi } from "./marly";
import { recursiveToCamelCase } from "src/utility/recursive-to-camel-case";

export interface ApiKey {
  type: string;
  version: string;
  id: number;
  userId: string;
  name: string;
  apiKey: string;
  createdAt: string;
  updatedAt: string;
  enabled: boolean;
  manualEnabled: boolean;
  status: string;
  dailyQuota: number | null;
  monthlyQuota: number | null;
}

export interface CreateApiKeyInput {
  name?: string;
  dailyQuota?: number;
  monthlyQuota?: number;
}

export interface UpdateApiKey {
  name?: string;
  dailyQuota?: number;
  monthlyQuota?: number;
  enabled?: boolean;
}

const createApiKey = async (
  input: CreateApiKeyInput,
): Promise<AxiosResponse<ApiKey>> => {
  return await marlyApi
    .post(
      "/v3/user/apikey",
      {
        name: input.name,
        daily_quota: input.dailyQuota, // eslint-disable-line camelcase
        monthly_quota: input.monthlyQuota, // eslint-disable-line camelcase
      },
      {
        withCredentials: true,
      },
    )
    .then((response) => {
      response.data = recursiveToCamelCase(response.data);
      return response;
    })
    .catch((error) => {
      // Unknown error
      console.error("Unknown createApiKey error", error);
      throw error;
    });
};

const getApiKeys = async (): Promise<AxiosResponse<ApiKey[]>> => {
  return await marlyApi
    .get("/v3/user/apikeys", {
      withCredentials: true,
    })
    .then((response) => {
      response.data = recursiveToCamelCase(response.data);
      return response;
    })
    .catch((error) => {
      // Unknown error
      console.error("Unknown getApiKeys error", error);
      throw error;
    });
};

const updateApiKey = async (
  key: ApiKey,
  update: UpdateApiKey,
): Promise<AxiosResponse<ApiKey>> => {
  let newDailyQuota = update.dailyQuota || key.dailyQuota;
  if (update.dailyQuota == null) {
    // We need to explicitly set dailyQuota to null to avoid the fallback to the previous value
    newDailyQuota = null;
  }
  let newMonthlyQuota = update.monthlyQuota || key.monthlyQuota;
  if (update.monthlyQuota == null) {
    // We need to explicitly set monthlyQuota to null to avoid the fallback to the previous value
    newMonthlyQuota = null;
  }
  let newEnabled = key.manualEnabled;
  if (update.enabled != null) {
    newEnabled = update.enabled;
  }

  return await marlyApi
    .post(
      `/user/apikey/${key.apiKey}`,
      {
        name: update.name || key.name,
        daily_quota: newDailyQuota, // eslint-disable-line camelcase
        monthly_quota: newMonthlyQuota, // eslint-disable-line camelcase
        enabled: newEnabled,
      },
      {
        withCredentials: true,
      },
    )
    .then((response) => {
      response.data = recursiveToCamelCase(response.data);
      return response;
    })
    .catch((error) => {
      // Unknown error
      console.error("Unknown updateApiKey error", error);
      throw error;
    });
};

const deleteApiKey = async (
  key: ApiKey,
): Promise<AxiosResponse<ApiKey["id"]>> => {
  return await marlyApi
    .delete(`/user/apikey/${key.apiKey}`, {
      withCredentials: true,
    })
    .then((response) => {
      response.data = key.id;
      return response;
    })
    .catch((error) => {
      // Unknown error
      console.error("Unknown deleteApiKey error", error);
      throw error;
    });
};

export default {
  createApiKey,
  getApiKeys,
  updateApiKey,
  deleteApiKey,
};
