import { FC, ReactElement, useCallback, useEffect, useState } from "react";
import { Loading } from "../loading";
import { UrlClickToCopy } from "../url-click-to-copy";
import { LinkButton } from "../buttons";
import { RequestChain } from "../requst-chain";
import { FilledStarIcon } from "src/ui/icons/filled-star-icon";
import { EmptyStarIcon } from "src/ui/icons/empty-star-icon";
import {
  Select,
  Image,
  Flex,
  Text,
  Table,
  Button,
  Paper,
  ActionIcon,
  TextInput,
  SelectProps,
  ThemeIcon,
  Box,
} from "@mantine/core";
import {
  useChains,
  addChainFavorite,
  removeChainFavorite,
} from "src/store/chain";
import { Chain, Network } from "src/api/chain";
import { useUserApiKeys } from "src/store/api_keys";
import { ApiKey } from "src/api/api_keys";
import { theme } from "src/ui/theme";
import { endpointGetMetricsPath } from "src/utility/routes";
import { useMediaQuery } from "@mantine/hooks";
import { SpockHandIcon } from "src/ui/icons/spock-hand-icon";
import { PageRoute } from "src/utility/utils";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "src/core/hooks";
import { SearchIcon } from "src/ui/icons/search-icon";
import { endpointUrl } from "src/utility/endpoint";

interface EndpointsCardProps {
  filterChains?: boolean;
  favouriteChains?: boolean;
}

export const EndpointsCard: FC<EndpointsCardProps> = ({
  filterChains,
  favouriteChains,
}) => {
  const dispatch = useAppDispatch();
  const chains = useChains();
  // const [rows, setRows] = useState<ReactElement[]>([]);
  const apiKeys = useUserApiKeys();
  // const [selectedChain, setSelectedChain] = useState<Chain | undefined>(
  //   undefined,
  // );
  const [selectedApiKey, setSelectedApiKey] = useState<ApiKey | null>(null);
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [filter, setFilter] = useState<string>("");
  // const [showAll, setShowAll] = useState<boolean>(true);
  const [filteredData, setFilteredData] = useState<Chain[] | null>(null);

  // Sort chains if we switch keys
  // useEffect(() => {
  //   if (selectedApiKey && chains.state === "fulfilled") {
  //     dispatch(sortChains(selectedApiKey.apiKey));
  //   }
  // }, [dispatch, selectedApiKey, chains]);

  useEffect(() => {
    if (apiKeys && apiKeys.length >= 1) {
      setSelectedApiKey(apiKeys[0]);
    }
  }, [apiKeys, setSelectedApiKey]);

  useEffect(() => {
    if (chains.state !== "fulfilled") {
      return;
    }
    if (!filter) {
      setFilteredData(chains.data);
    } else {
      if (selectedApiKey) {
        let filteredChains;
        if (favouriteChains) {
          filteredChains = chains.data.filter(
            (chain) =>
              chain.networks.some((network) =>
                network.favoritedBy.includes(selectedApiKey.apiKey),
              ) &&
              (chain.name.toLowerCase().includes(filter.toLowerCase()) ||
                chain.networks.some((network) =>
                  network.name.toLowerCase().includes(filter.toLowerCase()),
                )),
          );
        } else {
          filteredChains = chains.data.filter(
            (chain) =>
              chain.name.toLowerCase().includes(filter.toLowerCase()) ||
              chain.networks.some((network) =>
                network.name.toLowerCase().includes(filter.toLowerCase()),
              ),
          );
        }
        // Sort based on if the Chain starts with filter or not
        filteredChains.sort((a: Chain, b: Chain) => {
          const aStarts = a.name.toLowerCase().startsWith(filter.toLowerCase());
          const bStarts = b.name.toLowerCase().startsWith(filter.toLowerCase());
          if (aStarts && !bStarts) {
            return -1;
          }
          if (!aStarts && bStarts) {
            return 1;
          }
          return 0;
        });
        setFilteredData(filteredChains);
      }
    }
  }, [chains, favouriteChains, selectedApiKey, filter]);

  const toggleFavorite = useCallback(
    (chain: Chain, network: Network) => {
      if (!selectedApiKey || chains.state !== "fulfilled") {
        return null;
      }

      if (network.favoritedBy.includes(selectedApiKey.apiKey) === false) {
        // Update backend
        dispatch(
          addChainFavorite({
            chainId: chain.id,
            networkId: network.id,
            apiKey: selectedApiKey.apiKey,
          }),
        );
      } else {
        // Update backend
        dispatch(
          removeChainFavorite({
            chainId: chain.id,
            networkId: network.id,
            apiKey: selectedApiKey.apiKey,
          }),
        );
      }
      return null;
    },
    [selectedApiKey, dispatch, chains],
  );

  const renderRows = useCallback(
    (chain: Chain, index: number): ReactElement[] => {
      if (!selectedApiKey) {
        return [];
      }

      // Check if filterChains is true and there's a selected API key
      const shouldFilter = filterChains && selectedApiKey;

      const networks = shouldFilter
        ? chain.networks.filter((network) =>
            network.favoritedBy.includes(selectedApiKey.apiKey),
          )
        : chain.networks;

      // If no networks after filtering, return an empty array
      if (networks.length === 0) {
        return [];
      }

      // setRows;

      return networks.map((network) => {
        const key = selectedApiKey?.apiKey ?? "<KEY>";
        const https = network?.https
          ? endpointUrl(network.https, key)
          : undefined;
        const wss = network?.wss ? endpointUrl(network.wss, key) : undefined;

        if (isMobile) {
          return (
            <Table.Tr key={`${chain.name}-${network.name}`}>
              <Table.Td p="0">
                <Button
                  id={index === 0 ? "chain-name" : undefined}
                  variant="transparent"
                  color="green"
                  onClick={() => navigate(endpointGetMetricsPath(chain.name))}
                  size="lg"
                >
                  <Flex gap="xs" align="center" p="0">
                    <ThemeIcon radius="xl" variant="default">
                      <Image h={20} w={20} src={chain.imageUrl} />
                    </ThemeIcon>
                    <Flex direction="column" align="flex-start">
                      <Text fw={400}>{chain.name}</Text>
                      <Text fw={400} c="primaryText">
                        {network.name}
                      </Text>
                    </Flex>
                  </Flex>
                </Button>
              </Table.Td>
              {favouriteChains && (
                <Table.Td align="center">
                  <ActionIcon
                    id={index === 0 ? "mark-as-favorite" : undefined}
                    variant="subtle"
                    onClick={() => toggleFavorite(chain, network)}
                  >
                    {network.favoritedBy.includes(selectedApiKey.apiKey) ? (
                      <FilledStarIcon />
                    ) : (
                      <EmptyStarIcon />
                    )}
                  </ActionIcon>
                </Table.Td>
              )}
            </Table.Tr>
          );
        } else {
          return (
            <Table.Tr key={`${chain.name}-${network.name}`}>
              <Table.Td style={{ whiteSpace: "nowrap" }}>
                <Button
                  id={index === 0 ? "chain-name" : undefined}
                  variant="transparent"
                  color="green"
                  onClick={() => navigate(endpointGetMetricsPath(chain.name))}
                  size="lg"
                >
                  <Flex gap="xs" align="center" p="0">
                    <ThemeIcon radius="xl" variant="default">
                      <Image h={20} w={20} src={chain.imageUrl} />
                    </ThemeIcon>
                    <Flex direction="column" align="flex-start">
                      <Text fw={400}>{chain.name}</Text>
                      <Text fw={400} c="primaryText" hiddenFrom="lg">
                        {network.name}
                      </Text>
                    </Flex>
                  </Flex>
                </Button>
              </Table.Td>
              <Table.Td visibleFrom="lg">{network.name}</Table.Td>
              <Table.Td visibleFrom="lg">{network.nodeType}</Table.Td>
              <Table.Td visibleFrom="md">
                <Flex
                  id={index === 0 ? "click-to-copy" : undefined}
                  direction="row"
                  gap="sm"
                >
                  {https && (
                    <UrlClickToCopy
                      variant="filled"
                      color={theme.colors.green[8]}
                      url={https}
                      text="HTTPS"
                      width={"4em"}
                    />
                  )}
                  {wss && (
                    <UrlClickToCopy
                      variant="filled"
                      color={theme.colors.green[8]}
                      url={wss}
                      text="Websocket"
                      width={"5em"}
                    />
                  )}
                </Flex>
              </Table.Td>
              {favouriteChains && (
                <Table.Td align="center">
                  <ActionIcon
                    id={index === 0 ? "mark-as-favorite" : undefined}
                    variant="subtle"
                    onClick={() => toggleFavorite(chain, network)}
                  >
                    {network.favoritedBy.includes(selectedApiKey.apiKey) ? (
                      <FilledStarIcon />
                    ) : (
                      <EmptyStarIcon />
                    )}
                  </ActionIcon>
                </Table.Td>
              )}
            </Table.Tr>
          );
        }
      });
    },
    [
      navigate,
      selectedApiKey,
      isMobile,
      toggleFavorite,
      favouriteChains,
      filterChains,
      // showAll,
    ],
  );

  // useEffect(() => {
  //   setRows(() => renderRows(selectedChain));
  //   console.log("rows", rows);
  // }, [renderRows, selectedChain]);

  // useEffect(() => {
  //   if (chains.state === "fulfilled" && chains.data.length > 0) {
  //     setSelectedChain(chains.data[0]);
  //   }
  // }, [chains]);

  const renderSelectOption: SelectProps["renderOption"] = ({ option }) =>
    option.label !== option.value ? (
      <Text>
        <Text span fw={700}>
          {option.label}
        </Text>{" "}
        - {option.value}
      </Text>
    ) : (
      <Text>{option.value}</Text>
    );

  return (
    <Loading isLoading={chains.state === "loading"}>
      <Flex direction="column" m="md">
        <Flex
          direction="row"
          gap="lg"
          mb="sm"
          align={"center"}
          justify="space-between"
        >
          {/* {chains.state === "fulfilled" && (
              <SelectChain chains={chains.data} onSelect={setSelectedChain} />
            )} */}
          {apiKeys && apiKeys.length > 1 && (
            <Select
              id="select-api-key"
              label="Select API key"
              placeholder="Select an api key"
              withCheckIcon={false}
              searchable
              disabled={!chains}
              onChange={(value) => {
                if (!value || !apiKeys) {
                  return; // Do nothing if value is null or apiKeys is undefined
                }

                const newApiKey =
                  apiKeys.find((key) => key.apiKey === value) || null;

                if (selectedApiKey?.apiKey === newApiKey?.apiKey) {
                  return; // Do not change if the selected key is the same as the current one
                }

                setSelectedApiKey(newApiKey); // Set the new API key if it's different
              }}
              data={apiKeys.map((key) => ({
                label: key.name || key.apiKey,
                value: key.apiKey,
              }))}
              renderOption={renderSelectOption}
              value={selectedApiKey?.apiKey}
              style={isMobile ? { width: "100%" } : { width: "400px" }}
            />
          )}
          {/* {favouriteChains && (
              <Switch
                label="Show all"
                color="green"
                checked={showAll}
                onChange={(e) => setShowAll(e.currentTarget.checked)} // onChange to toggle the state
              />
            )} */}
        </Flex>
        <TextInput
          id="filter-chains"
          w="100%"
          value={filter}
          leftSection={<SearchIcon />}
          onChange={(e) => setFilter(e.currentTarget.value)}
          placeholder="Search chains"
          mb="md"
        />

        <Paper
          shadow="none"
          radius="md"
          withBorder
          style={{ overflow: "hidden" }}
        >
          {selectedApiKey ? (
            isMobile ? (
              <Table stickyHeader verticalSpacing="lg" highlightOnHover>
                <Table.Thead bg="second-background">
                  <Table.Tr>
                    <Table.Th>
                      <Text ml="md" fw={700}>
                        Chain Name
                      </Text>
                    </Table.Th>
                    {favouriteChains && (
                      <Table.Th>
                        <Flex direction="column" align="center">
                          <Text fw={700}>Add To</Text>
                          <Text fw={700}>Home</Text>
                        </Flex>
                      </Table.Th>
                    )}
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {chains.state === "fulfilled" ? (
                    filteredData?.map(renderRows).flat()
                  ) : (
                    <tr>
                      <td colSpan={3}>
                        <Flex direction="column" p={20} align="center">
                          <SpockHandIcon fillColor={theme.colors?.purple[0]} />
                          <LinkButton
                            mt="md"
                            color={theme.colors?.purple[0]}
                            text={"Add Endpoints To Home"}
                            link={PageRoute.ENDPOINTS}
                          />
                        </Flex>
                      </td>
                    </tr>
                  )}
                  {!selectedApiKey && (
                    <tr>
                      <td colSpan={3}>Select an API key to see endpoints</td>
                    </tr>
                  )}
                </Table.Tbody>
              </Table>
            ) : (
              <Table stickyHeader verticalSpacing="sm" highlightOnHover>
                <Table.Thead bg="second-background">
                  <Table.Tr>
                    <Table.Th>
                      <Text ml="md" fw={700}>
                        Chain Name
                      </Text>
                    </Table.Th>
                    <Table.Th visibleFrom="lg">
                      <Text fw={700}>Network</Text>
                    </Table.Th>
                    <Table.Th visibleFrom="lg">
                      <Text fw={700}>Node Type</Text>
                    </Table.Th>
                    <Table.Th visibleFrom="md">
                      <Text fw={700}>Endpoints</Text>
                    </Table.Th>
                    {favouriteChains && (
                      <Table.Th>
                        <Flex direction="column" align="center">
                          <Text fw={700}>Add To</Text>
                          <Text fw={700}>Home</Text>
                        </Flex>
                      </Table.Th>
                    )}
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {chains.state === "fulfilled" ? (
                    filteredData?.map(renderRows).flat()
                  ) : (
                    <tr>
                      <td colSpan={3}>
                        <Flex direction="column" p={20} align="center">
                          <SpockHandIcon fillColor={theme.colors?.purple[0]} />
                          <LinkButton
                            mt="md"
                            color={theme.colors?.purple[0]}
                            text={"Add Endpoints To Home"}
                            link={PageRoute.ENDPOINTS}
                          />
                        </Flex>
                      </td>
                    </tr>
                  )}
                  {!selectedApiKey && (
                    <tr>
                      <td colSpan={3}>Select an API key to see endpoints</td>
                    </tr>
                  )}
                </Table.Tbody>
              </Table>
            )
          ) : (
            <Flex direction="column" p={20} align="center">
              <SpockHandIcon fillColor={theme.colors?.purple[0]} />
              <LinkButton
                mt="md"
                color={theme.colors?.purple[0]}
                text={"Add Endpoints To Home"}
                link={PageRoute.ENDPOINTS}
                // linkState={{ selectedApiKeyId: selectedApiKey?.id }}
              />
            </Flex>
          )}
        </Paper>
        <Box mt="md">
          <RequestChain initialRequest={filter} />
        </Box>
      </Flex>
    </Loading>
  );
};
