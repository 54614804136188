import api, {
  UserInput,
  PasswordToShort,
  UserExistsError,
  DisposableDomainBlocked,
} from "../api/marly";
import {
  Error,
  EMPTY_STATE,
  LOADING_STATE,
  RequestState,
  fulfilledState,
  rejectedState,
} from "./core/request_state";
import { RootState } from "src/store";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

export interface AuthState {
  register: RequestState<null>; // Track register request
}

const initialState: AuthState = {
  register: EMPTY_STATE,
};

export const logout = createAsyncThunk(
  "auth/logout",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.logout();
      // We get no body, only a remove cookie header
      return response.data;
    } catch (error: unknown) {
      console.error("Unkwon error", error);
      return rejectWithValue({ type: "unknown", serverMessage: "" });
    }
  },
);

export const register = createAsyncThunk(
  "auth/register",
  async (input: UserInput, { rejectWithValue }) => {
    try {
      const response = await api.register(input);
      return response.data;
    } catch (error: unknown) {
      if (error instanceof PasswordToShort) {
        return rejectWithValue({
          type: "password_too_short",
          serverMessage: error.detail,
        });
      }
      if (error instanceof DisposableDomainBlocked) {
        return rejectWithValue({
          type: "disposable_domain_blocked",
        });
      }
      if (error instanceof UserExistsError) {
        return rejectWithValue({
          type: "register_failed_user_exists",
          serverMessage: error.detail,
        });
      }
      // Unknown error
      console.error("Unkwon register error", error);
      return rejectWithValue({ type: "unknown", serverMessage: "" });
    }
  },
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(register.pending, (state, _action) => {
      // Clear any errors we had when we try again
      state.register = LOADING_STATE;
    });
    builder.addCase(
      register.rejected,
      (state, action: PayloadAction<unknown>) => {
        const error = action.payload as Error;

        state.register = rejectedState(error);
      },
    );
    builder.addCase(
      register.fulfilled,
      (state, _action: PayloadAction<unknown>) => {
        state.register = fulfilledState(null);
      },
    );
  },
});

// Action creators are generated for each case reducer function
// export const { login } = authSlice.actions;

/* Selectors */
export const selectRegisterState = (state: RootState): RequestState<null> =>
  state.auth.register;

// Cookie helper functions - to have only one place where the cookie name is defined.
export const cookieSelector = ["marly_authenticated"];
export const isAuthenticated = (cookies: {
  [x: string]: boolean;
}): boolean | undefined => {
  if (Object.prototype.hasOwnProperty.call(cookies, "marly_authenticated")) {
    return cookies["marly_authenticated"];
  }
  return false;
};

export default authSlice.reducer;
