import { FC } from "react";
import { TextComponent } from "../text-component";
import { Grid, Paper, Space, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { theme } from "src/ui/theme";
import { Chart } from "src/utility/chart";

type SummaryDataProps = {
  title: string;
  data: string;
  extraText?: string;
};

const SummaryData = ({ title, data }: SummaryDataProps) => {
  return (
    <>
      <TextComponent title={title} />
      <Text size="lg" fw="700" c={theme.colors?.green?.[0]}>
        {data}
      </Text>
    </>
  );
};

interface MetricsSummaryProps {
  chart: Chart;
}

export const MetricsSummary: FC<MetricsSummaryProps> = ({ chart }) => {
  const { t } = useTranslation();

  return (
    <Space mt="lg">
      <TextComponent title={t("metrics.summary.title")} mb="sm" />
      <Grid>
        <Grid.Col span={{ base: 12, sm: 4 }}>
          <Paper shadow="lg" p="xl" radius="md" withBorder>
            <SummaryData
              title={t("metrics.summary.total_today")}
              data={t("format.number", { number: chart.summary.today })}
            />
          </Paper>
        </Grid.Col>
        <Grid.Col span={{ base: 12, sm: 4 }}>
          <Paper shadow="lg" p="xl" radius="md" withBorder>
            <SummaryData
              title={t("metrics.summary.total_7_days")}
              data={t("format.number", { number: chart.summary.lastSevenDays })}
            />
          </Paper>
        </Grid.Col>
        <Grid.Col span={{ base: 12, sm: 4 }}>
          <Paper shadow="lg" p="xl" radius="md" withBorder>
            <SummaryData
              title={t("metrics.summary.total_30_days")}
              data={t("format.number", {
                number: chart.summary.lastThirtyDays,
              })}
            />
          </Paper>
        </Grid.Col>
      </Grid>
    </Space>
  );
};
