import { useEffect } from "react";
import attributesApi, { Attribute } from "../api/attributes";
import {
  EMPTY_STATE,
  Error,
  LOADING_STATE,
  RequestState,
  fulfilledState,
  rejectedState,
} from "./core/request_state";
import { useAppDispatch, useAppSelector } from "src/core/hooks";
import { RootState } from "src/store";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

export interface AttributesState {
  attributes: RequestState<Attribute[]>;
}

const initialState: AttributesState = {
  attributes: EMPTY_STATE,
};

export const getAttributes = createAsyncThunk(
  "attributes/getAttributes",
  async (_input, { rejectWithValue }) => {
    try {
      const response = await attributesApi.getAttributes();
      return response.data;
    } catch (error: unknown) {
      // Unknown error
      console.error("Unkwon error", error);
      return rejectWithValue({ type: "unknown", serverMessage: "" });
    }
  },
);

export const attributesSlice = createSlice({
  name: "attributes",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getAttributes.pending, (state, _action) => {
      state.attributes = LOADING_STATE;
    });
    builder.addCase(
      getAttributes.rejected,
      (state, action: PayloadAction<unknown>) => {
        const error = action.payload as Error;

        state.attributes = rejectedState(error);
      },
    );
    builder.addCase(
      getAttributes.fulfilled,
      (state, action: PayloadAction<Attribute[]>) => {
        state.attributes = fulfilledState(action.payload);
      },
    );
  },
});

/* Selectors */
const selectAttributesState = (state: RootState): AttributesState =>
  state.attributes;

export const useAttributes = (): RequestState<Attribute[]> => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(selectAttributesState);

  useEffect(() => {
    if (state.attributes === EMPTY_STATE) {
      dispatch(getAttributes());
    }
  }, [state, dispatch]);

  // TODO We should probably handle errors in a generic way?
  // Throw here and have a error boundary that handles it?

  return state.attributes;
};

// export const { } = attributesSlice.actions;
export default attributesSlice.reducer;
