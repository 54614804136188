import { FC, ReactNode, useEffect, useState } from "react";
import { register, selectRegisterState } from "src/store/authentication";
import { useAppDispatch, useAppSelector } from "src/core/hooks";
import { useNavigate } from "react-router-dom";
import { useForm } from "@mantine/form";
import { useTranslation } from "react-i18next";
import {
  Text,
  Flex,
  Anchor,
  Box,
  Paper,
  TextInput,
  PasswordInput,
  Button,
  Progress,
  Title,
  Select,
  Alert,
} from "@mantine/core";
import { PageRoute } from "src/utility/utils";
import { getTosVersion } from "src/ui/components/tos-modal";
import { login, selectLoginState } from "src/store/user";
import { DwellirShield } from "src/ui/icons/dwellir-shield";
import { SellingPointCheckbox } from "src/ui/icons/selling-point-checkbox";
import { GoBackArrow } from "src/ui/icons/go-back-arrow";
import { useAttributes } from "src/store/attributes";
import { Loading } from "src/ui/components/loading";
import { SelectControlParams } from "src/api/attributes";
import { TextLink } from "src/ui/components/text-link";

interface SellingPointProps {
  label: string;
  children: ReactNode;
}

export const SellingPoint: FC<SellingPointProps> = ({ label, children }) => {
  return (
    <Box>
      <Flex direction="row" gap="xs">
        <Box w="rem(24)">
          <SellingPointCheckbox />
        </Box>
        <Flex direction="column">
          <Text size="md" fw={600}>
            {label}
          </Text>
          <Text size="sm" fw={400}>
            {children}
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};

export const Register = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const attributes = useAttributes();

  const [formPart, setFormPart] = useState<"account" | "info">("account");

  const [internalError, setInternalError] = useState<null | ReactNode>(null);

  const loginState = useAppSelector(selectLoginState);
  const registerState = useAppSelector(selectRegisterState);

  const form = useForm({
    initialValues: {
      name: "",
      email: "",
      password: "",
      organization_name: "", // eslint-disable-line camelcase
      usecase: "",
      otherUsecase: "",
      referrer: "",
      tosAgreement: false,
    },
  });

  const handleSubmit = () => {
    const values = form.values;
    if (values.password || false) {
      dispatch(
        register({
          name: values.name,
          email: values.email,
          password: values.password,
          organization_name: values.organization_name, // eslint-disable-line camelcase
          usecase: values.usecase,
          otherUsecase: values.otherUsecase,
          referrer: values.referrer,
          // There is no user when signing up
          tosAgreement: getTosVersion(undefined), // eslint-disable-line camelcase
        }),
      );
    }
  };

  useEffect(() => {
    if (registerState.state === "fulfilled") {
      dispatch(
        login({ username: form.values.email, password: form.values.password }),
      ).then(() => {
        navigate(PageRoute.VERIFY);
      });
    }
  }, [
    registerState,
    navigate,
    form.values.email,
    form.values.password,
    dispatch,
  ]);

  useEffect(() => {
    if (registerState.state === "rejected") {
      const errors = form.errors;
      if (
        registerState.error.type === "password_too_short" &&
        !Object.keys(errors).includes("password")
      ) {
        form.setFieldError("password", t("register.password_too_short"));
        setFormPart("account");
      } else if (registerState.error.type === "disposable_domain_blocked") {
        form.setFieldError(
          "email",
          "Disposable email domains are not allowed.",
        );
        setFormPart("account");
      } else {
        setInternalError(
          <Text size="sm">
            Failed to register user, if you already have an account and forgot
            your password you can reset it{" "}
            <Anchor
              c="green"
              href={PageRoute.FORGOT_PASSWORD + `?email=${form.values.email}`}
              inherit
            >
              here
            </Anchor>
          </Text>,
        );
      }
    }
  }, [registerState, form, t]);

  let usecaseData: string[] = [];
  if (attributes.state === "fulfilled") {
    const usecase = attributes.data.find(
      (attribute) => attribute.systemName === "Usecase",
    );
    if (usecase != null) {
      const params = usecase?.controlParams as SelectControlParams;
      usecaseData = params.options;
    }
  }

  return (
    <Box w="100dvw" h="100dvh" bg="background">
      <Flex h="100vh" justify="center" align="center" p="sm" gap="xl">
        <Box visibleFrom="md">
          <Flex direction="column" w={400} gap="xl">
            <Flex direction="row" w="100%" gap="rem(2)" justify="center">
              <DwellirShield />
              <Text size="xl" fw={600}>
                Blockchain API Platform
              </Text>
            </Flex>
            <Flex direction="column" gap="lg">
              <SellingPoint label="No Compute Units here">
                1 Response = 1 API Credit. We find Compute unit pricing as
                confusing as you do.
              </SellingPoint>
              <SellingPoint label="Super High Rate Limits">
                Handle up to 10x requests per second with our Bursts Feature.
              </SellingPoint>
              <SellingPoint label="Personal Support">
                Gustav, Shanni, and Elias are here for you. Use the chat,{" "}
                <TextLink target="https://cal.com/dwellir/feedback">
                  schedule a call
                </TextLink>
                , or{" "}
                <TextLink target="mailto:support@dwellir.com">
                  email us
                </TextLink>
                .
              </SellingPoint>
            </Flex>
            <Paper radius="md" p="sm" withBorder>
              <center>
                <Text size="md" fw={400}>
                  Did you know? We also offer dedicated nodes!
                </Text>
                <Flex direction="column" w="100%" align="center">
                  <Text size="md" fw={400}>
                    <TextLink target="https://dwellir.outseta.com/forms/aWxXLVWV">
                      Contact us
                    </TextLink>{" "}
                    for a quote.
                  </Text>
                </Flex>
              </center>
            </Paper>
          </Flex>
        </Box>
        <Flex direction="column" w={400} gap="rem(10)">
          <Paper radius="md" p="xl" bg="second-background">
            {formPart === "account" && (
              <Flex direction="column" gap="xl">
                <Flex w="100%" justify="center">
                  <Title order={3}>Get Started</Title>
                </Flex>

                <form onSubmit={form.onSubmit(() => setFormPart("info"))}>
                  <Flex direction="column" gap="xl">
                    <TextInput
                      label={t("register.email")}
                      placeholder={t("register.email_placeholder")}
                      required
                      {...form.getInputProps("email")}
                    />
                    <PasswordInput
                      styles={{
                        label: {
                          // Needed to be able to get the reset link on the right side.
                          width: "100%",
                        },
                      }}
                      label={
                        <Flex
                          direction="row"
                          justify="space-between"
                          align="center"
                        >
                          <Text fw={400}>
                            {t("register.password")}
                            <Text span size="sm" c="var(--mantine-color-error)">
                              {" "}
                              *
                            </Text>
                          </Text>

                          <Progress.Root w="100" size="md">
                            <Progress.Section
                              value={(form.values.password.length / 12) * 100}
                              color={
                                form.values.password.length < 12
                                  ? "red"
                                  : "green"
                              }
                            ></Progress.Section>
                          </Progress.Root>
                        </Flex>
                      }
                      placeholder={t("register.password_placeholder")}
                      {...form.getInputProps("password")}
                    />
                    <Button
                      w="100%"
                      color="green"
                      type="submit"
                      data-disabled={
                        form.values.email === "" ||
                        form.values.password.length < 12
                      }
                    >
                      {t("register.button.continue")}
                    </Button>
                  </Flex>
                </form>

                <Flex w="100%" justify="center">
                  <Text size="xs">
                    Already have an account?{" "}
                    <TextLink target={PageRoute.LOGIN} newTab={false}>
                      Log in
                    </TextLink>
                    .
                  </Text>
                </Flex>
              </Flex>
            )}
            {formPart === "info" && (
              <Flex direction="column" gap="lg">
                <Flex w="100%" justify="center">
                  <Title order={3}>Tell us a bit about yourself</Title>
                </Flex>

                <form onSubmit={form.onSubmit(handleSubmit)}>
                  <Flex direction="column" gap="lg">
                    <TextInput
                      styles={{
                        label: {
                          // Needed to get the required star on the same row as our custom label
                          display: "flex",
                          flexDirection: "row",
                          gap: "4px",
                        },
                      }}
                      label={
                        <Text size="md" fw={600}>
                          Your name
                        </Text>
                      }
                      placeholder={"Satoshi Nakamoto"}
                      required
                      {...form.getInputProps("name")}
                    />

                    <TextInput
                      styles={{
                        label: {
                          // Needed to get the required star on the same row as our custom label
                          display: "flex",
                          flexDirection: "row",
                          gap: "4px",
                        },
                      }}
                      label={
                        <Text size="md" fw={600}>
                          Organization name
                        </Text>
                      }
                      placeholder={"Bitcoin"}
                      required
                      {...form.getInputProps("organization_name")}
                    />

                    <Loading isLoading={attributes.state === "loading"}>
                      <Select
                        styles={{
                          label: {
                            // Needed to get the required star on the same row as our custom label
                            display: "flex",
                            flexDirection: "row",
                            gap: "4px",
                          },
                        }}
                        label={
                          <Text size="md" fw={600}>
                            What are you building?
                          </Text>
                        }
                        required
                        data={usecaseData}
                        {...form.getInputProps("usecase")}
                      />
                    </Loading>

                    {form.values.usecase == "Other" && (
                      <TextInput
                        styles={{
                          label: {
                            // Needed to get the required star on the same row as our custom label
                            display: "flex",
                            flexDirection: "row",
                            gap: "4px",
                          },
                        }}
                        label={
                          <Text size="md" fw={600}>
                            What "Other" thing are you building?
                          </Text>
                        }
                        required
                        {...form.getInputProps("otherUsecase")}
                      />
                    )}

                    <TextInput
                      styles={{
                        label: {
                          // Needed to get the required star on the same row as our custom label
                          display: "flex",
                          flexDirection: "row",
                          gap: "4px",
                        },
                      }}
                      label={
                        <Text size="md" fw={600}>
                          Where did you hear about us?
                        </Text>
                      }
                      required
                      {...form.getInputProps("referrer")}
                    />

                    <Button
                      w="100%"
                      color="green"
                      type="submit"
                      loading={
                        registerState.state === "loading" ||
                        loginState.state === "loading"
                      }
                      data-disabled={
                        form.values.name === "" ||
                        form.values.organization_name == "" ||
                        form.values.usecase === "" ||
                        (form.values.usecase === "Other" &&
                          form.values.otherUsecase === "") ||
                        form.values.referrer == ""
                      }
                    >
                      {t("register.button.create_account")}
                    </Button>
                  </Flex>
                </form>

                <Flex w="100%" justify="center">
                  <Text fw={400} size="xs">
                    By creating an account, you agree to our{" "}
                    <TextLink
                      target={"https://www.dwellir.com/terms-of-service"}
                    >
                      Terms of Service
                    </TextLink>{" "}
                    and{" "}
                    <TextLink target={"https://www.dwellir.com/privacy-policy"}>
                      Privacy Policy
                    </TextLink>
                    .
                  </Text>
                </Flex>

                <Button
                  variant="transparent"
                  color="green.6"
                  onClick={() => setFormPart("account")}
                  leftSection={<GoBackArrow />}
                >
                  <Text size="xs"> or go back</Text>
                </Button>
              </Flex>
            )}

            {internalError && (
              <Alert mt="md" radius="md" color="red">
                {internalError}
              </Alert>
            )}
          </Paper>
        </Flex>
      </Flex>
    </Box>
  );
};
