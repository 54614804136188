import { FC } from "react";
import { Loading } from "../loading";
import { useUser } from "src/store/user";

interface InternalUsersOnlyProps {
  children: React.ReactNode;
}

export const InternalUsersOnly: FC<InternalUsersOnlyProps> = ({ children }) => {
  const user = useUser();

  return (
    <Loading isLoading={user.state === "loading"}>
      {user.state === "fulfilled" &&
        (user.data.email.endsWith("@dwellir.com") ||
          user.data.email.endsWith("@example.com")) &&
        children}
    </Loading>
  );
};
