import { AxiosResponse } from "axios";
import { detailError, isAxiosError, marlyApi } from "./marly";
import { recursiveToCamelCase } from "src/utility/recursive-to-camel-case";

export type Period = "startOfMonth" | "default";
export type Interval = "minute" | "hour" | "day";

export interface RequestResponse {
  requests: number;
  responses: number;
}

export interface ByTime {
  byMethod: Record<string, RequestResponse>;
  responses: number;
}

export interface Analytics {
  type: string;
  version: string;
  totalResponses: number;
  totalRequests: number;
  byKey: Record<string /* apiKey */, Record<string /* time */, ByTime>>;
  byHost: Record<string /* host */, Record<string /* time */, ByTime>>;
}

export interface AnalyticsV4Row {
  apiKey: string;
  datetime: string;
  rpcMethod: string;
  requests: number;
  responses: number;
  domain: string;
}

export interface AnalyticsV4 {
  rows: AnalyticsV4Row[];
}

export interface RpsAnalytics {
  rps: number;
  peakRps: number;
  limitedRequests: number;
}

export interface ByMethodApi {
  requests_by_method: Record<string, number>;
}

export interface AnalyticsApi {
  type: string;
  version: string;
  total_responses: number;
  total_requests: number;
  by_key: Record<string, Record<string, ByMethodApi>>;
}

export interface DedicatedNodeRequest {
  chain: string;
  otherInformation: string;
}

export interface ResetPassword {
  token: string;
  password: string;
}

export interface UserUpdate {
  name: string;
  tosAgreement: string;
}

export class BadTokenError extends Error {
  readonly detail: string;
  constructor(detail: string) {
    super(detail);
    this.detail = detail;
  }
}

export class EmailNotVerifiedError extends Error {
  readonly detail: string;
  constructor(detail: string) {
    super(detail);
    this.detail = detail;
  }
}

const getAnalyticsV4 = async (
  interval: Interval,
  period: Period,
  apiKeys: string[] | undefined = undefined,
  domains: string[] | undefined = undefined,
): Promise<AxiosResponse<AnalyticsV4>> => {
  let query = "";
  if (period === "startOfMonth") {
    query = "start_of_month=true";
  }
  return await marlyApi
    .post(
      `/v4/user/analytics/${interval}?${query}`,
      {
        api_keys: apiKeys, //eslint-disable-line camelcase
        domains,
      },
      {
        withCredentials: true,
      },
    )
    .then((response) => {
      response.data = recursiveToCamelCase(response.data);
      return response;
    });
};

const getRpsAnalytics = async (): Promise<AxiosResponse<RpsAnalytics>> => {
  return await marlyApi
    .get(`/v4/user/analytics/rps`, {
      withCredentials: true,
    })
    .then((response) => {
      response.data = recursiveToCamelCase(response.data);
      return response;
    });
};

const forgotPassword = async (email: string): Promise<AxiosResponse<null>> => {
  const location = document.location;
  const resetUrl = `${location.protocol}//${location.host}/password-reset`;
  return await marlyApi
    .post(
      `/v4/auth/forgot-password`,
      {
        email,
      },
      { headers: { "x-dwellir-reset-url": resetUrl } },
    )
    // TODO error handling
    .catch((error) => {
      if (isAxiosError<detailError>(error)) {
        if (error.response?.data.detail === "EMAIL_NOT_VERIFIED") {
          throw new EmailNotVerifiedError("EmailNotVerified");
        }
      }
      console.error("Unknown error", error);
      throw error;
    });
};

const resetPassword = async ({
  token,
  password,
}: ResetPassword): Promise<AxiosResponse<null>> => {
  return await marlyApi
    .post(`/auth/reset-password`, {
      token,
      password,
    })
    .catch((error) => {
      if (isAxiosError<detailError>(error)) {
        if (error.response?.data.detail === "RESET_PASSWORD_BAD_TOKEN") {
          throw new BadTokenError("Bad token");
        }
      }
      console.error("Unknown error", error);
      throw error;
    });
};

const updateUser = async (input: UserUpdate): Promise<AxiosResponse<null>> => {
  return await marlyApi
    .post(
      `/v3/user`,
      {
        name: input.name,
        //eslint-disable-next-line camelcase
        tos_agreement: input.tosAgreement,
      },
      { withCredentials: true },
    )
    .catch((error) => {
      console.error("Unknown error", error);
      throw error;
    });
};

export interface CurrentSubscription {
  id: number;
  name: string;
  rateLimit: number;
  burstLimit: number;
  monthlyQuota: number;
  dailyQuota: number;
  type: string;
  version: string;
  createdAt: string;
  updatedAt: string;
  apiKeysLimit: number;
}

const getCurrentSubscription = async (): Promise<
  AxiosResponse<CurrentSubscription>
> => {
  return await marlyApi
    .get(`/v3/user/subscription`, {
      withCredentials: true,
    })
    .then((response) => {
      response.data = recursiveToCamelCase(response.data);
      return response;
    })
    .catch((error) => {
      // Unknown error
      console.error("Unknown getApiKeys error", error);
      throw error;
    });
};

const cancelCurrent = async (
  apiKeyToKeep: string,
): Promise<AxiosResponse<null>> => {
  return await marlyApi
    .delete(`/v4/subscription/current/${apiKeyToKeep}`, {
      withCredentials: true,
    })
    .catch((error) => {
      // Unknown error
      console.error("Unknown cancelCurrent error", error);
      throw error;
    });
};

const requestDedicatedNode = async (
  input: DedicatedNodeRequest,
): Promise<AxiosResponse<null>> => {
  return await marlyApi
    .post(
      `/v3/user/dedicated-node-request`,
      {
        chain: input.chain,
        //eslint-disable-next-line camelcase
        other_information: input.otherInformation,
      },
      {
        withCredentials: true,
      },
    )
    .catch((error) => {
      // Unknown error
      console.error("Unknown getApiKeys error", error);
      throw error;
    });
};

export default {
  getAnalyticsV4,
  getRpsAnalytics,
  getCurrentSubscription,
  cancelCurrent,
  forgotPassword,
  resetPassword,
  requestDedicatedNode,
  updateUser,
};
