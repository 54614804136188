import { useEffect, useState } from "react";
import { SquareCard } from "../square-card";
import { Loading } from "../loading";
import classes from "./card-grid.module.css";
import { Grid, Flex, TextInput } from "@mantine/core";
import { Chain } from "src/api/chain";
import { useChains } from "src/store/chain";
import { SearchIcon } from "src/ui/icons/search-icon";

export const CardGrid = ({
  onChainSelect,
}: {
  onChainSelect: (chainId: number, chainName: string) => void;
}) => {
  const [selectedChain, setSelectedChain] = useState<number | null>(null);
  const chains = useChains();
  const [filter, setFilter] = useState("");
  const [filteredChains, setFilteredChains] = useState<Chain[]>([]);

  useEffect(() => {
    if (chains.state === "fulfilled") {
      if (filter) {
        const filtered = chains.data.filter((chain) =>
          chain.name.toLowerCase().includes(filter.toLowerCase()),
        );
        setFilteredChains(filtered);
      } else {
        setFilteredChains(chains.data);
      }
    }
  }, [chains, filter]);

  const handleSelect = (chainId: number, chainName: string) => {
    setSelectedChain(chainId);
    onChainSelect(chainId, chainName);
  };

  const renderCards = (chains: Chain[]) => {
    return chains.map((chain) => (
      <Card
        key={chain.id}
        text={chain.name}
        imgSrc={chain.imageUrl}
        onSelect={() => handleSelect(chain.id, chain.name)}
        isSelected={selectedChain === chain.id}
      />
    ));
  };

  return (
    <Loading isLoading={chains.state === "loading"}>
      <Flex direction="column" align="center">
        <TextInput
          w={{ base: "80vw", sm: "55vw", lg: "70vw", xl: "80vw" }}
          value={filter}
          leftSection={<SearchIcon />}
          onChange={(e) => setFilter(e.currentTarget.value)}
          placeholder="Search chains"
          mb="md"
        />
        <Flex justify="center">
          <Grid grow={false} gutter={"lg"}>
            {filteredChains && renderCards(filteredChains)}
          </Grid>
        </Flex>
      </Flex>
    </Loading>
  );
};

type CardProps = {
  text: string;
  imgSrc?: string;
  onSelect: () => void;
  isSelected: boolean;
};

export const Card = ({ text, imgSrc, onSelect, isSelected }: CardProps) => {
  const selectedClass = isSelected ? classes.selected : "";
  return (
    <Grid.Col span={{ base: 6, sm: 4, lg: 3, xl: 2 }} onClick={onSelect}>
      <SquareCard text={text} imgSrc={imgSrc} selected={selectedClass} />
    </Grid.Col>
  );
};
