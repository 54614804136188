import { FC, useEffect } from "react";
import { User } from "src/api/marly";

export interface OutsetaSupportFormProps {
  user: User | undefined;
}

export const OutsetaSupportForm: FC<OutsetaSupportFormProps> = ({ user }) => {
  useEffect(() => {
    window.o_options.support = {
      mode: "embed",
      selector: "#support-form",
      formDefaults: {
        Email: user?.email ?? undefined,
      },
    };

    // Load the Outseta script with the defined options
    const scriptOutseta = document.createElement("script");
    scriptOutseta.src = "https://cdn.outseta.com/outseta.min.js";
    scriptOutseta.async = true;
    scriptOutseta.setAttribute("data-options", "o_options");
    document.head.appendChild(scriptOutseta);

    // Cleanup scripts on unmount
    return () => {
      document.head.removeChild(scriptOutseta);
    };
  }, [user]);
  return <div id="support-form"></div>;
};
