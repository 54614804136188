import { FC, useState } from "react";
import copy from "copy-to-clipboard";
import { Flex, ActionIcon, Box, StyleProp, Text } from "@mantine/core";
import { CopyIcon } from "src/ui/icons/copy-icon";
import { CheckIcon } from "src/ui/icons/check-icon";
import { theme } from "src/ui/theme";

interface UrlClickToCopyProps {
  id?: string;
  url: string;
  text: string;
  variant?: string;
  color?: string;
  miw?: StyleProp<string | number | undefined>;
  width?: string;
}

export const UrlClickToCopy: FC<UrlClickToCopyProps> = ({
  id,
  url,
  text,
  variant,
  color,
  miw = undefined,
  width = "300px",
}) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = (url: string) => {
    copy(url);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };

  return (
    <>
      <ActionIcon
        id={id}
        size="md"
        radius="lg"
        miw={miw}
        style={{ width: "fit-content" }}
        variant={variant || "outline"}
        color={color || theme.colors?.green?.[5]}
        onClick={() => copyToClipboard(url)}
      >
        <Flex direction="row" align="center" gap="sm" mx="sm">
          <Box
            style={{
              width, // Adjust width to fit the content and allow for scrolling
              padding: "5px", // Optional: add padding for better spacing
              overflowX: "hidden", // Default to hidden to hide scrollbars initially
              transition: "all 0.3s", // Smooth transition for hover effects
            }}
            visibleFrom="md"
            onMouseEnter={(e) => {
              // Change overflow on hover to enable scrolling
              e.currentTarget.style.overflowX = "auto";
            }}
            onMouseLeave={(e) => {
              // Revert overflow when not hovering
              e.currentTarget.style.overflowX = "hidden";
            }}
          >
            <Text c="white" lightHidden size="sm">
              {text}
            </Text>
            <Text c="black" darkHidden size="sm">
              {text}
            </Text>
          </Box>
          <Box
            style={{
              width: "150px", // Adjust width to fit the content and allow for scrolling
              padding: "5px", // Optional: add padding for better spacing
              overflowX: "hidden", // Default to hidden to hide scrollbars initially
              transition: "all 0.3s", // Smooth transition for hover effects
            }}
            hiddenFrom="md"
            onMouseEnter={(e) => {
              // Change overflow on hover to enable scrolling
              e.currentTarget.style.overflowX = "auto";
            }}
            onMouseLeave={(e) => {
              // Revert overflow when not hovering
              e.currentTarget.style.overflowX = "hidden";
            }}
          >
            <Text c="white" lightHidden size="sm">
              {text}
            </Text>
            <Text c="black" darkHidden size="sm">
              {text}
            </Text>
          </Box>
          {isCopied ? <CheckIcon /> : <CopyIcon />}
        </Flex>
      </ActionIcon>
    </>
  );
};
