import { FC } from "react";
import { BaseIconProps, Icon } from "./base/icon";

export const DwellirShieldTextIcon: FC<BaseIconProps> = (props) => {
  return (
    <Icon viewBox="0 0 885.46 626.09" width="" height="25vh" {...props}>
      <g transform="matrix(3.6138 0 0 3.6138 34.383 -189.99)">
        <g transform="matrix(.89441 0 0 -.89441 -126.32 194.42)">
          <path
            d="m304.83 68.851c-0.127 7.094-0.027 14.192-0.027 21.288h-0.036c0 7.97 2e-3 15.941-2e-3 23.911-2e-3 2.825-0.153 3.102-2.848 3.741-9.289 2.201-18.58 4.397-27.894 6.49-2.975 0.669-5.999 1.125-9.098 0.613-10.996-1.812-21.763-4.626-32.551-7.353-1.25-0.315-1.918-1.024-1.989-2.302-0.023-0.403-0.064-0.805-0.064-1.208-8e-3 -15.268 7e-3 -30.536-0.024-45.805-0.011-5.646 1.38-10.915 4.086-15.84 4.132-7.521 10.096-13.382 16.926-18.427 4.557-3.367 9.396-6.258 14.652-8.431 1.173-0.485 2.209-0.479 3.379 8e-3 4.149 1.725 8.034 3.924 11.747 6.433 6.913 4.672 13.059 10.151 17.726 17.143 3.985 5.968 6.147 12.474 6.017 19.739"
            fill="#29594b"
          />
          <path
            d="m234.56 113.72c-2e-3 -4.833-3e-3 -9.666-3e-3 -14.499 0-10.161 0-20.669-0.02-31.005-0.01-4.9 1.155-9.411 3.561-13.791 3.431-6.246 8.429-11.666 15.73-17.059 4.629-3.421 9.128-6.013 13.753-7.923 0.023-0.01 0.043-0.017 0.06-0.023 0.02 7e-3 0.043 0.015 0.071 0.027 3.632 1.51 7.231 3.482 11.002 6.031 7.24 4.893 12.66 10.12 16.575 15.984 3.678 5.51 5.413 11.174 5.303 17.311-0.089 4.977-0.067 10.015-0.046 14.887 3e-3 0.739 6e-3 1.486 9e-3 2.238h-0.027l1e-3 10.951c1e-3 5.638 1e-3 11.275-2e-3 16.913-9.746 2.309-18.559 4.387-27.432 6.381-2.399 0.54-4.967 0.982-7.48 0.567-9.933-1.636-19.926-4.17-29.59-6.619zm33.08-92.79c-1.102 0-2.204 0.227-3.298 0.678-5.27 2.179-10.358 5.103-15.552 8.94-8.208 6.063-14.135 12.538-18.122 19.795-3.071 5.59-4.622 11.609-4.609 17.889 0.021 10.329 0.022 20.832 0.022 30.99 0 4.936 0 9.873 2e-3 14.809 0 0.424 0.026 0.797 0.049 1.126 8e-3 0.105 0.015 0.21 0.021 0.315 0.174 3.092 2.111 5.4 5.182 6.176l2.608 0.66c9.835 2.494 20.006 5.071 30.293 6.766 3.956 0.652 7.638 0.032 10.718-0.66 9.043-2.032 18.003-4.147 27.94-6.502 1.479-0.35 3.504-0.83 4.854-2.639 1.253-1.679 1.254-3.562 1.255-5.223 4e-3 -5.734 3e-3 -11.468 3e-3 -17.202l-1e-3 -2.473h0.036v-4.238c0-2.174-9e-3 -4.38-0.018-6.513-0.021-4.826-0.042-9.817 0.046-14.699 0.138-7.813-2.125-15.272-6.73-22.169-4.536-6.795-10.711-12.782-18.879-18.301-4.246-2.87-8.333-5.106-12.493-6.836-1.104-0.459-2.216-0.689-3.327-0.689"
            fill="#52b196"
          />
          <g transform="translate(-1369.6 -1045.7)">
            <path
              transform="translate(1631.2 1118.1)"
              d="m0 0c-1.721 0.379-2.999-0.09-4.045-1.106-2.138-2.075-4.268-4.162-6.315-6.325-1.681-1.777-1.665-4.426-0.109-6.055 1.64-1.716 4.36-1.776 6.23-7e-3 2.224 2.105 4.376 4.287 6.516 6.478 1.077 1.102 1.628 2.411 1.081 4.289-1.067-0.965-1.931-1.816-2.87-2.576-0.818-0.663-1.718-0.603-2.477 0.122-0.774 0.739-0.763 1.627-0.161 2.462 0.618 0.86 1.311 1.665 2.15 2.718m11.675 5.343c1.554-0.502 2.816-0.058 3.876 0.97 2.259 2.192 4.534 4.373 6.693 6.662 1.818 1.926 1.703 4.514-0.097 6.213-1.685 1.593-4.18 1.577-6.034-0.17-2.23-2.102-4.4-4.271-6.546-6.461-1.076-1.099-1.594-2.406-1.004-4.053 1.102 0.486 1.668 1.304 2.305 2.039 0.852 0.985 1.892 0.989 2.829 0.307 1.08-0.787 0.944-1.833 0.187-2.829-0.685-0.9-1.791-1.46-2.209-2.678m-12.355-1.117c1.123 0.054 2.201-0.053 3.167-0.601 0.796-0.453 1.367-0.235 1.961 0.375 0.609 0.624 1.061 1.15 0.668 2.152-1.561 3.988-0.373 7.251 2.692 10.067 2.076 1.907 3.93 4.057 6.027 5.939 3.014 2.703 7.432 2.728 10.523 0.231 3.012-2.433 3.856-6.701 2.11-10.142-0.756-1.491-2.25-2.164-3.283-3.337-1.57-1.785-3.137-3.58-4.831-5.243-2.398-2.353-5.295-3.059-8.482-1.918-1.047 0.374-1.759 0.523-2.651-0.359-0.853-0.844-0.591-1.507-0.27-2.427 1.146-3.283 0.459-6.217-1.974-8.704-2.147-2.194-4.294-4.389-6.51-6.512-3.369-3.229-8.197-3.276-11.446-0.186-3.209 3.051-3.427 8.066-0.331 11.449 2.293 2.507 4.746 4.873 7.209 7.216 1.494 1.42 3.334 2.152 5.421 2"
              fill="#52b196"
            />
          </g>
        </g>
        <g transform="matrix(.29104 0 0 -.29104 169.36 190.75)" fill="#52b196">
          <path d="m0 0c0-2.409-0.606-4-1.91-5.008-1.477-1.143-3.477-1.723-5.945-1.723h-9.401v13.673h8.839c2.744 0 4.907-0.601 6.431-1.788 1.355-1.056 1.986-2.694 1.986-5.154m2.328-14.236c1.931 1.05 3.582 2.439 4.925 4.144 2.106 2.677 3.175 6.12 3.175 10.232 0 5.398-1.751 9.611-5.205 12.522-3.319 2.797-7.884 4.216-13.57 4.216h-19.197v-47.527h10.288v13.982h8.581l10.891-13.982h9.125v4.798z" />
          <path
            transform="matrix(1.2121 0 0 1.2121 -2531.1 -1366)"
            d="m2034.9 1101.7h8.4877v39.21h-8.4877z"
          />
          <path
            transform="translate(-113.1 16.878)"
            d="m0 0h-10.287v-47.527h32.21v9.935h-21.923z"
          />
          <path
            transform="translate(-171.98 16.878)"
            d="m0 0h-10.288v-47.527h32.21v9.935h-21.922z"
          />
          <path
            transform="translate(-243.89 -30.649)"
            d="m0 0h34.95v9.936h-24.662v9.105h21.079v9.937h-21.079v8.614h24.662v9.935h-34.95z"
          />
          <path
            transform="translate(-289.14 -10.649)"
            d="m0 0-7.259 27.527h-8.91l-7.315-27.527-8.375 27.527h-10.773l15.842-47.527h7.858l7.216 26.894 7.216-26.894h7.858l15.842 47.527h-10.768z"
          />
          <path
            transform="translate(-368.18 -6.8853)"
            d="m0 0c0-2.257-0.357-4.264-1.061-5.965-0.692-1.672-1.657-3.097-2.868-4.233-1.231-1.153-2.71-2.052-4.396-2.671-1.732-0.636-3.654-0.96-5.713-0.96h-6.169v27.657h6.169c2.057 0 3.978-0.324 5.713-0.96 1.688-0.622 3.167-1.521 4.395-2.67 1.209-1.135 2.173-2.568 2.865-4.26 0.707-1.727 1.065-3.725 1.065-5.938m3.646 17.309c-2.135 2.082-4.732 3.7-7.72 4.81-2.935 1.091-6.194 1.644-9.683 1.644h-16.738v-47.527h16.738c3.489 0 6.747 0.553 9.683 1.644 2.987 1.11 5.585 2.728 7.72 4.81 2.137 2.086 3.812 4.634 4.977 7.573 1.15 2.904 1.734 6.181 1.734 9.737s-0.584 6.832-1.734 9.736c-1.165 2.939-2.84 5.487-4.977 7.573"
          />
        </g>
      </g>
    </Icon>
  );
};
