import { Navigate, useParams } from "react-router-dom";
import { useCheckoutStatus } from "src/store/checkout";

// TODO Do we need this page still?
export const Return = () => {
  const { sessionId } = useParams();
  const sessionStatus = useCheckoutStatus(sessionId);

  if (sessionStatus.state === "loading") {
    return <>Loading</>;
  }

  if (
    sessionStatus.state === "fulfilled" &&
    sessionStatus.data.status === "open"
  ) {
    return <Navigate to="/checkout" />;
  }

  if (
    sessionStatus.state === "fulfilled" &&
    sessionStatus.data.status === "complete"
  ) {
    return (
      <section id="success">
        <p>
          We appreciate your business! A confirmation email will be sent to{" "}
          {sessionStatus.data.customerEmail}. If you have any questions, please
          email <a href="mailto:orders@example.com">orders@example.com</a>.
        </p>
      </section>
    );
  }

  return null;
};
