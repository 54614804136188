export const DwellirLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="190"
      height="40"
      version="1.1"
      fill="none"
      viewBox="0 0 250 50"
    >
      <defs>
        <clipPath id="clipPath100" clipPathUnits="userSpaceOnUse">
          <path d="M1615.27 1276.66h42.88v-42.94h-42.88z"></path>
        </clipPath>
      </defs>
      <g transform="translate(-6.776 -62.868)">
        <g>
          <g transform="matrix(.35278 0 0 -.35278 63.437 81.991)">
            <path
              fill="#52b196"
              fillOpacity="1"
              fillRule="nonzero"
              stroke="none"
              d="M0 0c0-2.257-.357-4.264-1.061-5.965-.692-1.672-1.657-3.097-2.868-4.233-1.231-1.153-2.71-2.052-4.396-2.671-1.732-.636-3.654-.96-5.713-.96h-6.169v27.657h6.169c2.057 0 3.978-.324 5.713-.96 1.688-.622 3.167-1.521 4.395-2.67 1.209-1.135 2.173-2.568 2.865-4.26C-.358 4.211 0 2.213 0 0m3.646 17.309c-2.135 2.082-4.732 3.7-7.72 4.81-2.935 1.091-6.194 1.644-9.683 1.644h-16.738v-47.527h16.738c3.489 0 6.747.553 9.683 1.644 2.987 1.11 5.585 2.728 7.72 4.81 2.137 2.086 3.812 4.634 4.977 7.573C9.773-6.833 10.357-3.556 10.357 0s-.584 6.832-1.734 9.736c-1.165 2.939-2.84 5.487-4.977 7.573"
            ></path>
          </g>
          <g transform="matrix(.35278 0 0 -.35278 91.32 83.32)">
            <path
              fill="#52b196"
              fillOpacity="1"
              fillRule="nonzero"
              stroke="none"
              d="M0 0l-7.259 27.527h-8.91L-23.484 0l-8.375 27.527h-10.773L-26.79-20h7.858l7.216 26.894L-4.5-20h7.858L19.2 27.527H8.432z"
            ></path>
          </g>
          <g transform="matrix(.35278 0 0 -.35278 107.284 90.375)">
            <path
              fill="#52b196"
              fillOpacity="1"
              fillRule="nonzero"
              stroke="none"
              d="M0 0h34.95v9.936H10.288v9.105h21.079v9.937H10.288v8.614H34.95v9.935H0z"
            ></path>
          </g>
          <g transform="matrix(.35278 0 0 -.35278 132.652 73.608)">
            <path
              fill="#52b196"
              fillOpacity="1"
              fillRule="nonzero"
              stroke="none"
              d="M0 0h-10.288v-47.527h32.21v9.935H0z"
            ></path>
          </g>
          <g transform="matrix(.35278 0 0 -.35278 153.424 73.608)">
            <path
              fill="#52b196"
              fillOpacity="1"
              fillRule="nonzero"
              stroke="none"
              d="M0 0h-10.287v-47.527h32.21v9.935H0z"
            ></path>
          </g>
          <path
            fill="#52b196"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            strokeWidth="0.353"
            d="M170.567 90.375h3.63V73.608h-3.63z"
          ></path>
          <g transform="matrix(.35278 0 0 -.35278 193.323 79.562)">
            <path
              fill="#52b196"
              fillOpacity="1"
              fillRule="nonzero"
              stroke="none"
              d="M0 0c0-2.409-.606-4-1.91-5.008-1.477-1.143-3.477-1.723-5.945-1.723h-9.401V6.942h8.839c2.744 0 4.907-.601 6.431-1.788C-.631 4.098 0 2.46 0 0m2.328-14.236a15.862 15.862 0 014.925 4.144C9.359-7.415 10.428-3.972 10.428.14c0 5.398-1.751 9.611-5.205 12.522-3.319 2.797-7.884 4.216-13.57 4.216h-19.197v-47.527h10.288v13.982h8.581L2.216-30.649h9.125v4.798z"
            ></path>
          </g>
          <g transform="matrix(.35278 0 0 -.35278 34.565 84.211)">
            <path
              fill="#29594b"
              fillOpacity="1"
              fillRule="nonzero"
              stroke="none"
              d="M0 0c-.127 7.094-.027 14.192-.027 21.288h-.036c0 7.97.002 15.941-.002 23.911-.002 2.825-.153 3.102-2.848 3.741-9.289 2.201-18.58 4.397-27.894 6.49-2.975.669-5.999 1.125-9.098.613-10.996-1.812-21.763-4.626-32.551-7.353-1.25-.315-1.918-1.024-1.989-2.302-.023-.403-.064-.805-.064-1.208-.008-15.268.007-30.536-.024-45.805-.011-5.646 1.38-10.915 4.086-15.84 4.132-7.521 10.096-13.382 16.926-18.427 4.557-3.367 9.396-6.258 14.652-8.431 1.173-.485 2.209-.479 3.379.008 4.149 1.725 8.034 3.924 11.747 6.433 6.913 4.672 13.059 10.151 17.726 17.143C-2.032-13.771.13-7.265 0 0"
            ></path>
          </g>
          <g transform="matrix(.35278 0 0 -.35278 9.775 68.382)">
            <path
              fill="#52b196"
              fillOpacity="1"
              fillRule="nonzero"
              stroke="none"
              d="M0 0l-.003-14.499c0-10.161 0-20.669-.02-31.005-.01-4.9 1.155-9.411 3.561-13.791 3.431-6.246 8.429-11.666 15.73-17.059 4.629-3.421 9.128-6.013 13.753-7.923.023-.01.043-.017.06-.023.02.007.043.015.071.027 3.632 1.51 7.231 3.482 11.002 6.031 7.24 4.893 12.66 10.12 16.575 15.984 3.678 5.51 5.413 11.174 5.303 17.311-.089 4.977-.067 10.015-.046 14.887l.009 2.238h-.027l.001 10.951c.001 5.638.001 11.275-.002 16.913-9.746 2.309-18.559 4.387-27.432 6.381-2.399.54-4.967.982-7.48.567C21.122 5.354 11.129 2.82 1.465.371zm33.08-92.79c-1.102 0-2.204.227-3.298.678-5.27 2.179-10.358 5.103-15.552 8.94C6.022-77.109.095-70.634-3.892-63.377c-3.071 5.59-4.622 11.609-4.609 17.889.021 10.329.022 20.832.022 30.99 0 4.936 0 9.873.002 14.809 0 .424.026.797.049 1.126l.021.315c.174 3.092 2.111 5.4 5.182 6.176l2.608.66c9.835 2.494 20.006 5.071 30.293 6.766 3.956.652 7.638.032 10.718-.66 9.043-2.032 18.003-4.147 27.94-6.502 1.479-.35 3.504-.83 4.854-2.639 1.253-1.679 1.254-3.562 1.255-5.223.004-5.734.003-11.468.003-17.202l-.001-2.473h.036v-4.238c0-2.174-.009-4.38-.018-6.513-.021-4.826-.042-9.817.046-14.699.138-7.813-2.125-15.272-6.73-22.169-4.536-6.795-10.711-12.782-18.879-18.301-4.246-2.87-8.333-5.106-12.493-6.836-1.104-.459-2.216-.689-3.327-.689"
            ></path>
          </g>
          <g transform="matrix(.35278 0 0 -.35278 -556.086 522.936)">
            <g>
              <g clipPath="url(#clipPath100)">
                <g transform="translate(1630.782 1252.44)">
                  <path
                    fill="#52b196"
                    fillOpacity="1"
                    fillRule="nonzero"
                    stroke="none"
                    d="M0 0c-1.721.379-2.999-.09-4.045-1.106-2.138-2.075-4.268-4.162-6.315-6.325-1.681-1.777-1.665-4.426-.109-6.055 1.64-1.716 4.36-1.776 6.23-.007 2.224 2.105 4.376 4.287 6.516 6.478 1.077 1.102 1.628 2.411 1.081 4.289-1.067-.965-1.931-1.816-2.87-2.576-.818-.663-1.718-.603-2.477.122-.774.739-.763 1.627-.161 2.462C-1.532-1.858-.839-1.053 0 0m11.675 5.343c1.554-.502 2.816-.058 3.876.97 2.259 2.192 4.534 4.373 6.693 6.662 1.818 1.926 1.703 4.514-.097 6.213-1.685 1.593-4.18 1.577-6.034-.17-2.23-2.102-4.4-4.271-6.546-6.461-1.076-1.099-1.594-2.406-1.004-4.053 1.102.486 1.668 1.304 2.305 2.039.852.985 1.892.989 2.829.307 1.08-.787.944-1.833.187-2.829-.685-.9-1.791-1.46-2.209-2.678M-.68 4.226c1.123.054 2.201-.053 3.167-.601.796-.453 1.367-.235 1.961.375.609.624 1.061 1.15.668 2.152-1.561 3.988-.373 7.251 2.692 10.067 2.076 1.907 3.93 4.057 6.027 5.939 3.014 2.703 7.432 2.728 10.523.231 3.012-2.433 3.856-6.701 2.11-10.142-.756-1.491-2.25-2.164-3.283-3.337-1.57-1.785-3.137-3.58-4.831-5.243-2.398-2.353-5.295-3.059-8.482-1.918-1.047.374-1.759.523-2.651-.359-.853-.844-.591-1.507-.27-2.427 1.146-3.283.459-6.217-1.974-8.704-2.147-2.194-4.294-4.389-6.51-6.512-3.369-3.229-8.197-3.276-11.446-.186-3.209 3.051-3.427 8.066-.331 11.449 2.293 2.507 4.746 4.873 7.209 7.216 1.494 1.42 3.334 2.152 5.421 2"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
