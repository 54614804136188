import { AxiosResponse } from "axios";
import { marlyApi } from "./marly";
import { recursiveToCamelCase } from "src/utility/recursive-to-camel-case";

export interface InvoiceItem {
  amount: number; // in cents
  currency: string;
  description: string;
  quantity: number;
  price_per_unit: number;
}

export interface Invoice {
  type: string;
  version: string;
  total: number; // in cents
  currency: string;
  dueDate: string;
  lines: InvoiceItem[];
}

interface InvoiceApi {
  type: string;
  version: string;
  total: number; // in cents
  currency: string;
  due_date: string;
  lines: InvoiceItem[];
}

export interface Checkout {
  clientSecret: string;
  type: string;
  amount: number;
  tax: number;
  url: string;
}

export interface PaymentMethodCard {
  displayBrand: string;
  last4: string;
  expirationMonth: number;
  expirationYear: number;
  default: boolean;
  stripeId: string;
}

export interface CancelChecks {
  tooManyKeys: boolean;
  tooMuchDailyTraffic: boolean;
  tooMuchMonthlyTraffic: boolean;
}

export type CreateCheckout = {
  lookupKey: string;
  yearly: boolean;
  paymentMethodId: string | undefined;
};

const getPaymentMethods = async (): Promise<
  AxiosResponse<PaymentMethodCard[]>
> => {
  return await marlyApi
    .get("/v4/user/payment_method", {
      withCredentials: true,
    })
    .then((response) => {
      response.data = recursiveToCamelCase(response.data);
      return response;
    });
};

const createCheckout = async ({
  lookupKey,
  yearly,
  paymentMethodId,
}: CreateCheckout): Promise<AxiosResponse<Checkout>> => {
  const location = document.location;
  const redirectUrl = `${location.protocol}//${location.host}/usage-billing/billing`;
  return await marlyApi
    .get(
      `/subscriptions/checkout/${lookupKey}?yearly=${yearly}&pm=${paymentMethodId}`,
      {
        withCredentials: true,
        headers: { "x-dwellir-redirect-url": redirectUrl },
      },
    )
    .catch((error) => {
      // Unknown error
      console.error("Unknown createCheckout error", error);
      throw error;
    });
};

const getCheckoutStatus = async (
  sessionId: string,
): Promise<AxiosResponse<{ status: string; customer_email: string }>> => {
  return await marlyApi
    .get(`/subscriptions/checkout/status/${sessionId}`, {
      withCredentials: true,
    })
    .catch((error) => {
      // Unknown error
      console.error("Unknown getCheckoutStatus error", error);
      throw error;
    });
};

const subscriptionCancelChecks = async (
  newSubId: number,
): Promise<AxiosResponse<CancelChecks>> => {
  return await marlyApi
    .get(`/v4/subscription/current/cancel_check/${newSubId}`, {
      withCredentials: true,
    })
    .then((response) => {
      response.data = recursiveToCamelCase(response.data);
      return response;
    })
    .catch((error) => {
      // Unknown error
      console.error("Unknown cancelCurrent error", error);
      throw error;
    });
};

const upcomingInvoice = async (): Promise<AxiosResponse<Invoice>> => {
  return await marlyApi
    .get(`/v3/invoice/upcoming`, {
      withCredentials: true,
    })
    .then((res) => {
      const api = res.data as InvoiceApi;
      res.data = {
        type: api.type,
        version: api.version,
        total: api.total,
        currency: api.currency,
        dueDate: api.due_date,
        lines: api.lines,
      };
      return res;
    })
    .catch((error) => {
      // Unknown error
      console.error("Unknown upcomingInvoice error", error);
      throw error;
    });
};

export default {
  getPaymentMethods,
  createCheckout,
  getCheckoutStatus,
  subscriptionCancelChecks,
  upcomingInvoice,
};
