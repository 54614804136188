import { Chain } from "src/api/chain";

export const chainsSort = (a: Chain, b: Chain, apiKey: string) => {
  const aHasFav = a.networks.some((network) =>
    network.favoritedBy.includes(apiKey),
  );
  const bHasFav = b.networks.some((network) =>
    network.favoritedBy.includes(apiKey),
  );

  if (aHasFav === bHasFav) {
    // Do a name compare if the value is the same
    return a.name.localeCompare(b.name);
  } else {
    // Sort chains with favorites first (true before false)
    return aHasFav ? -1 : 1;
  }
};
