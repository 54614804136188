import { useEffect, useRef, useState } from "react";
import { Loading } from "../loading";
import { Button } from "../buttons";
import { SettingsCard } from "../settings-card";
import { TextLink } from "../text-link";
import classes from "./plan-picker.module.css";
import {
  Box,
  Card,
  Center,
  CheckIcon,
  Checkbox,
  Flex,
  Grid,
  Modal,
  ModalProps,
  Paper,
  Pill,
  Space,
  Text,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import { grayFontColor, theme } from "src/ui/theme";
import { usePlans } from "src/store/subscription";
import { Checkout } from "src/ui/components/stripe";
import {
  reloadCurrentSubscription,
  useCurrentSubscription,
} from "src/store/user";
import { SubscriptionPlan } from "src/api/subscription_plan";
import { useAppDispatch, useAppSelector } from "src/core/hooks";
import {
  createCheckout,
  reset,
  selectCreateCheckout,
  usePaymentMethods,
} from "src/store/checkout";
import { OfficeIcon } from "src/ui/icons/office-icon";
import { UserIcon } from "src/ui/icons/user-icon";
import Confetti from "react-confetti";
import {
  updateOrganizationInformation,
  useOrganizationInformation,
} from "src/store/organization";

type PlanPickerCardProps = {
  title: string;
  price: string;
  extraPrice?: string;
  includedDesc: string;
  extraDesc: string;
  fontColor?: string;
  includedList: { item: string; extra?: string }[];
  currentPlan?: string;
  onClick?: () => void;
};

const PlanPickerCard = ({
  title,
  price,
  extraPrice,
  includedDesc,
  extraDesc,
  fontColor,
  includedList,
  currentPlan,
  onClick,
}: PlanPickerCardProps) => {
  const color = fontColor ?? theme.colors?.gray?.[6];

  const styles = currentPlan
    ? { root: { backgroundColor: "second-background" } }
    : {};

  return (
    <Paper
      shadow="lg"
      p="xl"
      radius="md"
      h="100%"
      withBorder
      styles={styles}
      className={currentPlan ? "" : classes["plan-picker-card"]}
      onClick={onClick}
    >
      <Text fw={700} size="24px" mb="lg" c={color}>
        {title}
        {currentPlan && (
          <Pill
            ml="md"
            c={grayFontColor}
            styles={{
              root: { border: "1px solid" + theme.colors?.gray?.[4] },
            }}
          >
            {currentPlan}
          </Pill>
        )}
      </Text>
      <Text size="36px" fw={700} mb="md" c={color}>
        {price}
        {price !== "Free" && (
          <Text span size="sm">
            {" "}
            + taxes
          </Text>
        )}
        {extraPrice && (
          <span
            style={{
              color: grayFontColor,
              fontSize: "16px",
              marginLeft: "5px",
            }}
          >
            {extraPrice}
          </span>
        )}
      </Text>
      <Text>{includedDesc}</Text>
      <Text
        style={{
          borderBottom: "1px solid" + theme.colors?.gray?.[4],
          paddingBottom: fontColor ? "20px" : "25px",
        }}
        size="sm"
        c={grayFontColor}
      >
        {extraDesc}
      </Text>
      <Space mt="lg">
        {includedList.map((item) => {
          return (
            <Flex key={item.item} align="center" mt="sm">
              <CheckIcon size={16} />
              <Text ml="sm">
                {item.item}
                {item.extra && (
                  <span style={{ marginLeft: "5px", color: grayFontColor }}>
                    {item.extra}
                  </span>
                )}
              </Text>
            </Flex>
          );
        })}
      </Space>
    </Paper>
  );
};

type PlanPickerProps = {
  opened: boolean;
  startState: "success" | "canceled" | undefined;
  onClose: () => void;
  onCancel: () => void;
} & ModalProps;

export const PlanPicker = ({
  opened,
  startState,
  onClose,
  onCancel,
}: PlanPickerProps) => {
  const { t } = useTranslation();
  const [state, setState] = useState<
    | "business"
    | "billingInformation"
    | "picker"
    | "stripe"
    | "success"
    | "canceled"
  >(startState == null ? "picker" : startState);
  const plans = usePlans();
  const subscription = useCurrentSubscription();
  // Fake track currentSubscription, backend needs to be updated to wait
  // until the subscription changes before returning...
  const [currentSubscription, setCurrentSubscription] = useState<number | null>(
    null,
  );
  const dispatch = useAppDispatch();
  const [lookupKey, setLookupKey] = useState("");
  const [clickedPlan, setClickedPlan] = useState<{
    id: number;
    name: string;
    amount: number;
    currency: string;
    monthlyQuota: number;
  } | null>(null);
  const organizationInformation = useOrganizationInformation();
  const successBoxRef = useRef<HTMLDivElement | null>(null);
  const createCheckoutRequest = useAppSelector(selectCreateCheckout);
  const [hasRedirected, setHasRedirected] = useState(false);
  const paymentMethods = usePaymentMethods();
  const [yearly, setYearly] = useState(false);

  useEffect(() => {
    startState && setState(startState);
  }, [startState]);

  useEffect(() => {
    if (createCheckoutRequest.state === "fulfilled" && !hasRedirected) {
      setHasRedirected(true);
      window.location.href = createCheckoutRequest.data.url;
    }
  }, [createCheckoutRequest, hasRedirected]);

  // const nextState = () => {
  //   if (clickedPlan == null) {
  //     setState("picker");
  //   }

  //   if (state === "picker") {
  //     if (organizationInformation?.isBusiness === false) {
  //       setState("stripe");
  //     } else if (
  //       organizationInformation?.isBusiness === true &&
  //       validStripeBilling(organizationInformation)
  //     ) {
  //       setState("stripe");
  //     } else {
  //       setState("business");
  //     }
  //   }
  // };

  // const validStripeBilling = (
  //   information: OrganizationInformation,
  // ): boolean => {
  //   if (information.billingCountry && information.taxId) {
  //     const valid = stripeTaxUtil.getStripeType({
  //       country: information.billingCountry || "",
  //       taxId: information.taxId || "",
  //     });
  //     if (valid) {
  //       return true;
  //     }
  //   }
  //   return false;
  // };

  useEffect(() => {
    if (subscription.state === "fulfilled" && currentSubscription == null) {
      setCurrentSubscription(subscription.data.id);
    }
  }, [subscription, currentSubscription]);

  const renderCard = (plan: SubscriptionPlan | undefined) => {
    if (plan == null || subscription.state !== "fulfilled") {
      return null;
    }
    const lookupKey = plan.lookup_key ?? "free_plan";

    let price = 0;
    if (lookupKey != "free_plan") {
      price = plan.price / 100;
    }
    if (yearly) {
      price = price * 12 * 0.7;
    }

    const isCurrent = currentSubscription === plan.id;
    const canClick = !isCurrent;
    const isFree = lookupKey === "free_plan";

    const onClick = () => {
      if (canClick) {
        setClickedPlan({
          id: plan.id,
          name: plan.name,
          amount: plan.price,
          currency: "usd",
          monthlyQuota: plan.monthly_quota,
        });
        if (isFree) {
          onClose();
          onCancel();
        } else {
          setLookupKey(lookupKey);
          if (
            paymentMethods.state === "fulfilled" &&
            paymentMethods.data.length > 0
          ) {
            // Returning user with registered paymentMethods (credit cards)
            setState("stripe");
          } else {
            // New user, show stripe payment page
            dispatch(
              createCheckout({
                lookupKey,
                yearly,
                paymentMethodId: undefined,
              }),
            );
          }
        }
      }
    };

    return (
      <Loading
        isLoading={
          createCheckoutRequest.state === "loading" &&
          clickedPlan?.id === plan.id
        }
      >
        <PlanPickerCard
          title={t(`plan.modal.${lookupKey}.title`)}
          price={t(`plan.modal.${lookupKey}.price`, { price })}
          includedDesc={t(`plan.modal.${lookupKey}.included_desc`)}
          extraDesc={t(`plan.modal.${lookupKey}.extra_desc`)}
          includedList={plan.features.map((f) => {
            if (f.endsWith("_rps")) {
              const [x, _] = f.split("_rps");
              return { item: t(`plan.modal.features.x_rps`, { x }) };
            }
            if (f.endsWith("_rps_plus")) {
              const [x, _] = f.split("_rps_plus");
              return { item: t(`plan.modal.features.x_rps`, { x }) };
            }
            if (f.endsWith("_chains_plus")) {
              const [x, _] = f.split("_chains_plus");
              return { item: t(`plan.modal.features.x_chains_plus`, { x }) };
            }
            return { item: t(`plan.modal.features.${f}`) };
          })}
          currentPlan={isCurrent ? t(`plan.modal.current_plan`) : undefined}
          onClick={onClick}
        />
      </Loading>
    );
  };

  const realOnClose = () => {
    dispatch(reset());
    setState("picker");
    onClose();
  };

  return (
    <Modal
      opened={opened}
      onClose={realOnClose}
      size="auto"
      radius="lg"
      centered
    >
      {state == "picker" && (
        <Loading
          isLoading={
            plans.state === "loading" || paymentMethods.state === "loading"
          }
        >
          {paymentMethods.state === "fulfilled" &&
            paymentMethods.data.length == 0 && (
              <Center>
                <Card shadow="sm" padding="lg" radius="md" withBorder mb="md">
                  <Checkbox
                    label="I want to pay yearly and get 30% off!"
                    onChange={(event) => setYearly(event.currentTarget.checked)}
                  />
                </Card>
              </Center>
            )}
          <Grid m="lg">
            <Grid.Col span={{ base: 12, sm: 3 }}>
              {plans.state === "fulfilled" &&
                renderCard(plans.data.find((p) => p.lookup_key == null))}
            </Grid.Col>
            <Grid.Col span={{ base: 12, sm: 3 }}>
              {plans.state === "fulfilled" &&
                renderCard(
                  plans.data.find((p) => p.lookup_key == "developer_plan"),
                )}
            </Grid.Col>
            <Grid.Col span={{ base: 12, sm: 3 }}>
              {plans.state === "fulfilled" &&
                renderCard(
                  plans.data.find((p) => p.lookup_key == "growth_plan"),
                )}
            </Grid.Col>
            <Grid.Col span={{ base: 12, sm: 3 }}>
              {plans.state === "fulfilled" &&
                renderCard(
                  plans.data.find((p) => p.lookup_key == "scale_plan"),
                )}
            </Grid.Col>
          </Grid>
        </Loading>
      )}
      {state == "business" && (
        <Box mih="35rem" miw="45rem">
          <Flex direction="column" h="20rem" gap="md" align="center">
            <Text fw={700}>Do you represent a business?</Text>
            <Flex direction="row" w="12rem" justify="space-between">
              <Button
                text="Yes"
                icon={<OfficeIcon fillColor="white" />}
                onClick={() => setState("billingInformation")}
              />
              <Button
                text="No"
                icon={<UserIcon fillColor="white" />}
                onClick={() => {
                  if (organizationInformation) {
                    dispatch(
                      updateOrganizationInformation({
                        ...organizationInformation,
                        isBusiness: false,
                      }),
                    );
                  }
                  setState("stripe");
                }}
              />
            </Flex>
          </Flex>
        </Box>
      )}
      {state == "billingInformation" && (
        <Box mih="35rem" miw="45rem">
          <Flex direction="column" h="20rem" gap="md" align="center">
            <Text fw={700}>We need to collect your tax information</Text>
            {/* TODO FIX THIS <BillingInformationForm />*/}
            <Flex direction="row" justify="flex-end">
              {organizationInformation && (
                <Flex direction="column" gap="md">
                  <SettingsCard
                    information={organizationInformation}
                    requiredOnly
                    saveLabel="Continue"
                    onSaved={() => {
                      setState("stripe");
                    }}
                  />
                </Flex>
              )}
            </Flex>
          </Flex>
        </Box>
      )}
      {state == "stripe" && clickedPlan && (
        <Flex direction="row" justify="center">
          <Checkout
            lookupKey={lookupKey}
            onComplete={() => {
              // Ideally the backend would communicate this change to us via a
              // websocket once the webhook has been processed.
              setCurrentSubscription(clickedPlan.id);
              dispatch(reloadCurrentSubscription());
              setState("success");
            }}
            onCancel={() => {
              setState("picker");
            }}
            plan={
              plans.state === "fulfilled" &&
              renderCard(plans.data.find((p) => p.id === clickedPlan.id))
            }
            planData={clickedPlan}
          />
        </Flex>
      )}
      {state == "success" && (
        <Box mih="35rem" miw="45rem">
          <Confetti
            height={successBoxRef.current?.getBoundingClientRect().height ?? 0}
            width={successBoxRef.current?.getBoundingClientRect().width ?? 0}
          />
          <Flex
            direction="column"
            h="50vw"
            justify="center"
            align="center"
            ref={successBoxRef}
          >
            <Text fw={700} size="lg">
              Success!
            </Text>
            <Text size="md">Your new plan is now in use</Text>
          </Flex>
        </Box>
      )}
      {state == "canceled" && (
        <Box mih="35rem" miw="45rem">
          <Flex direction="column" h="20rem" gap="md" align="center">
            <Text fw={700}>Did something go wrong?</Text>
            <Text fw={700}>
              Feel free to try again or reach out to{" "}
              <TextLink target="mailto:support@dwellir.com">
                support@dwellir.com
              </TextLink>{" "}
              for assistance.
            </Text>
          </Flex>
        </Box>
      )}
    </Modal>
  );
};
