import { RootPanel } from "../components";
import { Loading } from "../components/loading";
import { OutsetaLeadCapture } from "../components/outseta/outseta-lead-capture";
import { Flex, Paper, Text } from "@mantine/core";
import { useUser } from "src/store/user";

export const DedicatedNodes = () => {
  const user = useUser();

  return (
    <RootPanel withNavbar>
      <Paper
        shadow="lg"
        p="xl"
        radius="md"
        mx={{ base: "sm", md: "sm", lg: "25vw", xl: "25vw" }}
        mt="lg"
        withBorder
      >
        <Flex direction="column" gap="md">
          <Text>Request a dedicated node</Text>

          <Text>
            Use this form to request a dedicated node for a specific chain. We
            are able to run dedicated nodes for most chains even if you are not
            the developer of the chain itself.
          </Text>

          <Loading isLoading={user.state === "loading"}>
            <Flex direction="column" gap="md">
              <OutsetaLeadCapture />
            </Flex>
          </Loading>
        </Flex>
      </Paper>
    </RootPanel>
  );
};
