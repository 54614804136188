import { FC, useState } from "react";
import { SubmitButton } from "../buttons";
import {
  Flex,
  Modal,
  Text,
  TextInput,
  NumberInput,
  Box,
  Switch,
} from "@mantine/core";

import { CreateApiKeyInput } from "src/api/api_keys";
import { useForm } from "@mantine/form";
import { SaveIcon } from "src/ui/icons/save-icon";
import { CurrentSubscription } from "src/api/user";

interface CreateKeyModalProps {
  open: boolean;
  subscription: CurrentSubscription;
  onCreate(input: CreateApiKeyInput): void;
  onClose: () => void;
}

export const CreateKeyModal: FC<CreateKeyModalProps> = ({
  open,
  subscription,
  onCreate,
  onClose,
}) => {
  const [customDailyQuota, setCustomDailyQuota] = useState(false);
  const [customMontlyQuota, setCustomMonthlyQuota] = useState(false);

  const form = useForm({
    initialValues: {
      name: "",
      dailyQuota: "",
      monthlyQuota: "",
    },
  });

  const realClose = () => {
    setCustomDailyQuota(false);
    setCustomMonthlyQuota(false);
    form.reset();
    onClose();
  };

  const handleSubmit = () => {
    let daily = undefined;
    if (customDailyQuota && form.values.dailyQuota !== "") {
      daily = Number(form.values.dailyQuota);
    }
    let monthly = undefined;
    if (customMontlyQuota && form.values.monthlyQuota !== "") {
      monthly = Number(form.values.monthlyQuota);
    }
    onCreate({
      name: form.values.name,
      dailyQuota: daily,
      monthlyQuota: monthly,
    });
    realClose();
  };

  const quotasEnabled = subscription.apiKeysLimit > 1;

  const title = "Create an API key";
  const submitLabel = "Create Api Key";

  return (
    <Modal
      opened={open}
      onClose={realClose}
      title={
        <Box>
          <Text>{title}</Text>
        </Box>
      }
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Flex direction="column" gap="md">
          <TextInput
            label="Name"
            size="md"
            required
            {...form.getInputProps("name")}
          />
          {!quotasEnabled && (
            <Text size="sm" c="dimmed" fw={400}>
              Your current subscription plan does not support multiple keys.
              Custom quotas are disabled.
            </Text>
          )}
          <Switch
            label="Set a daily quota for this key"
            checked={customDailyQuota}
            disabled={!quotasEnabled}
            onChange={(event) => {
              setCustomDailyQuota(event.currentTarget.checked);
            }}
          />
          {customDailyQuota && (
            <NumberInput
              description="Block this key after this many requests in a day"
              size="md"
              thousandSeparator=" "
              hideControls
              {...form.getInputProps("dailyQuota")}
            />
          )}
          <Switch
            label="Set a montly quota for this key"
            checked={customMontlyQuota}
            disabled={!quotasEnabled}
            onChange={(event) => {
              setCustomMonthlyQuota(event.currentTarget.checked);
            }}
          />
          {customMontlyQuota && (
            <NumberInput
              description="Block this key after this many requests in a month"
              size="md"
              thousandSeparator=" "
              hideControls
              {...form.getInputProps("monthlyQuota")}
            />
          )}
          <SubmitButton text={submitLabel} icon={<SaveIcon />} />
        </Flex>
      </form>
    </Modal>
  );
};
