import { ReactNode } from "react";
import {
  ButtonProps as MantineButtonProps,
  Button as MantineButton,
  Space,
} from "@mantine/core";
import { theme } from "src/ui/theme";

type ButtonProps = {
  id?: string;
  text: string;
  onClick?: () => void;
  disabled?: boolean;
  icon?: ReactNode;
} & MantineButtonProps;

export const DangerButton = ({
  id,
  text,
  styles,
  onClick,
  disabled = false,
  icon,
  ...mantineProps
}: ButtonProps) => {
  return (
    <MantineButton
      id={id}
      styles={styles}
      color={theme.colors?.red?.[5]}
      variant={mantineProps.variant ? mantineProps.variant : "filled"}
      data-disabled={disabled}
      onClick={(event) => {
        event.preventDefault();
        onClick && !disabled && onClick();
      }}
      {...mantineProps}
    >
      {icon && <Space pr={"xs"}>{icon}</Space>}
      {text}
    </MantineButton>
  );
};
