import { FC, useEffect, useState } from "react";
import { HasDataPartition } from "../usage-viewer";
import { Group, Select, Image } from "@mantine/core";
import { Chain } from "src/api/chain";

interface SelectChainProps {
  chains: HasDataPartition<Chain>;
  onSelect: (chain: Chain) => void;
  allowAllOption?: boolean;
}

export const ALL_CHAINS = {
  id: -1,
  name: "All chains",
  imageUrl: "https://www.dwellir.com/partners/dwellir_circle.svg",
  networks: [],
};

export const SelectChain: FC<SelectChainProps> = ({
  chains,
  onSelect,
  allowAllOption = false,
}) => {
  const [selectedChain, setSelectedChain] = useState<Chain | undefined>(
    undefined,
  );

  useEffect(() => {
    setSelectedChain(ALL_CHAINS);
    onSelect(ALL_CHAINS);
  }, [setSelectedChain, onSelect]);

  const renderSelectOption = ({ option }: { option: { label: string } }) => {
    let chain = chains.hasData.find((c) => c.name === option.label);
    if (!chain) {
      chain = chains.missingData.find((c) => c.name === option.label);
    }
    if (option.label === ALL_CHAINS.name) {
      chain = ALL_CHAINS;
    }
    return (
      <Group flex="1" gap="xs">
        <Image h={20} w={20} src={chain?.imageUrl} />
        {option.label}
      </Group>
    );
  };

  const handleChainChange = (value: string | null) => {
    let chain = chains.hasData.find((chain: Chain) => chain.name === value);
    if (!chain) {
      chain = chains.missingData?.find((chain: Chain) => chain.name === value);
    }
    if (value === ALL_CHAINS.name) {
      chain = ALL_CHAINS;
    }
    if (chain) {
      setSelectedChain(chain);
      onSelect(chain);
    }
  };

  const data = [];
  if (allowAllOption) {
    data.push(ALL_CHAINS.name);
  }
  data.push({
    group: "Has data",
    items: chains.hasData?.map((chain) => chain.name),
  });
  data.push({
    group: "Missing data for selected key",
    items: chains.missingData.map((chain) => chain.name),
  });

  return (
    <Select
      label="Select Chain"
      placeholder="Polkadot"
      withCheckIcon={false}
      searchable
      renderOption={renderSelectOption}
      leftSection={<Image h={20} w={20} src={selectedChain?.imageUrl} />}
      disabled={![...chains.hasData, ...chains.missingData]}
      onChange={handleChainChange}
      data={data}
      value={selectedChain?.name}
    />
  );
};
